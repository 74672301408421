// @flow

import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Progress } from "reactstrap";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Chart as ChartJS, Colors, defaults } from "chart.js";
import "chart.js/auto";
import { Bar, Pie, Doughnut, Line } from "react-chartjs-2";
import { saveAs } from "file-saver";
import * as KSTKReporting from "./KSTKReporting";
import { ReportContext } from "./ReportContext";
import { fetchAuthentication } from "../../services/Client";
import { getChartData, clearChartDataCache, exportTableData } from "../../services/report.service";
import { getModulesData, processModuleData } from "../../services/module.service";
import * as XLSX from "xlsx";
import debounce from "lodash.debounce";
import type {
  ChartMetadata,
  ChartMetadataValue,
  ChartDataQueryParams,
  ChartJSModel,
  DatasourceRelation,
} from "../KSTKComponentTypes";
import { getTableData, getTableDataExportUrl } from "../../util/kpi.util";
import { deleteFiles, getFilesFromContainer } from "../../services/file.service";
import { getColumnsFromDatasource } from "../../util/report.util";

const Wrapper = styled.div`
  overflow-x: auto;
  border: 1px solid rgb(224, 224, 224);
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.75) 2px 2px 4px -1px;
  display: grid;
  grid-template-rows: 15px 25px calc(100% - 40px);
  overflow: visible;

  &.selected {
    border: 2px solid #3a62ac;
  }
  &.unset-overflow {
    overflow: unset;
  }
  &.viewMode {
    padding: 8px;
    grid-template-rows: 0px 25px calc(100% - 25px);
    border: none;
    box-shadow: none;
    &.trueHasShadow {
      box-shadow: 0px 5px 43px -9px rgb(83 87 103 / 23%);
    }
  }
  &.falseHasTitle {
    grid-template-rows: 25px calc(100% - 25px);
  }

  &.labelChartType {
    grid-template-rows: 8px calc(100% - 8px) 0px;
    white-space: pre-wrap;
  }

  &.dropdownChartType,
  &.cardChartType {
    &.editMode {
      grid-template-rows: 0px 12px calc(100% - 12px);
      padding: 8px;
    }
    &.viewMode {
      grid-template-rows: 0px 25px calc(100% - 20px);
    }
  }

  &.dropdownChartType {
    &.viewMode {
      padding: 0px;
    }
  }

  &.cardChartType {
    &.viewMode {
      padding: 4px;
    }
  }
`;

const ProgressLoader = styled(Progress)`
  position: absolute;
  width: 100%;
  left: 1px;
  height: 5px;
  bottom: 0;
`;

const LoadingVisual = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
`;

const KPIOptions = styled(UncontrolledDropdown)`
  z-index: 9999;
  text-align: right;
  div {
    width: 50px;
    min-width: 50px;
    &.falseHasExport {
      height: 105px !important;
    }
    &.trueHasExport &.viewMode {
      height: 90px !important;
    }
  }
`;

const KPIOptionsToggle = styled(DropdownToggle)`
  height: 20px;
  ::after {
    display: block;
    margin: 0;
  }
  &.falseHasExport {
    &.viewMode {
      visibility: hidden;
    }
  }
`;

const KPIOptionsItem = styled(DropdownItem)`
  font-size: 16px;
  height: 30px;

  text-align: center;
  &:hover {
    background-color: white;
  }
  &.falseHasExport {
    visibility: hidden;
  }
  &.viewMode {
    display: none;
  }
`;

const LightCSVLink = styled(CSVLink)`
  color: #3a62ac;
  &:hover {
    color: #3a62ac;
  }
`;

const ChartActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  &.label,
  &.dropdown {
    display: none;
  }

  & > * {
    margin: 0 5px 0 5px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align
  margin: 0;
`;

const KPITopArea = styled.div`
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
`;

const GraphScrollableWrapper = styled.div``;

const GraphWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
`;

type Props = {
  index: number,
  type: string,
  mode: string,
  filter: string,
  metadata: ChartMetadata,
};

const components = {
  bar: {
    component: Bar,
    singleColorPerSeries: true,
  },
  horizontalBar: {
    component: Bar,
    singleColorPerSeries: true,
  },
  stackedBar: {
    component: Bar,
    singleColorPerSeries: true,
  },
  horizontalStackedBar: {
    component: Bar,
    singleColorPerSeries: true,
  },
  waterfall: {
    component: Bar,
    singleColorPerSeries: true,
  },
  doughnut: {
    component: Doughnut,
    singleColorPerSeries: false,
  },
  pie: {
    component: Pie,
    singleColorPerSeries: false,
  },
  line: {
    component: Line,
    singleColorPerSeries: true,
  },
  label: {
    component: KSTKReporting.KSTKLabel,
  },
  image: {
    component: KSTKReporting.KSTKImage,
  },
  dropdown: {
    component: KSTKReporting.KSTKSelectionDropdown,
    overflowUnset: true,
  },
  card: {
    component: KSTKReporting.KSTKCard,
  },
  table: {
    component: KSTKReporting.KSTKTable,
  },
  map: {
    component: KSTKReporting.KSTKMap,
  },
};

const Graph = React.memo(
  (props) => {
    const TagName = components[props.type].component;
    if (props.metadata) {
      return (
        <TagName
          data={props.filteredData}
          options={props.options}
          mode={props.mode}
          metadata={props.metadata}
          getTableData={props.getTableData}
          exportTableData={props.exportTableData}
          convertDataTypes={props.convertDataTypes}
          checkPivotTableWrappers={props.checkPivotTableWrappers}
          index={props.index}
          dataMode={props.dataMode}
          onDemandDataUpdate={props.onDemandDataUpdate}
          startExportTableData={props.startExportTableData}
          onExportTableDataEnd={props.onExportTableDataEnd}
          getSourceByDataMode={props.getSourceByDataMode}
          getAbbreviatedNumber={props.getAbbreviatedNumber}
          getCardParsedValue={props.getCardParsedValue}
        />
      );
    } else {
      return <TagName data={props.filteredData} options={props.options} mode={props.mode} index={props.index} />;
    }
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export const KSTKKPI = React.memo(
  (props: Props) => {
    const memoizedMetadata = useMemo(() => {
      return props.metadata;
    }, [props.metadata]);
    // const memoizedOptions = useMemo(() => {
    //   return options;
    // }, [options]);
    // const memoizedFilteredData = useMemo(() => {
    //   return filteredData;
    // }, [filteredData]);

    const [options, setOptions] = useState({
      onClick: (e) => onChartClick(e),
      maintainAspectRatio: false,
      refreshRate: 1000,
      refreshMode: "debounce",
    });
    const [data, setData] = useState({});
    const [filteredData, setFilteredData] = useState({ datasets: [] });
    const [exportData, setExportData] = useState([]);
    const [distinctValuesByField, setDistinctValuesByField] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [isDown, setIsDown] = useState(false);
    const [dataFilteredBySelection, setDataFilteredBySelection] = useState(false);
    const printRef = React.useRef();

    const [kpiOptionsCache, setKPIOptionsCache] = useState(null);
    const [exportingTableData, setExportingTableData] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const {
      report,
      selection,
      selectedChartArea,
      selectedChartAreaIndex,
      filter,
      selectedChart,
      pages,
      selectedPage,
      kpiClick,
      duplicateKPI,
      removeKPI,
      selectedSource,
      datasource,
      colorPalette,
      layout,
      publishNotification,
      publishProgressNotification,
      removeNotification,
    } = useContext(ReportContext);

    /*  useEffect(() => {
    getData();
  }, []); */

    const getMetadataValuesFromDropdowns = () => {
      let dropdownSelectedValues = "";
      if (layout?.length > 0) {
        layout.forEach((layoutEl) => {
          if (
            layoutEl.i != props.index &&
            (layoutEl.kpiType == "dropdown" || layoutEl.kpiType == "map") &&
            layoutEl.metadata?.values != null &&
            layoutEl.metadata?.values?.length > 0
          ) {
            dropdownSelectedValues += JSON.stringify(layoutEl.metadata.values);
          } else if (layoutEl.i == props.index) {
            dropdownSelectedValues += layoutEl.kpiType;
          }
        });
      }
      return dropdownSelectedValues;
    };

    useEffect(() => {
      //debouncedGetData();
      getData();
    }, [
      props.metadata,
      pages?.filter((p) => p.id == selectedPage)[0]?.filters,
      getMetadataValuesFromDropdowns(),
      selectedSource,
      props.dataMode,
      props.onDemandDataUpdate,
      datasource,
      colorPalette,
      report,
    ]);

    useEffect(() => {
      let newOptionsString = JSON.stringify({
        ...options,
        legend: {
          display: data?.datasets?.length > 1,
        },
      });
      if (kpiOptionsCache == null) {
        setKPIOptionsCache(newOptionsString);
      } else if (newOptionsString != kpiOptionsCache) {
        setKPIOptionsCache(newOptionsString);
        setOptions({
          ...options,
          legend: {
            display: data?.datasets?.length > 1,
          },
        });
      }
    }, [data, props.type]);

    useEffect(() => {
      if (data && data.datasets && data.datasets.length) {
        setFilteredData(getFilteredData());
      }
    }, [selection]);

    useEffect(() => {
      if (filteredData.datasets?.length > 0 || filteredData.labels?.length > 0) {
        updateChartOptions();
      }
      if (
        filteredData?.exportData?.length > 0 &&
        props.metadata.chartOverflow != null &&
        props.metadata.chartOverflow != ""
      ) {
        let newDistinctValuesByField = {};
        filteredData.exportData.forEach((el, index) => {
          for (const field in el) {
            let parsedField = field.indexOf(" ") != -1 ? field.split(" ")[field.split(" ").length - 1] : field;

            if (Object.hasOwnProperty.call(el, field)) {
              let fieldValue = el[field];
              if (newDistinctValuesByField[parsedField] == null) {
                newDistinctValuesByField[parsedField] = [];
              }

              if (newDistinctValuesByField[parsedField].indexOf(fieldValue) == -1) {
                newDistinctValuesByField[parsedField].push(fieldValue);
              }
            }
          }
          setDistinctValuesByField(newDistinctValuesByField);
        });
        if (
          ["bar", "stackedBar", "waterfall", "line", "horizontalBar", "horizontalStackedBar"].indexOf(props.type) !=
          -1 &&
          props.metadata &&
          props.metadata.axis &&
          props.metadata.axis.length
        ) {
          if (
            filteredData.labels.length < 16 &&
            (props.metadata.forceChartOverflow == null || props.metadata.forceChartOverflow == false)
          ) {
            document.getElementById("graphScrollableWrapper" + props.index).style.overflow = "hidden";
            document.getElementById("graphWrapper" + props.index).style.overflow = "hidden";
            document.getElementById("graphWrapper" + props.index).style.width = "100%";
            document.getElementById("graphWrapper" + props.index).style.height = "100%";
          } else {
            let overflowValue;
            let metadataValuesFromDropdowns = getMetadataValuesFromDropdowns();
            if (
              props.metadata.chartOverflowWithFilters != null &&
              props.metadata.chartOverflowWithFilters != "" &&
              metadataValuesFromDropdowns != "" &&
              metadataValuesFromDropdowns != props.type
            ) {
              overflowValue = props.metadata.chartOverflowWithFilters;
            } else {
              overflowValue = props.metadata.chartOverflow;
            }
            if (["bar", "stackedBar", "waterfall", "line", "table"].indexOf(props.type) != -1) {
              document.getElementById("graphScrollableWrapper" + props.index).style.overflowX = "scroll";
              document.getElementById("graphScrollableWrapper" + props.index).style.overflowY = "hidden";
              document.getElementById("graphWrapper" + props.index).style.width = overflowValue + "px";
              document.getElementById("graphWrapper" + props.index).style.height = "100%";
            } else if (["horizontalBar", "horizontalStackedBar"].indexOf(props.type) != -1) {
              document.getElementById("graphScrollableWrapper" + props.index).style.overflowX = "hidden";
              document.getElementById("graphScrollableWrapper" + props.index).style.overflowY = "scroll";
              document.getElementById("graphWrapper" + props.index).style.width = "100%";
              document.getElementById("graphWrapper" + props.index).style.height = overflowValue + "px";
            }
          }
        }
      }
    }, [filteredData]);

    const checkPivotTableWrappers = (tableData) => {
      if (
        tableData?.length > 0 &&
        props.metadata != null &&
        props.metadata.chartOverflow != null &&
        props.metadata.chartOverflow != "" &&
        props.metadata.columnPivotColumns != null &&
        props.metadata.columnPivotColumns.field != null &&
        props.metadata.columnPivotColumns.field.length > 0
      ) {
        let newDistinctValuesByField = {};
        tableData.forEach((el, index) => {
          for (const field in el) {
            let parsedField = field.indexOf(" ") != -1 ? field.split(" ")[field.split(" ").length - 1] : field;

            if (Object.hasOwnProperty.call(el, field)) {
              let fieldValue = el[field];
              if (newDistinctValuesByField[parsedField] == null) {
                newDistinctValuesByField[parsedField] = [];
              }

              if (newDistinctValuesByField[parsedField].indexOf(fieldValue) == -1) {
                newDistinctValuesByField[parsedField].push(fieldValue);
              }
            }
          }
          setDistinctValuesByField(newDistinctValuesByField);
        });

        let firstPivotColumn = props.metadata.columnPivotColumns.field[0].split(".")[1];
        // Postgres datasource
        if (newDistinctValuesByField[firstPivotColumn] == null) {
          let filteredField = Object.keys(newDistinctValuesByField).filter(
            (el) => el.indexOf("_" + firstPivotColumn) != -1
          );
          if (filteredField.length > 0) {
            firstPivotColumn = filteredField[0];
          }
        }
        if (
          newDistinctValuesByField[firstPivotColumn]?.length < 11 &&
          (props.metadata.forceChartOverflow == null || props.metadata.forceChartOverflow == false)
        ) {
          document.getElementById("graphScrollableWrapper" + props.index).style.overflow = "hidden";
          document.getElementById("graphWrapper" + props.index).style.width = "100%";
        } else {
          document.getElementById("graphScrollableWrapper" + props.index).style.overflowX = "scroll";
          document.getElementById("graphScrollableWrapper" + props.index).style.overflowY = "hidden";
          document.getElementById("graphWrapper" + props.index).style.width = props.metadata.chartOverflow + "px";
        }
      }
    };

    const higherOrderGetTableData = (skip, limit, metadata, layout, pages, report) => {
      if (props?.onDemandDataUpdate == true && props.mode === "edit") {
        return Promise.resolve({});
      } else {
        return getTableData(
          selectedSource,
          datasource,
          metadata,
          props.filter,
          props.autoUpdateTimerFlag,
          layout,
          {
            skip,
            limit,
          },
          {
            buildQueryParams: function () {
              return buildQueryParams(layout, pages, report);
            },
            publishNotification,
            getChartData,
            KSTKChartToChartJS,
            getModulesData,
            getEnvolvedTables,
            processModuleData,
            setExportData,
          }
        );
      }
    };

    const higherOrderExportTableData = (metadata, layout) =>
      getTableDataExportUrl(selectedSource, datasource, metadata, props.filter, layout, {
        buildQueryParams: function () {
          return buildQueryParams(layout);
        },
        publishNotification,
        exportTableData,
      });

    let queryParamsCache;
    let lastSortedQueryParamsCache;
    let lastSortedObjData;

    const getData = () => {
      if (props?.onDemandDataUpdate == true && props.mode === "edit") {
        return;
      }
      try {
        if (!selectedSource || !datasource) {
          return;
        }

        let sourceId = selectedSource.id;

        if (props.filter == "ORG" || datasource.sourceType == "mongo") {
          sourceId =
            selectedSource.organization && selectedSource.organization != "organization"
              ? selectedSource.organization
              : selectedSource.id;
        } else if (props.filter == "USER") {
          sourceId = selectedSource.organization;
        }

        if (props.type === "label") {
          let label = "This is a label";
          setData({});
          setFilteredData(label);
        } else if (props.type === "dropdown") {
          setIsLoading(true);
          if (props.metadata && props.metadata.axis && props.metadata.axis.length) {
            if (props.metadata.axis[0].variableName && props.metadata.axis[0].variableName.indexOf("$$ ") === 0) {
              const obj = setValueToChartJS(props.metadata.axis[0].variableValues.split(","));
              setData(JSON.parse(JSON.stringify(obj)));
              setFilteredData(JSON.parse(JSON.stringify(obj)));
              setExportData(obj);
              setIsLoading(false);
            } else if (datasource.sourceType == "postgres") {
              let queryParams = buildQueryParams(layout);
              queryParams.unlimited = true;

              queryParamsCache = JSON.stringify(queryParams);
              getChartData(sourceId, queryParams)
                .then((data) => {
                  let obj = KSTKChartToChartJS(data, props.metadata, layout);
                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  setExportData(data);
                })
                .catch((err) => {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error (Dropdown -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            } else if (datasource.sourceType == "mongo") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getModulesData(
                datasource.module,
                sourceId,
                null,
                getEnvolvedTables(true),
                selectedSource,
                props.filter,
                props.autoUpdateTimerFlag
              )
                .then((data) => {
                  let processedData = processModuleData(data, queryParams);
                  let obj = KSTKChartToChartJS(processedData, props.metadata, layout);

                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  setExportData(processedData);
                })
                .catch(function (err) {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            }
          } else {
            setIsLoading(false);
          }
        } else if (props.type === "card") {
          if (
            props.metadata &&
            props.metadata.values &&
            props.metadata.values.length > 0 &&
            props.metadata.values[0].field != ""
          ) {
            setIsLoading(true);
            if (datasource.sourceType == "postgres") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getChartData(sourceId, queryParams)
                .then((data) => {
                  let obj = KSTKChartToChartJS(data, props.metadata, layout);

                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  setExportData(data);
                })
                .catch((err) => {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error (Card -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            } else if (datasource.sourceType == "mongo") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getModulesData(
                datasource.module,
                sourceId,
                null,
                getEnvolvedTables(true),
                selectedSource,
                props.filter,
                props.autoUpdateTimerFlag
              )
                .then((data) => {
                  let processedData = processModuleData(data, queryParams);
                  let obj = KSTKChartToChartJS(processedData, props.metadata, layout);

                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  setExportData(processedData);
                })
                .catch(function (err) {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error  (Card -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            }
          }
        } else if (props.type === "table") {
          // Moved to the KSTKTable component, due to serverside pagination needs
          //if (props.metadata && props.metadata.values) {
          //  if (datasource.sourceType == "postgres") {
          //    let queryParams = buildQueryParams(layout);
          //    if (queryParams != null && queryParams != undefined) {
          //      queryParamsCache = JSON.stringify(queryParams);
          //      getChartData(sourceId, queryParams)
          //        .then((data) => {
          //          let obj = KSTKChartToChartJS(data, props.metadata);
          //          setData(JSON.parse(JSON.stringify(obj)));
          //          setFilteredData(JSON.parse(JSON.stringify(obj)));
          //          setExportData(obj.exportData);
          //        })
          //        .catch((err) => {
          //          publishNotification({
          //            type: "alert",
          //            message: `Error (Table -> ${props.metadata.title}): ${err?.error?.message || err}`,
          //          });
          //        });
          //    }
          //  } else if (datasource.sourceType == "mongo") {
          //    let queryParams = buildQueryParams(layout);
          //    queryParamsCache = JSON.stringify(queryParams);
          //    getModulesData(datasource.module, sourceId, null, getEnvolvedTables(true))
          //      .then((data) => {
          //        let processedData = processModuleData(data, queryParams);
          //        let obj = KSTKChartToChartJS(processedData, props.metadata);
          //        setData(JSON.parse(JSON.stringify(obj)));
          //        setFilteredData(JSON.parse(JSON.stringify(obj)));
          //        setExportData(obj.exportData);
          //      })
          //      .catch(function (err) {
          //        publishNotification({
          //          type: "alert",
          //          message: `Error  (Table -> ${props.metadata.title}): ${err?.error?.message || err}`,
          //        });
          //      });
          //  }
          //} else {
          //  clearData();
          //}
        } else if (props.type === "map") {
          if (props.metadata && props.metadata.axis && props.metadata.axis.length) {
            setIsLoading(true);
            if (datasource.sourceType == "postgres") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getChartData(sourceId, queryParams)
                .then((data) => {
                  let obj = KSTKChartToChartJS(data, props.metadata, layout);
                  if (props.type == "waterfall") {
                    obj = getWaterfallData(obj);
                  }
                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  /* setExportData(obj.exportData); */
                })
                .catch((err) => {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error (Generic -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            } else if (datasource.sourceType == "mongo") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getModulesData(
                datasource.module,
                sourceId,
                null,
                getEnvolvedTables(true),
                selectedSource,
                props.filter,
                props.autoUpdateTimerFlag
              )
                .then((data) => {
                  let processedData = processModuleData(data, queryParams);
                  let obj = KSTKChartToChartJS(processedData, props.metadata, layout);
                  if (props.type == "waterfall") {
                    obj = getWaterfallData(obj);
                  }
                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  /* setExportData(obj.exportData); */
                })
                .catch(function (err) {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error  (Generic -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            }
          }
        } else {
          if (
            props.metadata &&
            props.metadata.axis &&
            props.metadata.axis.length &&
            !props.metadata.axis[0].variableName &&
            props.metadata.values
          ) {
            setIsLoading(true);
            if (datasource.sourceType == "postgres") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getChartData(sourceId, queryParams)
                .then((data) => {
                  let obj = KSTKChartToChartJS(data, props.metadata, layout);
                  if (props.type == "waterfall") {
                    obj = getWaterfallData(obj);
                  }
                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  setExportData(obj.exportData);
                })
                .catch((err) => {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error (Generic -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            } else if (datasource.sourceType == "mongo") {
              let queryParams = buildQueryParams(layout);
              queryParamsCache = JSON.stringify(queryParams);
              getModulesData(
                datasource.module,
                sourceId,
                null,
                getEnvolvedTables(true),
                selectedSource,
                props.filter,
                props.autoUpdateTimerFlag
              )
                .then((data) => {
                  let processedData = processModuleData(data, queryParams);
                  let obj = KSTKChartToChartJS(processedData, props.metadata, layout);
                  if (props.type == "waterfall") {
                    obj = getWaterfallData(obj);
                  }
                  setData(JSON.parse(JSON.stringify(obj)));
                  setFilteredData(JSON.parse(JSON.stringify(obj)));
                  setExportData(obj.exportData);
                })
                .catch(function (err) {
                  setData({});
                  setFilteredData({});
                  setExportData([]);
                  publishNotification({
                    type: "alert",
                    message: `Error  (Generic -> ${props.metadata.title}): ${err?.error?.message || err}`,
                  });
                })
                .finally((_) => {
                  setIsLoading(false);
                });
            }
          } else {
            clearData();
          }
        }
      } catch (err) {
        setData({});
        setFilteredData({});
        setExportData([]);
        publishNotification({
          type: "alert",
          message: `Error (getData -> ${err?.error?.message || err}`,
        });
      }
    };

    const debouncedGetData = useCallback(
      debounce(getData, 3000, {
        leading: props.mode === "view" ? true : false,
      }),
      []
    );

    const clearData = () => {
      setData({});
      setFilteredData({});
      setExportData([]);
    };

    //Update chart options, such as datalabels
    const updateChartOptions = () => {
      let tooltipGenericTitleCallback = function (context) {
        let contextElement = context[0];
        if (contextElement.label != null && typeof contextElement.label == "string") {
          let newLabel = contextElement.label.replace(/,/gi, " ");
          return newLabel;
        }
        return contextElement.label;
      };
      let tooltipGenericLabelCallback = function (context) {
        if (context.dataset != null && typeof context.dataset.label == "string") {
          return context.dataset.label + ": " + context.formattedValue;
        }
      };
      let tooltipGenericFooterCallback = function (context) {
        let tooltipFooterItems = [];
        let dataIndex = context[0].dataIndex;
        // Disable tooltips when context has more than 1 item
        if (
          filteredData != null &&
          Array.isArray(filteredData.tooltips) &&
          filteredData.tooltips.length &&
          context.length == 1
        ) {
          filteredData.tooltips.forEach((tooltip) => {
            tooltipFooterItems.push(tooltip.label + ": " + tooltip.data[dataIndex]);
          });
        }
        return tooltipFooterItems;
      };
      // Force update of datalabels
      if (options?.plugins?.datalabels) {
        delete options.plugins.datalabels;
      }
      if (props.type == "bar") {
        setOptions({
          ...options,
          responsive: true,
          indexAxis: "x",
          scales: {
            y: {
              type: "linear",
            },
          },
          plugins: {
            datalabels: {
              display: "auto",
              clamp: true,
              anchor:
                props.metadata.datalabelAnchor != null && props.metadata.datalabelAnchor != ""
                  ? props.metadata.datalabelAnchor
                  : "center",
              align:
                props.metadata.datalabelAlign != null && props.metadata.datalabelAlign != ""
                  ? props.metadata.datalabelAlign
                  : "center",
              offset:
                props.metadata.datalabelAnchor != null &&
                  props.metadata.datalabelAnchor == "end" &&
                  props.metadata.datalabelAlign != null &&
                  props.metadata.datalabelAlign == "end"
                  ? -4
                  : 0,
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
                weight: 700,
              },
              formatter:
                (props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
                  (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")
                  ? function (value, context) {
                    let formattedValue = value;
                    if (
                      props.metadata.friendlyNumber != null &&
                      props.metadata.friendlyNumber == true &&
                      !isNaN(Number(formattedValue))
                    ) {
                      formattedValue = getAbbreviatedNumber(
                        Number(formattedValue),
                        props.metadata.friendlyNumberDecimalPlaces
                      );
                    }
                    if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                      formattedValue = formattedValue + props.metadata.datalabelSuffix;
                    }
                    return formattedValue;
                  }
                  : null,
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                footer: tooltipGenericFooterCallback,
              },
            },
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
              align:
                props.metadata && props.metadata.chartOverflow != null && props.metadata.chartOverflow != ""
                  ? "start"
                  : "center",
            },
          },
        });
      } else if (props.type == "horizontalBar") {
        setOptions({
          ...options,
          responsive: true,
          indexAxis: "y",
          scales: {
            y: {
              type: "category",
            },
          },
          plugins: {
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
            },
            datalabels: {
              display: "auto",
              clamp: true,
              anchor:
                props.metadata.datalabelAnchor != null && props.metadata.datalabelAnchor != ""
                  ? props.metadata.datalabelAnchor
                  : "center",
              align:
                props.metadata.datalabelAlign != null && props.metadata.datalabelAlign != ""
                  ? props.metadata.datalabelAlign
                  : "center",
              offset:
                props.metadata.datalabelAnchor != null &&
                  props.metadata.datalabelAnchor == "end" &&
                  props.metadata.datalabelAlign != null &&
                  props.metadata.datalabelAlign == "end"
                  ? -4
                  : 0,
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
                weight: 700,
              },
              formatter:
                (props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
                  (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")
                  ? function (value, context) {
                    let formattedValue = value;
                    if (
                      props.metadata.friendlyNumber != null &&
                      props.metadata.friendlyNumber == true &&
                      !isNaN(Number(formattedValue))
                    ) {
                      formattedValue = getAbbreviatedNumber(
                        Number(formattedValue),
                        props.metadata.friendlyNumberDecimalPlaces
                      );
                    }
                    if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                      formattedValue = formattedValue + props.metadata.datalabelSuffix;
                    }
                    return formattedValue;
                  }
                  : null,
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                footer: tooltipGenericFooterCallback,
              },
            },
          },
        });
      } else if (props.type == "stackedBar") {
        setOptions({
          ...options,
          responsive: true,
          indexAxis: "x",
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          plugins: {
            datalabels: {
              display: "auto",
              clamp: true,
              anchor:
                props.metadata.datalabelAnchor != null && props.metadata.datalabelAnchor != ""
                  ? props.metadata.datalabelAnchor
                  : "center",
              align:
                props.metadata.datalabelAlign != null && props.metadata.datalabelAlign != ""
                  ? props.metadata.datalabelAlign
                  : "center",
              offset:
                props.metadata.datalabelAnchor != null &&
                  props.metadata.datalabelAnchor == "end" &&
                  props.metadata.datalabelAlign != null &&
                  props.metadata.datalabelAlign == "end"
                  ? -4
                  : 0,
              anchor: "end",
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
                weight: 700,
              },
              formatter:
                (props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
                  (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")
                  ? function (value, context) {
                    let formattedValue = value;
                    if (
                      props.metadata.friendlyNumber != null &&
                      props.metadata.friendlyNumber == true &&
                      !isNaN(Number(formattedValue))
                    ) {
                      formattedValue = getAbbreviatedNumber(
                        Number(formattedValue),
                        props.metadata.friendlyNumberDecimalPlaces
                      );
                    }
                    if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                      formattedValue = formattedValue + props.metadata.datalabelSuffix;
                    }
                    return formattedValue;
                  }
                  : null,
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                footer: tooltipGenericFooterCallback,
              },
            },
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
              align:
                props.metadata && props.metadata.chartOverflow != null && props.metadata.chartOverflow != ""
                  ? "start"
                  : "center",
            },
          },
        });
      } else if (props.type == "horizontalStackedBar") {
        setOptions({
          ...options,
          responsive: true,
          indexAxis: "y",
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              type: "category",
            },
          },
          plugins: {
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
            },
            datalabels: {
              display: "auto",
              clamp: true,
              anchor:
                props.metadata.datalabelAnchor != null && props.metadata.datalabelAnchor != ""
                  ? props.metadata.datalabelAnchor
                  : "center",
              align:
                props.metadata.datalabelAlign != null && props.metadata.datalabelAlign != ""
                  ? props.metadata.datalabelAlign
                  : "center",
              offset:
                props.metadata.datalabelAnchor != null &&
                  props.metadata.datalabelAnchor == "end" &&
                  props.metadata.datalabelAlign != null &&
                  props.metadata.datalabelAlign == "end"
                  ? -4
                  : 0,
              anchor: "end",
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
                weight: 700,
              },
              formatter:
                (props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
                  (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")
                  ? function (value, context) {
                    let formattedValue = value;
                    if (
                      props.metadata.friendlyNumber != null &&
                      props.metadata.friendlyNumber == true &&
                      !isNaN(Number(formattedValue))
                    ) {
                      formattedValue = getAbbreviatedNumber(
                        Number(formattedValue),
                        props.metadata.friendlyNumberDecimalPlaces
                      );
                    }
                    if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                      formattedValue = formattedValue + props.metadata.datalabelSuffix;
                    }
                    return formattedValue;
                  }
                  : null,
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                footer: tooltipGenericFooterCallback,
              },
            },
          },
        });
      } else if (props.type == "waterfall") {
        setOptions({
          ...options,
          indexAxis: "x",
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            y: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          plugins: {
            datalabels: {
              display: "auto",
              clamp: true,
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
                weight: 700,
              },
              offset: -3,
              formatter: function (value, context) {
                let formattedValue = Array.isArray(value) ? Math.round(value[1] - value[0]) : value;
                if (
                  props.metadata.friendlyNumber != null &&
                  props.metadata.friendlyNumber == true &&
                  !isNaN(Number(formattedValue))
                ) {
                  formattedValue = getAbbreviatedNumber(
                    Number(formattedValue),
                    props.metadata.friendlyNumberDecimalPlaces
                  );
                }
                if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                  formattedValue = formattedValue + props.metadata.datalabelSuffix;
                }
                return formattedValue;
              },
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                label: (tooltipItem) => {
                  const v = tooltipItem.dataset.data[tooltipItem.dataIndex];
                  return Array.isArray(v) ? v[1] - v[0] : v;
                },
                footer: tooltipGenericFooterCallback,
              },
            },
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
              align:
                props.metadata && props.metadata.chartOverflow != null && props.metadata.chartOverflow != ""
                  ? "start"
                  : "center",
            },
          },
        });
      } else if (props.type == "pie" || props.type == "doughnut") {
        setOptions({
          ...options,
          responsive: true,
          indexAxis: "x",
          scales: {
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
            },
            datalabels: {
              display: "auto",
              clamp: true,
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              padding: 6,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
              },
              formatter:
                (props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
                  (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")
                  ? function (value, context) {
                    let formattedValue = value;
                    if (
                      props.metadata.friendlyNumber != null &&
                      props.metadata.friendlyNumber == true &&
                      !isNaN(Number(formattedValue))
                    ) {
                      formattedValue = getAbbreviatedNumber(
                        Number(formattedValue),
                        props.metadata.friendlyNumberDecimalPlaces
                      );
                    }
                    if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                      formattedValue = formattedValue + props.metadata.datalabelSuffix;
                    }
                    return formattedValue;
                  }
                  : null,
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                label: tooltipGenericLabelCallback,
                footer: tooltipGenericFooterCallback,
              },
            },
          },
        });
      } else if (props.type == "line") {
        setOptions({
          ...options,
          responsive: true,
          indexAxis: "x",
          scales: {
            y: {
              type: "linear",
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          borderColor: function (context) {
            return context.dataset.backgroundColor;
          },
          fill: props.metadata.lineChartFill != null ? props.metadata.lineChartFill : false,
          backgroundColor: function (context) {
            return hexToRGBA(rgbaToHex(context.dataset.backgroundColor), 0.2);
          },
          plugins: {
            datalabels: {
              display: "auto",
              align: "center",
              anchor: "center",
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              color: "black",
              textStrokeColor: "white",
              textStrokeWidth: 2,
              borderRadius: 4,
              font: {
                size:
                  props.metadata.dataLabelFontSize != null && props.metadata.dataLabelFontSize != ""
                    ? props.metadata.dataLabelFontSize
                    : 12,
              },
              formatter:
                (props.metadata.friendlyNumber != null && props.metadata.friendlyNumber == true) ||
                  (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "")
                  ? function (value, context) {
                    let formattedValue = value;
                    if (
                      props.metadata.friendlyNumber != null &&
                      props.metadata.friendlyNumber == true &&
                      !isNaN(Number(formattedValue))
                    ) {
                      formattedValue = getAbbreviatedNumber(
                        Number(formattedValue),
                        props.metadata.friendlyNumberDecimalPlaces
                      );
                    }
                    if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
                      formattedValue = formattedValue + props.metadata.datalabelSuffix;
                    }
                    return formattedValue;
                  }
                  : null,
            },
            tooltip: {
              callbacks: {
                title: tooltipGenericTitleCallback,
                footer: tooltipGenericFooterCallback,
              },
            },
            legend: {
              display:
                props.metadata != null && (props.metadata.showLegend == null || props.metadata.showLegend == true)
                  ? true
                  : false,
              align:
                props.metadata && props.metadata.chartOverflow != null && props.metadata.chartOverflow != ""
                  ? "start"
                  : "center",
            },
          },
        });
      }
    };

    const clearCache = () => {
      const queryParams = buildQueryParams(layout);

      if (selectedSource?.id && queryParams) {
        clearChartDataCache(selectedSource.id, queryParams);
        getData();
      }
    };

    const getWaterfallData = (obj) => {
      if (obj.datasets && obj.datasets.length > 0 && obj.datasets[0].data && obj.datasets[0].data.length > 0) {
        //Limit datasets to 1 element
        obj.datasets = new Array(obj.datasets[0]);
        let objDatasetData = obj.datasets[0].data;
        //Only accept datasets that have numeric elements
        if (!isNaN(Number(objDatasetData[0]))) {
          let total = 0;
          objDatasetData = objDatasetData.map(Number);
          /* let originalObjDataSet = JSON.parse(JSON.stringify(objDatasetData)); */
          //objDatasetData.sort((a, b) => a - b);
          /*  let sortedObjDatasetElementsIndex = [];
        objDatasetData.forEach((el) => {
          sortedObjDatasetElementsIndex.push(originalObjDataSet.indexOf(el));
          total += el;
        }); */
          objDatasetData.forEach((el) => {
            total += el;
          });
          total = Math.round(total * 100) / 100;
          let lastDataEl = 0;
          let newObjDatasetData = [];
          objDatasetData.forEach(function (el, index) {
            if (lastDataEl == 0) {
              newObjDatasetData.push([0, el]);
            } else if (index == objDatasetData.length - 1) {
              newObjDatasetData.push([lastDataEl, Math.round(total)]);
            } else {
              newObjDatasetData.push([lastDataEl, lastDataEl + el]);
            }
            lastDataEl = lastDataEl + el;
          });
          newObjDatasetData.push(total);
          obj.datasets[0].data = newObjDatasetData;
          //Sort labels acording to the sorted data
          /* if (obj.labels && obj.labels.length > 0 && obj.originalLabels && obj.originalLabels.length > 0) {
          let newLabels = [];
          let newOriginalLabels = [];
          sortedObjDatasetElementsIndex.forEach((index) => {
            newLabels.push(obj.labels[index]);
            newOriginalLabels.push(obj.originalLabels[index]);
          });
          newLabels.push(["Total"]);
          newOriginalLabels.push("Total");
          obj.labels = newLabels;
          obj.originalLabels = newOriginalLabels;
        } */
          if (obj.labels && obj.labels.length > 0 && obj.originalLabels && obj.originalLabels.length > 0) {
            obj.labels.push(["Total"]);
            obj.originalLabels.push("Total");
          }
          return obj;
        } else {
          return obj;
        }
      }
    };

    //NOTE: if filtering for the same data as before, unfilter 🤘🏻
    const getFilteredData = () => {
      if (selection && selection != null && selectedChartArea && props.type !== "label" && props.type !== "card") {
        let filterSource = selectedChartArea;
        let filterDataSource = selection;
        let filterIndex = filterSource.index;
        let filterLabel = filterDataSource.originalLabels[filterIndex];
        let filterDatasets = filterDataSource.datasets;

        //Check if KPI can be filtered using the filterLabel
        if (data.originalLabels && data.originalLabels.indexOf(filterLabel) == -1 && props.type !== "card") {
          setDataFilteredBySelection(false);
          return data;
        }

        //Check if same label was selected and unfilter if necessary
        if (
          filterDataSource.labels.length == 1 &&
          JSON.stringify(data.originalLabels[selectedChartAreaIndex]) === JSON.stringify(filterLabel)
        ) {
          setDataFilteredBySelection(false);
          return data;
        }

        setDataFilteredBySelection(true);
        return {
          labels: [filterLabel],
          datasets: data.datasets.map((d) => {
            return {
              label: d.label,
              data: [].concat(d.data[filterIndex]),
              /* backgroundColor: [].concat(d.backgroundColor[filterIndex]),
            borderColor: [].concat(d.borderColor[filterIndex]),
            borderWidth: 1, */
            };
          }),
        };
      } else {
        setDataFilteredBySelection(false);
        return data;
      }
    };

    const onChartClick = (event) => {
      /* console.log("been here");
      filter(
        event,
        JSON.parse(JSON.stringify(event.chart.data)),
        event.chart.getElementsAtEventForMode(event, "nearest", { intersect: true }, true)[0],
        props.index
      ); */
    };

    const startExportTableData = () => {
      if (exportingTableData) {
        publishNotification({
          type: "info",
          message: "Esta tabela já tem uma exportação em progresso",
        });
        return;
      }
      setExportingTableData(true);
      publishProgressNotification(
        {
          type: "info",
          message: `Exportação em progresso...`,
        },
        "export-table-data"
      );
    };

    const parseFileName = (path) => {
      const splitedPath = path.split("/");
      return splitedPath[splitedPath.length - 1];
    };

    const onExportTableDataEnd = (exportUrl) => {
      setExportingTableData(false);
      removeNotification("export-table-data");
      const linkSource = `/api/containers/${exportUrl.data.containerId}/download/${exportUrl.data.filename}`;
      saveAs(
        linkSource,
        props.metadata.title != null && props.metadata.title != ""
          ? props.metadata.title + ".xlsx"
          : parseFileName(linkSource)
      );
      setTimeout(() => {
        deleteFiles(exportUrl.data.containerId, exportUrl.data.filename);
      }, 30000);
    };

    const createPagePDF = async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png", 1.0);

      const pdf = new jsPDF("landscape", "pt", "a4");
      const imgProperties = pdf.getImageProperties(data);
      if (
        imgProperties.width < pdf.internal.pageSize.getWidth() &&
        imgProperties.height < pdf.internal.pageSize.getHeight()
      ) {
        pdf.addImage(
          data,
          "PNG",
          pdf.internal.pageSize.getWidth() / 2 - imgProperties.width / 2,
          pdf.internal.pageSize.getHeight() / 2 - imgProperties.height / 2,
          imgProperties.width,
          imgProperties.height
        );
      } else {
        const imageWith = pdf.internal.pageSize.getWidth();
        const imageHeight = (imgProperties.height * imageWith) / imgProperties.width;
        pdf.addImage(
          data,
          "PNG",
          pdf.internal.pageSize.getWidth() / 2 - imageWith / 2,
          pdf.internal.pageSize.getHeight() / 2 - imageHeight / 2,
          imageWith,
          imageHeight
        );
      }

      setTimeout(() => {
        pdf.save(
          props.metadata.title != null && props.metadata.title != "" ? props.metadata.title + ".pdf" : "PDF.pdf"
        );
      }, 1000);
    };

    const createImage = async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element);

      const data = canvas.toDataURL("image/png");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download =
          props.metadata.title != null && props.metadata.title != "" ? props.metadata.title + ".png" : "Image.png";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    };

    const chartCanvasClicked = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    const setValueToChartJS = (value) => {
      return {
        datasets: [
          {
            backgroundColor: getBackgroundColors(1, 0),
            data: [...value.map((v) => [v])],
            labels: [...value.map((v) => [v])],
            originalLabels: [...value],
          },
        ],
        exportData: [],
        labels: [...value.map((v) => [v])],
        originalLabels: [...value],
        tooltips: [],
      };
    };

    const KSTKChartToChartJS = (data: any, metadata: ChartMetadata, layout): ChartJSModel => {
      let originalLabels = [];
      let labels = [];
      let exportData = [];

      if (data.data != null) {
        data = data.data;
      }

      // // Get custom columns if data was not already modeled
      // if (data != null && data.length > 0 && data[0].modeledFlag == null) {
      //   let envolvedTables = [];
      //   let envolvedSources = [];
      //   if (metadata.axis && metadata.axis.length) {
      //     envolvedTables.push(metadata.axis[0].split(".")[0]);
      //   }
      //   if (metadata.values) {
      //     parseValidValues(metadata.values).map((val, valueIndex) => {
      //       if (
      //         val.field != null &&
      //         val.field != "" &&
      //         data != null &&
      //         data.length &&
      //         envolvedTables.indexOf(val.field.split(".")[0]) == -1
      //       ) {
      //         envolvedTables.push(val.field.split(".")[0]);
      //       }
      //     });
      //   }
      //   envolvedTables.forEach((tableName) => {
      //     let sourceId = datasource.resources.find((res) => res.name === tableName).id;
      //     if (envolvedSources.indexOf(sourceId) == -1) {
      //       envolvedSources.push(sourceId);
      //     }
      //   });
      //   envolvedSources.forEach((sourceId) => {
      //     if (
      //       datasource.customColumns &&
      //       datasource.customColumns[sourceId] &&
      //       datasource.customColumns[sourceId].length
      //     ) {
      //       data = data.map((d) => {
      //         datasource.customColumns[sourceId].forEach((customColumn) => {
      //           let row = d;
      //           d[customColumn.name] = eval(customColumn.customFunction);
      //         });
      //         return d;
      //       });
      //     }
      //   });
      // }

      if (metadata.axis && metadata.axis.length) {
        const variablesInLayout = layout
          .filter((l) => l.metadata.axis && l.metadata.axis?.length && l.metadata.axis[0].variableName)
          .map((l) => {
            return {
              ...l.metadata.axis[0],
              value: l.metadata?.values?.length ? l.metadata?.values[0]?.value : null,
            };
          });
        let variablesValues = {};
        variablesInLayout.forEach((v) => {
          variablesValues[v.variableName] = v.value;
        });

        const variablesInValues =
          metadata.values != null
            ? metadata.values
              .filter((v) => v.field?.variableName != null)
              .filter((v) => variablesInLayout.find((l) => l.variableName == v.field.variableName))
              .map((v) => v.field)
            : [];

        data.forEach((d) => {
          variablesInValues.forEach((v) => {
            d[v.variableName.replace("$$ ", "")] = variablesValues[v.variableName];
          });
        });
        const axisColumn = metadata.axis[0].split(".")[1];
        const tableName = getSourceByDataMode(metadata.axis[0].split(".")[0]);
        const sourceId = datasource.resources.find((res) => res.name === tableName).id;

        let customTableModelation;
        if (datasource.customModelations[sourceId]) {
          customTableModelation = datasource.customModelations[sourceId].find((cm) => cm.name === axisColumn);
        }

        originalLabels = data.map((d) => d[axisColumn]);
        labels = data.map((d) => {
          const value = d[axisColumn];

          return customTableModelation
            ? eval(customTableModelation.mod) != null
              ? data.length < 6
                ? eval(customTableModelation.mod).split(" ")
                : eval(customTableModelation.mod)
              : ""
            : typeof value == "string" && value != null
              ? data.length < 6
                ? value.split(" ")
                : value
              : typeof value == "number" && value != null
                ? value
                : "";
        });
      }

      let obj;
      let datasets = [];
      let tooltips = [];

      // DATASETS

      let originalLabelsDataLabels = [...new Set(originalLabels)].sort();
      let labelsDataLabels = [...new Set(labels)].sort();
      let dataSplitByDataLabelsFlag = false;
      if (metadata.values) {
        metadata.values.forEach((val, valueIndex) => {
          if (typeof val.field !== "object" && val.field != "") {
            if (val.field != null && data != null && data.length && data[0].modeledFlag == null) {
              let tableName = val.field.split(".")[0];
              let sourceId = datasource.resources.find((res) => res.name === tableName).id;
              let valueField = val.field.split(".")[1];
              let valueDataField = val.aggregator + "_" + getSourceByDataMode(val.field?.replace(/\./gi, "_"));
              let customTableModelation;
              if (datasource.customModelations[sourceId]) {
                customTableModelation = datasource.customModelations[sourceId].find((cm) => cm.name === valueField);
              }
              let customDataType;
              if (datasource.customDataTypes[sourceId]) {
                customDataType = datasource.customDataTypes[sourceId].find((cm) => cm.name === valueField);
              }
              if (
                (customTableModelation != null && customTableModelation.mod) ||
                (customDataType != null && customDataType.dataType)
              ) {
                data = data.map((d) => {
                  let value = d[valueDataField];
                  let customModelationDataTypeResult;
                  if (customTableModelation != null && customTableModelation.mod) {
                    value = eval(customTableModelation.mod);
                  }
                  if (customDataType != null && customDataType.dataType) {
                    customModelationDataTypeResult = eval(
                      convertDataTypes(customDataType.dataType, customDataType.decimal)
                    );
                  } else {
                    customModelationDataTypeResult = value;
                  }
                  if (customModelationDataTypeResult != null) {
                    d[valueDataField] = customModelationDataTypeResult;
                    /* d.modeledFlag = true; */
                    return d;
                  } else {
                    /* d.modeledFlag = true; */
                    return d;
                  }
                });
              }
            }
          }

          let valueColumn =
            typeof val.field === "object"
              ? val.field?.name || val.field?.variableName.replace("$$ ", "")
              : val.aggregator && val.aggregator.length > 0
                ? val.aggregator + "_" + getSourceByDataMode(val.field?.replace('"', "").replace(".", "_"))
                : val.field && val.field.length
                  ? val.field.split(".")[1]
                  : val;

          if (datasource.sourceType == "mongo" && val.field != null) {
            valueColumn = val.field.split(".")[1];
          }

          //PostgreSQL considers the count distinct column and just count
          // valueColumn = valueColumn === "count_dist" ? "count" : valueColumn;

          const labelName =
            typeof val.field === "object"
              ? val.seriesName != null && val.seriesName != ""
                ? val.seriesName
                : val.field?.name || val.field?.variableName.replace("$$ ", "")
              : val.field && val.field.length
                ? val.seriesName != null && val.seriesName != ""
                  ? val.seriesName
                  : `${getAggregatorLabel(val.aggregator)} ${getCustomHeader(
                    val.field.split(".")[0],
                    val.field.split(".")[1]
                  )}`
                : val;

          // Get datasets by labels
          if (
            metadata.values != null &&
            metadata.values.length == 1 &&
            metadata.labels != null &&
            metadata.labels.length > 0 &&
            data &&
            data.length
          ) {
            dataSplitByDataLabelsFlag = true;
            let axisColumn = metadata.axis[0].split(".")[1];
            let labelField = metadata.labels[0].split(".")[1];
            let labelsValues = data.map((el) => el[labelField]);
            labelsValues.sort();
            let uniqueLabelsValues = [...new Set(labelsValues)];
            uniqueLabelsValues.forEach((labelValue, labelValueIndex) => {
              obj = {
                originalLabels: originalLabelsDataLabels,
                label: labelValue,
                originalLabelName: labelName,
                labelField: labelField,
                data: originalLabelsDataLabels
                  .map((originalLabel) =>
                    data.filter((d) => d[labelField] == labelValue && d[axisColumn] == originalLabel)
                  )
                  .map((d) => (d != null && d.length > 0 ? d[0][valueColumn] : null))
                  .map((d) => (typeof d === "number" && isFloat(d) ? roundFloat(d, 2) : d)),
                backgroundColor: getBackgroundColors(data.length, valueIndex + labelValueIndex),
              };
              obj.data = convertObjDataTypes(obj.data, val);
              datasets.push(obj);
            });
          } else {
            obj = {
              originalLabels: originalLabels,
              label: labelName,
              data: data && data.length ? data.map((d) => d[valueColumn]) : [null],
              backgroundColor:
                data && data.length
                  ? getBackgroundColors(data.length, valueIndex)
                  : getBackgroundColors(null, valueIndex),
            };

            // apply local (values) data types as decimal places
            obj.data = convertObjDataTypes(obj.data, val);
            datasets.push(obj);
          }
        });

        let axisColumn = null;
        let axisTableName = null;
        if (metadata.axis && metadata.axis.length) {
          axisColumn = metadata.axis[0].split(".")[1];
          axisTableName = metadata.axis[0].split(".")[0];
        }

        let labelNameCache = {};
        data.forEach((d) => {
          let exportDataEl = {};
          if (axisColumn != null) {
            exportDataEl[getCustomHeader(axisTableName, axisColumn)] = d[axisColumn];
          }
          metadata.values.map((val, valueIndex) => {
            if (
              typeof val.field !== "object" &&
              val.field != "" &&
              val.field != null &&
              labelNameCache[val.field.split(".")[1]] != null
            ) {
              exportDataEl[labelNameCache[val.field.split(".")[1]]] = convertChartDataTypes(
                d[val.field.split(".")[1]],
                d[val.field.split(".")[1]]?.val?.dataType,
                val.decimalPlaces
              );
            } else {
              const labelName =
                typeof val.field === "object"
                  ? val.seriesName != null && val.seriesName != ""
                    ? val.seriesName
                    : val.field.name
                  : val.field && val.field.length
                    ? val.seriesName != null && val.seriesName != ""
                      ? val.seriesName
                      : `${getAggregatorLabel(val.aggregator)} ${getCustomHeader(
                        val.field.split(".")[0],
                        val.field.split(".")[1]
                      )}`
                    : val;
              let dataFieldName =
                typeof val.field !== "object"
                  ? (val.aggregator ? val.aggregator + "_" : "") + (props.dataMode == "dev" ? "qa_" : "") + val.field
                  : val.field?.name || val.field?.variableName;

              if (datasource.sourceType == "mongo" && val.field != null) {
                dataFieldName = val.field.split(".")[1];
              }
              dataFieldName = dataFieldName.replace(/\./gi, "_");
              labelNameCache[dataFieldName] = labelName;
              exportDataEl[labelName] = convertChartDataTypes(d[dataFieldName], val?.dataType, val.decimalPlaces);
            }
            // Add labels columns to the export data
            if (
              metadata.values.length == 1 &&
              metadata.labels != null &&
              metadata.labels.length > 0 &&
              data &&
              data.length
            ) {
              let labelField = metadata.labels[0].split(".")[1];
              let labelFieldName = getCustomHeader(metadata.labels[0].split(".")[0], metadata.labels[0].split(".")[1]);
              exportDataEl[labelFieldName] = d[labelField];
            }
          });
          exportData.push(exportDataEl);
        });
      }

      if (metadata.tooltips) {
        tooltips = parseValidValues(metadata.tooltips).map((val, valueIndex) => {
          let valueColumn =
            typeof val.field === "object"
              ? val.field.name
              : val.aggregator && val.aggregator.length > 0
                ? `${val.aggregator}_${val.field.replace('"', "").replace(".", "_")}`
                : val.field && val.field.length
                  ? val.field.split(".")[1]
                  : val;

          if (datasource.sourceType == "mongo" && val.field != null) {
            valueColumn = val.field.split(".")[1];
          }
          //PostgreSQL considers the count distinct column and just count
          // valueColumn = valueColumn === "count_dist" ? "count" : valueColumn;

          const labelName =
            typeof val.field === "object"
              ? val.field.name
              : val.field && val.field.length
                ? val.seriesName != null && val.seriesName != ""
                  ? val.seriesName
                  : `${getAggregatorLabel(val.aggregator)} ${getCustomHeader(
                    val.field.split(".")[0],
                    val.field.split(".")[1]
                  )}`
                : val;

          // Get datasets by labels
          if (
            metadata.values != null &&
            metadata.values.length == 1 &&
            metadata.labels != null &&
            metadata.labels.length > 0 &&
            data &&
            data.length
          ) {
            dataSplitByDataLabelsFlag = true;
            let labelField = metadata.labels[0].split(".")[1];
            obj = {
              originalLabels: originalLabels,
              label: labelName,
              originalLabelName: labelName,
              labelField: labelField,
              data:
                data && data.length
                  ? data
                    .map((d) => d[valueColumn])
                    .map((d) => (typeof d === "number" && isFloat(d) ? roundFloat(d, 2) : d))
                  : [null],
            };
          } else {
            obj = {
              originalLabels: originalLabels,
              label: labelName,
              data:
                data && data.length
                  ? data
                    .map((d) => d[valueColumn])
                    .map((d) => (typeof d === "number" && isFloat(d) ? roundFloat(d, 2) : d))
                  : [null],
            };
          }

          obj.data = convertObjDataTypes(obj.data, val);
          return obj;
        });
      }

      obj = {
        originalLabels: !dataSplitByDataLabelsFlag ? originalLabels : originalLabelsDataLabels,
        labels: !dataSplitByDataLabelsFlag ? labels : labelsDataLabels,
        datasets: datasets,
        tooltips: tooltips,
        exportData: exportData,
      };
      if (queryParamsCache != lastSortedQueryParamsCache) {
        lastSortedQueryParamsCache = queryParamsCache;
        sortChartData(
          obj,
          dataSplitByDataLabelsFlag,

          metadata.axis && metadata.axis.length
            ? getCustomHeader(metadata.axis[0].split(".")[0], metadata.axis[0].split(".")[1])
            : null,
          metadata.labels && metadata.labels.length
            ? getCustomHeader(metadata.labels[0].split(".")[0], metadata.labels[0].split(".")[1])
            : null
        );
        lastSortedObjData = obj;
        return lastSortedObjData;
      } else if (lastSortedObjData != null) {
        return lastSortedObjData;
      } else {
        return obj;
      }
    };

    const convertDataTypes = useCallback((dataType, decimal) => {
      switch (dataType) {
        case "none":
          return "";
        case "currency":
          return "!isNaN(Number(value)) && value != null ? Number(value).toFixed(" + decimal + ") + '€' : value + '€'";
        case "date":
          return "new Date(value) instanceof Date && !isNaN(new Date(value)) ? new Date(value).toLocaleDateString() : value";
        case "decimal":
          if (decimal && decimal.length) {
            return "!isNaN(Number(value)) && value != null ? Number(value).toFixed(" + decimal + ") : value";
          } else {
            return "value";
          }
        case "percentage":
          if (decimal && decimal.length) {
            return (
              "!isNaN(Number(value)) && value != null ? ((Number(value) * 100).toFixed(" + decimal + ") + '%') : value"
            );
          } else {
            return "value";
          }
        //return "!isNaN(Number(value)) && Number(value) <= 1 && Number(value) >= 0 && value != null ? Number(value) * 100 + '%' : value";
        default:
          return "";
      }
    }, []);

    const getCardParsedValue = (value) => {
      let formattedValue = value;
      if (
        props.metadata.friendlyNumber != null &&
        props.metadata.friendlyNumber == true &&
        !isNaN(Number(formattedValue))
      ) {
        formattedValue = getAbbreviatedNumber(Number(formattedValue), props.metadata.friendlyNumberDecimalPlaces);
      }
      if (props.metadata.datalabelSuffix != null && props.metadata.datalabelSuffix != "") {
        formattedValue = formattedValue + props.metadata.datalabelSuffix;
      }
      return formattedValue;
    };

    const getAbbreviatedNumber = (number, decPlaces) => {
      let negativeNumberFlag = false;
      let dPlaces = decPlaces != null && decPlaces != "" ? Number(decPlaces) : 2;
      if (number < 0) {
        negativeNumberFlag = true;
        number = number * -1;
      }

      // 2 decimal places => 100, 3 => 1000, etc
      dPlaces = Math.pow(10, dPlaces);

      // Enumerate number abbreviations
      var abbrev = ["K", "M", "B", "T"];

      // Go through the array backwards, so we do the largest first
      for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by dPlaces, round, and then divide by dPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * dPlaces) / size) / dPlaces;

          // Handle special case where we round up to the next abbreviation
          if (number == 1000 && i < abbrev.length - 1) {
            number = 1;
            i++;
          }

          // Add the letter for the abbreviation
          number += abbrev[i];

          // We are done... stop
          break;
        }
      }

      return negativeNumberFlag ? "-" + number : number;
    };

    const convertChartDataTypes = (value, dataType, decimal) => {
      if (dataType != null && dataType != "") {
        switch (dataType) {
          case "none":
            return value;
          case "currency":
            return value;
          case "date":
            return new Date(value) instanceof Date && !isNaN(new Date(value))
              ? new Date(value).toLocaleDateString()
              : value;
          case "decimal":
            return toDecimal(value, decimal);
          case "percentage":
            const newValue = !isNaN(Number(value)) && value != null ? Number(value) * 100 : value;
            return toDecimal(newValue, decimal);
          default:
            break;
        }
      } else {
        return value;
      }
    };

    const convertObjDataTypes = (data, val) => {
      return data.map((d) => {
        if (val.dataType || val.decimalPlaces) {
          return convertChartDataTypes(d, val.dataType, val.decimalPlaces);
        } else {
          return d;
        }
      });
    };

    const toDecimal = (value, decimalPlaces) => {
      return !isNaN(Number(value)) && value != null
        ? (Math.round(Number(value) * 10 ** decimalPlaces) / 10 ** decimalPlaces).toFixed(decimalPlaces)
        : value;
    };

    const sortChartData = (obj, dataSplitByDataLabelsFlag, axisColumn, labelColumn) => {
      if (obj.originalLabels && obj.originalLabels.length && obj.datasets && obj.datasets.length > 0) {
        let dataLabel = dataSplitByDataLabelsFlag ? obj.datasets[0].originalLabelName : obj.datasets[0].label;
        if (typeof dataLabel == "object" && axisColumn) {
          dataLabel = axisColumn;
        }
        let sortedObjElementsNewIndexes = [];
        obj.exportData.forEach((el, index) => {
          el.originalIndex = index;
        });
        // Prepare export data map with values by dataset label
        let exportDataIndex = obj.exportData.length;
        let datasetLabels = [];
        let exportDataMap = {};
        if (dataSplitByDataLabelsFlag) {
          for (let w = 0; w < obj.exportData.length; w++) {
            let exportDataEl = obj.exportData[w];
            if (datasetLabels.indexOf(exportDataEl[labelColumn]) == -1) {
              datasetLabels.push(exportDataEl[labelColumn]);
            }
            if (exportDataMap[exportDataEl[axisColumn].toString()] == null) {
              exportDataMap[exportDataEl[axisColumn].toString()] = {};
            }
            exportDataMap[exportDataEl[axisColumn].toString()][exportDataEl[labelColumn].toString()] = exportDataEl;
          }
          datasetLabels.sort();
          for (const key in exportDataMap) {
            if (Object.hasOwnProperty.call(exportDataMap, key)) {
              let exportDataMapLabelValues = exportDataMap[key];
              if (Object.keys(exportDataMapLabelValues).length != datasetLabels.length) {
                let missingLabelValues = datasetLabels.filter(
                  (el) =>
                    Object.keys(exportDataMapLabelValues).indexOf(el) == -1 &&
                    Object.keys(exportDataMapLabelValues).indexOf(el.toString()) == -1
                );
                missingLabelValues.forEach((missingLabelValue) => {
                  let missingLabelEl = {};
                  missingLabelEl[axisColumn] = exportDataMap[key][Object.keys(exportDataMap[key])[0]][axisColumn];
                  missingLabelEl[labelColumn] = missingLabelValue;
                  missingLabelEl[dataLabel] = null;
                  missingLabelEl.originalIndex = exportDataIndex;
                  exportDataIndex += 1;
                  exportDataMap[key][missingLabelValue] = missingLabelEl;
                  obj.exportData.push(missingLabelEl);
                });
              }
            }
          }
        }
        if (axisColumn && !dataSplitByDataLabelsFlag) {
          if (
            props.metadata.chartDataOrder == null ||
            props.metadata.chartDataOrder == "" ||
            props.metadata.chartDataOrder == "abc"
          ) {
            processDataSort(obj.exportData, null, axisColumn);
            obj.exportData.forEach((el, index) => {
              sortedObjElementsNewIndexes.push(el);
            });
          } else if (props.metadata.chartDataOrder == "asc") {
            processDataSort(obj.exportData, "asc", dataLabel);
            obj.exportData.forEach((el, index) => {
              sortedObjElementsNewIndexes.push(el);
            });
          } else if (props.metadata.chartDataOrder == "desc") {
            processDataSort(obj.exportData, "desc", dataLabel);
            obj.exportData.forEach((el, index) => {
              sortedObjElementsNewIndexes.push(el);
            });
          }
        } else if (
          (props.metadata.chartDataOrder == null ||
            props.metadata.chartDataOrder == "" ||
            props.metadata.chartDataOrder == "abc") &&
          dataSplitByDataLabelsFlag
        ) {
          obj.exportData = processDataSort(
            obj.exportData,
            null,
            axisColumn,
            dataSplitByDataLabelsFlag,
            datasetLabels,
            exportDataMap,
            axisColumn,
            labelColumn
          );
          obj.exportData.forEach((el, index) => {
            sortedObjElementsNewIndexes.push(el);
          });
        } else if (props.metadata.chartDataOrder == "asc" && dataSplitByDataLabelsFlag) {
          obj.exportData = processDataSort(
            obj.exportData,
            "asc",
            dataLabel,
            dataSplitByDataLabelsFlag,
            datasetLabels,
            exportDataMap,
            axisColumn,
            labelColumn
          );
          obj.exportData.forEach((el, index) => {
            sortedObjElementsNewIndexes.push(el);
          });
        } else if (props.metadata.chartDataOrder == "desc" && dataSplitByDataLabelsFlag) {
          obj.exportData = processDataSort(
            obj.exportData,
            "desc",
            dataLabel,
            dataSplitByDataLabelsFlag,
            datasetLabels,
            exportDataMap,
            axisColumn,
            labelColumn
          );
          obj.exportData.forEach((el, index) => {
            sortedObjElementsNewIndexes.push(el);
          });
        }
        if (Array.isArray(obj.originalLabels)) {
          let newOriginalLabels = [];
          sortedObjElementsNewIndexes.forEach((indexInfo) => {
            newOriginalLabels.push(indexInfo[axisColumn]);
          });
          obj.originalLabels = [...new Set(newOriginalLabels)];
        }
        if (Array.isArray(obj.labels)) {
          let newLabels = [];
          sortedObjElementsNewIndexes.forEach((indexInfo) => {
            newLabels.push(indexInfo[axisColumn]);
          });
          obj.labels = [...new Set(newLabels)];
        }
        if (obj.datasets && obj.datasets.length) {
          for (let k = 0; k < obj.datasets.length; k++) {
            let dataset = obj.datasets[k];
            let newData = [];
            if (dataSplitByDataLabelsFlag) {
              dataset.data = obj.exportData.filter((el) => el[labelColumn] == dataset.label).map((el) => el[dataLabel]);
            } else {
              sortedObjElementsNewIndexes.forEach((indexInfo) => {
                newData.push(dataset.data[indexInfo.originalIndex]);
              });
              dataset.data = newData;
            }
          }
        }
        if (obj.tooltips && obj.tooltips.length) {
          for (let k = 0; k < obj.tooltips.length; k++) {
            let tooltip = obj.tooltips[k];
            let newData = [];
            // Tooltips disabled when labels are being used
            if (!dataSplitByDataLabelsFlag) {
              sortedObjElementsNewIndexes.forEach((indexInfo) => {
                newData.push(tooltip.data[indexInfo.originalIndex]);
              });
              tooltip.data = newData;
            }
          }
        }
        if (Array.isArray(obj.exportData)) {
          obj.exportData = JSON.parse(JSON.stringify(sortedObjElementsNewIndexes)).map((el) => {
            delete el.originalIndex;
            return el;
          });
        }
      } else if (obj.originalLabels && obj.originalLabels.length && obj.datasets && obj.datasets.length == 0) {
        let originalObj = JSON.parse(JSON.stringify(obj));
        let sortedObjElementsNewIndexes = [];
        if (
          props.metadata.chartDataOrder == null ||
          props.metadata.chartDataOrder == "" ||
          props.metadata.chartDataOrder == "abc" ||
          props.metadata.chartDataOrder == "asc"
        ) {
          processDataSort(obj.originalLabels, null, null);
          obj.originalLabels.forEach((el) => {
            sortedObjElementsNewIndexes.push(originalObj.originalLabels.indexOf(el));
          });
        } else if (props.metadata.chartDataOrder == "desc") {
          processDataSort(obj.originalLabels, "desc", null);
          obj.originalLabels.forEach((el) => {
            sortedObjElementsNewIndexes.push(originalObj.originalLabels.indexOf(el));
          });
        }

        /* else if (props.metadata.chartDataOrder == "asc") {
        processDataSort(obj.datasets[0].data, "asc", null);
        obj.datasets[0].data.forEach((el) => {
          sortedObjElementsNewIndexes.push(originalObj.datasets[0].data.indexOf(el));
        });
      } else if (props.metadata.chartDataOrder == "desc") {
        processDataSort(obj.datasets[0].data, "desc", null);
        obj.datasets[0].data.forEach((el) => {
          sortedObjElementsNewIndexes.push(originalObj.datasets[0].data.indexOf(el));
        });
      } */

        if (Array.isArray(obj.labels)) {
          let newLabels = [];
          sortedObjElementsNewIndexes.forEach((index) => {
            newLabels.push(originalObj.labels[index]);
          });
          obj.labels = newLabels;
        }
        if (Array.isArray(obj.exportData) && !dataSplitByDataLabelsFlag) {
          let newExportData = [];
          sortedObjElementsNewIndexes.forEach((index) => {
            newExportData.push(originalObj.exportData[index]);
          });
          obj.exportData = newExportData;
        }
        if (obj.datasets && obj.datasets.length) {
          for (let k = 0; k < obj.datasets.length; k++) {
            let dataset = obj.datasets[k];
            let newData = [];
            sortedObjElementsNewIndexes.forEach((index) => {
              newData.push(dataset.data[index]);
            });
            dataset.data = newData;
          }
        }
        if (obj.tooltips && obj.tooltips.length) {
          for (let k = 0; k < obj.tooltips.length; k++) {
            let tooltip = obj.tooltips[k];
            let newData = [];
            sortedObjElementsNewIndexes.forEach((index) => {
              newData.push(tooltip.data[index]);
            });
            tooltip.data = newData;
          }
        }
      }
      return obj;
    };

    const processDataSort = (
      data,
      order,
      field,
      dataSplitByDataLabelsFlag,
      datasetLabels,
      exportDataMap,
      axisColumn,
      labelColumn
    ) => {
      if (field != null) {
        if (order == null || order == "abc" || order == "asc") {
          data.sort((a, b) => {
            if (!isNaN(Number(a[field])) && !isNaN(Number(b[field]))) {
              if (Number(a[field]) > Number(b[field])) {
                return 1;
              } else {
                return -1;
              }
            } else if (typeof a[field] == "string" && typeof b[field] == "string") {
              if (a[field].localeCompare(b[field], "pt") == -1) {
                return -1;
              } else {
                return 1;
              }
            } else {
              return a[field] - b[field];
            }
          });
        } else {
          data.sort((a, b) => {
            if (!isNaN(Number(a[field])) && !isNaN(Number(b[field]))) {
              if (Number(a[field]) > Number(b[field])) {
                return -1;
              } else {
                return 1;
              }
            } else if (typeof a[field] == "string" && typeof b[field] == "string") {
              if (a[field].localeCompare(b[field], "pt") == -1) {
                return 1;
              } else {
                return -1;
              }
            } else {
              return b[field] - a[field];
            }
          });
        }
      } else {
        if (order == null || order == "abc" || order == "asc") {
          data.sort((a, b) => {
            if (!isNaN(Number(a)) && !isNaN(Number(b))) {
              if (Number(a) > Number(b)) {
                return 1;
              } else {
                return -1;
              }
            } else if (typeof a == "string" && typeof b == "string") {
              if (a.localeCompare(b, "pt") == -1) {
                return -1;
              } else {
                return 1;
              }
            } else {
              return a - b;
            }
          });
        } else if (order == "desc") {
          data.sort((a, b) => {
            if (!isNaN(Number(a)) && !isNaN(Number(b))) {
              if (Number(a) > Number(b)) {
                return -1;
              } else {
                return 1;
              }
            } else if (typeof a == "string" && typeof b == "string") {
              if (a.localeCompare(b, "pt") == -1) {
                return 1;
              } else {
                return -1;
              }
            } else {
              return b - a;
            }
          });
        }
      }

      // Return new data when data is split by labels. Data is ordered by the first dataset label
      if (dataSplitByDataLabelsFlag != null && dataSplitByDataLabelsFlag && datasetLabels.length > 1) {
        let newExportData = data.filter((el) => el[labelColumn] == datasetLabels[0]);
        let datasetLabelsToInsert = JSON.parse(JSON.stringify(datasetLabels)).slice(1);
        for (let k = 0; k < newExportData.length;) {
          let newExportDataEl = newExportData[k];
          datasetLabelsToInsert.forEach((labelToInsert) => {
            newExportData.splice(k, 0, exportDataMap[newExportDataEl[axisColumn]][labelToInsert]);
          });
          k += datasetLabelsToInsert.length + 1;
        }
        return newExportData;
      } else {
        return data;
      }
    };

    const getCustomHeader = (tableName, columnName) => {
      const resourceId = datasource.resources.find((r) => r.name === tableName)?.id;
      const customHeader = datasource.customHeaders[resourceId]?.find((c) => c.name === columnName)?.header;
      return customHeader || columnName;
    };

    const isFloat = (n) => {
      return n !== Math.floor(n);
    };

    const roundFloat = (n, cases) => {
      return parseFloat(n.toFixed(cases));
    };

    const getAggregatorLabel = (agg) => {
      const aggregators = [
        { value: "sum", label: "Som." },
        { value: "avg", label: "Média" },
        { value: "count", label: "Total" },
        { value: "count_dist", label: "Total (únicos)" },
        { value: "max", label: "Max" },
        { value: "min", label: "Min" },
        { value: "distinct", label: "Únicos" },
      ];

      return aggregators.find((a) => a.value === agg)?.label;
    };

    const buildQueryParams = (layout, outerPages, outerReport) => {
      try {
        let queryParams: ChartDataQueryParams = {
          id: props.index,
          select: [],
          measure: [],
          from: [],
          joins: [],
          wheres: [],
          whereIns: [],
          relations: [],
          dataMode: props.dataMode,
          title: props.metadata.title,
          needsTotalCount: props.type === "table",
          report: outerReport != null && outerReport.id != null ? outerReport.id : report.id,
          columnSettings: [],
          optimization: props.metadata.optimization,
        };
        if (props.metadata.unlimited) {
          queryParams.unlimited = true;
        }
        if (datasource.relations) {
          queryParams.relations = datasource.relations;
        }
        if (props.metadata.columnSettings != null && props.metadata.columnSettings.length > 0) {
          queryParams.columnSettings = parseValidColumnSettings(props.metadata.columnSettings);
        }
        const parsedValues = parseValidValues(props.metadata.values);
        // SELECT & FROM
        if (props.metadata.axis.length) {
          queryParams.select = [getSourceByDataMode(props.metadata.axis[0])].concat(
            props.metadata.labels?.map((el) => {
              return getSourceByDataMode(el);
            })
          );

          if (props.metadata.values && !valuesAreOnlyMeasures(props.metadata.values)) {
            queryParams.select = queryParams.select.concat(parsedValues);
          }

          //if(typeof props.metadata.axis[0] === "string") {
          //  queryParams.from = props.metadata.axis[0].split(".")[0];
          //} else if(typeof props.metadata.axis[0] === "object" && props.metadata.axis[0].columnA) {
          //  queryParams.from = props.metadata.axis[0].columnA.split(".")[0];
          //} else if(typeof props.metadata.axis[0] === "object" && props.metadata.axis[0].A) {
          queryParams.from =
            typeof props.metadata.axis[0] === "string"
              ? getSourceByDataMode(props.metadata.axis[0].split(".")[0])
              : getSourceByDataMode(props.metadata.axis[0].columnA.split(".")[0]);
        } else if (parsedValues.length) {
          queryParams.select = parsedValues;
          queryParams.from =
            typeof parsedValues[0] === "string"
              ? parsedValues[0].split(".")[0]
              : typeof parsedValues[0].field === "string"
                ? parsedValues[0].field.split(".")[0]
                : typeof parsedValues[0].field.columnA === "string"
                  ? parsedValues[0].field.columnA.split(".")[0]
                  : typeof parsedValues[0].field.columnA.columnA === "string"
                    ? parsedValues[0].field.columnA.columnA.split(".")[0]
                    : parsedValues[0].field.columnA.columnA.columnA.split(".")[0];
        }
        if (props.metadata.tooltips && props.metadata.tooltips.length) {
          queryParams.select = queryParams.select.concat(parseValidValues(props.metadata.tooltips));
        }

        // JOIN
        queryParams.joins = getUsefullRelations(queryParams.select, datasource.relations);

        //Add KPI, Page and Report filters
        let filters = [];
        if (props && props.metadata && props.metadata.filters != null && props.metadata.filters.length) {
          filters = filters.concat(parseValidFilters(props.metadata.filters));
        }
        let page =
          outerPages != null
            ? {
              ...outerPages.find((p) => p.id === selectedPage),
            }
            : {
              ...pages.find((p) => p.id === selectedPage),
            };
        filters = filters.concat(parseValidFilters(page.filters));
        if (
          outerReport != null &&
          outerReport.length &&
          outerReport[0].filters != null &&
          outerReport[0].filters.length
        ) {
          filters = filters.concat(parseValidFilters(outerReport[0].filters));
        } else if (report.filters != null && report.filters.length) {
          filters = filters.concat(parseValidFilters(report.filters));
        } else if (report != null && report.length && report[0].filters != null && report[0].filters.length) {
          filters = filters.concat(parseValidFilters(report[0].filters));
        }
        queryParams.wheres = getWheresFromFilters(filters);

        //Inject variable filters
        queryParams.wheres = queryParams.wheres.concat(getVariableFilters(layout));

        //Insert user id as filter if report is in USER mode
        queryParams.wheres = queryParams.wheres.concat(getUserIdFilter());

        queryParams.whereIns = getWhereInsFromLayout(queryParams.select, layout);

        // MEASURE
        queryParams.measure = parseValidMeasures(props.metadata.values);
        queryParams.measure = queryParams.measure.concat(
          getMeasuresFromWheres(queryParams.wheres, queryParams.measure)
        );

        // INs
        queryParams.ins = [];
        let temporaryIns = []
          .concat(getInsFromLayout(queryParams.select, datasource.relations, layout, queryParams.from))
          .concat(getInsFromWheres(queryParams.select, datasource.relations, queryParams.wheres, queryParams.from));

        temporaryIns.forEach((wi) => {
          const inIndex = queryParams.ins.findIndex(
            (ii) =>
              ii.mainTable === wi.mainTable &&
              ii.mainTableColumn === wi.mainTableColumn &&
              ii.selectingTable === wi.selectingTable &&
              ii.selectingTableColumn === wi.selectingTableColumn
          );

          if (inIndex === -1) {
            queryParams.ins.push(wi);
          } else {
            queryParams.ins[inIndex].filter = queryParams.ins[inIndex].filter.concat(wi.filter);
          }
        });

        // COLUMN GROUPS
        queryParams.columnGroups = parseValidValues(props.metadata.columnAggregators);

        // PIVOT
        queryParams.columnGrouping = props.metadata.columnGrouping;
        queryParams.columnPivotColumns = props.metadata.columnPivotColumns;
        queryParams.columnPivotsAggregators = props.metadata.columnPivotsAggregators;
        queryParams.columnPivotsAggregatorsList = props.metadata.columnPivotsAggregatorsList;
        queryParams.pivotAggregatorField = props.metadata.pivotAggregatorField;

        return queryParams;
      } catch (err) {
        publishNotification({
          type: "alert",
          message: `Error (Building Query -> ${err?.error?.message || err}`,
        });
      }
    };

    const parseValidValues = (values) => {
      if (values != null) {
        const parsedValues = values
          .filter((v) => v.field != null && v.field != "")
          .filter((v) => v.field?.variableName == null)
          //.filter((v) => v.aggregator != null && v.aggregator != "")
          .map((v) => {
            return {
              ...v,
              field:
                typeof v.field == "string" ? getSourceByDataMode(v.field) : transformMeasureSourceByDataMode(v.field),
            };
          });
        return parsedValues;
      } else {
        return [];
      }
    };

    const parseValidColumnSettings = (columnSettings) => {
      if (columnSettings != null) {
        const parsedColumnSettings = columnSettings
          .filter((c) => c.column != null && c.column != "")
          .filter(
            (c) =>
              props.metadata.values.filter(
                (v) =>
                  (v.field != null && v.field != "" && c.column == v.field) ||
                  (v.field != null && v.field != "" && typeof v.field === "object" && c.column == v.field.name)
              ).length
          );

        return parsedColumnSettings;
      } else {
        return [];
      }
    };

    const parseValidMeasures = (values) => {
      let validMeasures = [];

      // simple measures
      validMeasures = validMeasures.concat(
        values
          ?.filter((v) => v.field != null && v.field != "" && typeof v.field === "object")
          .filter((v) => v.field?.variableName == null)
          .filter(
            (v) =>
              (v.field.columnA == null || typeof v.field.columnA === "string") &&
              (v.field.columnB == null || typeof v.field.columnB === "string")
          )
          .map((v) => {
            return {
              ...v,
              field: transformMeasureSourceByDataMode(v.field),
              //primaryKey: datasource.resources
              //  .filter(
              //    (r) =>
              //      r.name === v.field.columnA?.split(".")[0] || (r.name === r.name) === v.field.columnB?.split(".")[0]
              //  )
              //  .map((r) => `${r.schema}.${r.name}.${r.primaryKey}`)
              //  .filter((k) => (k != null) & (k != undefined)),
            };
          })
      );

      // complex measure where only the first column is a measure
      validMeasures = validMeasures.concat(
        values
          ?.filter((v) => v.field != null && v.field != "" && typeof v.field === "object")
          .filter(
            (v) =>
              v.field.columnA != null &&
              typeof v.field.columnA === "object" &&
              (typeof v.field.columnB === "string" || v.field.columnB == null)
          )
          .map((v) => {
            let fullyComplexMeasures = [v];

            if (validMeasures.filter((m) => m.field.name === v.field.columnA.name).length === 0) {
              fullyComplexMeasures = fullyComplexMeasures.concat({
                field: v.field.columnA,
              });
            }

            return fullyComplexMeasures;
          })
          .flat()
          .map((v) => {
            return {
              ...v,
              field: transformMeasureSourceByDataMode(v.field),
              //primaryKey: datasource.resources
              //  .filter(
              //    (r) =>
              //      r.name === v.field.columnA?.split(".")[0] || (r.name === r.name) === v.field.columnB?.split(".")[0]
              //  )
              //  .map((r) => `${r.schema}.${r.name}.${r.primaryKey}`)
              //  .filter((k) => (k != null) & (k != undefined)),
            };
          })
      );

      // complex measure where only the second column is a measure
      validMeasures = validMeasures.concat(
        values
          ?.filter((v) => v.field != null && v.field != "" && typeof v.field === "object")
          .filter(
            (v) =>
              (v.field.columnA == null || typeof v.field.columnA === "string") &&
              typeof v.field.columnB === "object" &&
              v.field.columnB != null
          )
          .filter((v) => v.field.columnB != null)
          .map((v) => {
            let fullyComplexMeasures = [v];

            //if (validMeasures.filter((m) => m.field.name === v.field.columnA.name).length === 0) {
            //  fullyComplexMeasures = fullyComplexMeasures.concat({ field: v.field.columnA });
            //}
            if (validMeasures.filter((m) => m.field.name === v.field.columnB.name).length === 0) {
              fullyComplexMeasures = fullyComplexMeasures.concat({
                field: v.field.columnB,
              });
            }

            return fullyComplexMeasures;
          })
          .flat()
          .map((v) => {
            return {
              ...v,
              field: transformMeasureSourceByDataMode(v.field),
              //primaryKey: datasource.resources
              //  .filter(
              //    (r) =>
              //      r.name === v.field.columnA?.split(".")[0] || (r.name === r.name) === v.field.columnB?.split(".")[0]
              //  )
              //  .map((r) => `${r.schema}.${r.name}.${r.primaryKey}`)
              //  .filter((k) => (k != null) & (k != undefined)),
            };
          })
      );

      // complex measure where both columns are measures
      validMeasures = validMeasures.concat(
        values
          ?.filter((v) => v.field != null && v.field != "" && typeof v.field === "object")
          .filter(
            (v) =>
              typeof v.field.columnA === "object" &&
              typeof v.field.columnB === "object" &&
              v.field.columnA != null &&
              v.field.columnB != null
          )
          .map((v) => {
            let fullyComplexMeasures = [{ ...v, field: transformMeasureSourceByDataMode(v.field) }];

            if (validMeasures.filter((m) => m.field.name === v.field.columnA.name).length === 0) {
              fullyComplexMeasures = fullyComplexMeasures.concat({
                field: transformMeasureSourceByDataMode(v.field.columnA),
              });
            }
            if (validMeasures.filter((m) => m.field.name === v.field.columnB.name).length === 0) {
              fullyComplexMeasures = fullyComplexMeasures.concat({
                field: transformMeasureSourceByDataMode(v.field.columnB),
              });
            }

            return fullyComplexMeasures;
          })
          .flat()
      );

      return validMeasures;
      //return validMeasures.filter(function (item, pos, self) {
      //  return self.map((m) => m.field.name).indexOf(item.field.name) == pos;
      //});
    };

    const valuesAreOnlyMeasures = (values) => {
      return (
        values.filter((v) => v.field != null && v.field != "" && typeof v.field === "object").length === values.length
      );
    };

    const parseValidFilters = (filters) => {
      const parsedFilters = filters
        .filter((v) => v.column != null)
        .filter(
          (v) =>
            (v.operation != null && v.operation != "" && v.value != null && v.value != "") ||
            (v.operation == "notBlank" && (v.value == null || v.value == ""))
        )
        .map((v) => {
          return {
            ...v,
            column: getSourceByDataMode(v.column),
          };
        });
      return parsedFilters;
    };

    const parseValidVariableFilters = (variableFilters) => {
      const parsedFilters = variableFilters
        .filter((v) => v.column != null)
        .filter(
          (v) =>
            (v.operation != null && v.operation != "" && v.variable != null && v.variable != "") ||
            (v.operation == "notBlank" && (v.variable == null || v.variable == ""))
        )
        .map((v) => {
          return {
            ...v,
            column: getSourceByDataMode(v.column),
          };
        });
      return parsedFilters;
    };

    const parseValidWhereFromFilters = (filters) => {
      const parsedFilters = filters
        .filter((v) => v.source != null)
        .filter((v) => v.field != null && v.field != "")
        .filter(
          (v) =>
            (v.operator != null && v.operator != "" && v.value != null && v.value != "") ||
            (v.operator == "notBlank" && (v.value == null || v.value == ""))
        )
        .map((v) => {
          return {
            ...v,
            source: getSourceByDataMode(v.source),
          };
        });
      return parsedFilters;
    };

    const getWheresFromFieldFilters = (values) => {
      const filters = values
        .filter((v) => v.field?.filters?.length > 0)
        .map((v) => v.field?.filters)
        .flat()
        .filter((f) => f != null);

      return filters.map((f) => {
        return {
          source: getSourceByDataMode(typeof f.column === "string" ? f.column.split["."][0] : ""),
          field: typeof f.column === "string" ? f.column.split(".")[1] : f.column?.name?.length ? f.column.name : "",
          operator: f.operations,
          value: f.value,
        };
      });
    };

    const getUsefullRelations = (select, joins) => {
      const neededTables = select.map((t) => {
        switch (typeof t) {
          case "string":
            return getSourceByDataMode(t.split(".")[0]);
          case "object":
            if (t.field && typeof t.field === "string") {
              return getSourceByDataMode(t.field.split(".")[0]);
            }

            if (t.field && typeof t.field === "object" && typeof t.field.columnA === "string") {
              return getSourceByDataMode(t.field.columnA.split(".")[0]);
            }

            return undefined;
          default:
            return null;
        }
      });

      return joins.filter((j: DatasourceRelation) => {
        return (
          neededTables.indexOf(j.sourceA.split(".")[1]) > -1 ||
          neededTables.indexOf(j.sourceB.split(".")[1]) > -1 ||
          neededTables.indexOf("qa_" + j.sourceA.split(".")[1]) > -1 ||
          neededTables.indexOf("qa_" + j.sourceB.split(".")[1]) > -1
        );
      });
    };

    const getInsFromWheres = (select, joins, wheres, from) => {
      const neededTables = select.map((t) => {
        switch (typeof t) {
          case "string":
            return t.split(".")[0];
          case "object":
            if (t.field && typeof t.field === "string") {
              return t.field.split(".")[0];
            }

            if (t.field && typeof t.field === "object" && typeof t.field.columnA === "string") {
              return t.field.columnA.split(".")[0];
            }

            if (
              t.field &&
              typeof t.field === "object" &&
              typeof t.field.columnA === "object" &&
              typeof t.field.columnA.columnA === "string"
            ) {
              return t.field.columnA.columnA.split(".")[0];
            }

            return undefined;
          default:
            return null;
        }
      });

      const validFilters = wheres.filter((w) => neededTables.indexOf(getSourceByDataMode(w.source)) === -1);

      const tablesReferencedInTheValidFilters = validFilters.map((filter) => getSourceByDataMode(filter.source));

      const validJoins = joins.filter(
        (j) =>
          (tablesReferencedInTheValidFilters.indexOf(getSourceByDataMode(j.sourceA.split(".")[1])) > -1 &&
            getSourceByDataMode(j.sourceB.split(".")[1]) === from) ||
          (tablesReferencedInTheValidFilters.indexOf(getSourceByDataMode(j.sourceB.split(".")[1])) > -1 &&
            getSourceByDataMode(j.sourceA.split(".")[1]) === from)
      );

      return validJoins.map((j) => {
        return {
          mainTable: getSourceByDataMode(from),
          mainTableColumn: j.sourceA.split(".")[1] === from ? j.columnA : j.columnB,
          selectingTable: getSourceByDataMode(
            getSourceByDataMode(j.sourceA.split(".")[1]) === from ? j.sourceB.split(".")[1] : j.sourceA.split(".")[1]
          ),
          selectingTableColumn: getSourceByDataMode(j.sourceA.split(".")[1]) === from ? j.columnB : j.columnA,
          filter: validFilters
            .filter(
              (f) =>
                (f.source === getSourceByDataMode(j.sourceA.split(".")[1]) &&
                  getSourceByDataMode(j.sourceB.split(".")[1]) === from) ||
                (f.source === getSourceByDataMode(j.sourceB.split(".")[1]) &&
                  getSourceByDataMode(j.sourceA.split(".")[1]) === from)
            )
            .map((f) => {
              let source = getSourceByDataMode(f.source);
              let field = f.field;
              return {
                source: source,
                field: field,
                operator: f.operator,
                value: [f.value],
                notIn: f.operator == "<>" || f.operator == "not like" ? true : false,
                logicOperation: f.logicOperation,
              };
            }),
        };
      });
    };

    const getInsFromLayout = (select, joins, layout, from) => {
      const neededTables = select.map((t) => {
        switch (typeof t) {
          case "string":
            return t.split(".")[0];
          case "object":
            if (t.field && typeof t.field === "string") {
              return t.field.split(".")[0];
            }

            //if (t.field && typeof t.field === "object" && typeof t.field.columnA === "string") {
            //  return t.field.columnA.split(".")[0];
            //}

            return undefined;
          default:
            return null;
        }
      });

      const validDropdowns = layout
        .filter((l) => l.kpiType === "dropdown" && l.i != props.index)
        .filter((l) => l.metadata !== undefined)
        .filter((l) => l.metadata.values && l.metadata.values.length)
        .filter(
          (l) =>
            l.metadata.axis.length > 0 &&
            typeof l.metadata.axis[0] === "string" &&
            neededTables.indexOf(getSourceByDataMode(l.metadata.axis[0].split(".")[0])) === -1
        );

      const tablesReferencedInTheValidDropdowns = validDropdowns.map((dropdown) =>
        getSourceByDataMode(dropdown.metadata.axis[0].split(".")[0])
      );
      //.filter(l => l.metadata.axis[0].split(".")[0]);
      //.map((dropdown) => {
      //  let source = getSourceByDataMode(dropdown.metadata.axis[0].split(".")[0]);
      //  let field = dropdown.metadata.axis[0].split(".")[1];
      //  return {
      //    source: source,
      //    field: field,
      //    operator: "eq",
      //    value: dropdown.metadata.values.map((v) => v.value),
      //  };
      //});

      const validJoins = joins.filter(
        (j) =>
          (tablesReferencedInTheValidDropdowns.indexOf(getSourceByDataMode(j.sourceA.split(".")[1])) > -1 &&
            getSourceByDataMode(j.sourceB.split(".")[1]) === from) ||
          (tablesReferencedInTheValidDropdowns.indexOf(getSourceByDataMode(j.sourceB.split(".")[1])) > -1 &&
            getSourceByDataMode(j.sourceA.split(".")[1]) === from)
      );

      return validJoins.map((j) => {
        return {
          mainTable: getSourceByDataMode(from),
          mainTableColumn: j.sourceA.split(".")[1] === from ? j.columnA : j.columnB,
          selectingTable: getSourceByDataMode(
            getSourceByDataMode(j.sourceA.split(".")[1]) === from ? j.sourceB.split(".")[1] : j.sourceA.split(".")[1]
          ),
          selectingTableColumn: getSourceByDataMode(j.sourceA.split(".")[1]) === from ? j.columnB : j.columnA,
          filter: validDropdowns
            .filter(
              (d) =>
                (d.metadata.axis[0].split(".")[0] === j.sourceA.split(".")[1] &&
                  getSourceByDataMode(j.sourceB.split(".")[1]) === from) ||
                (d.metadata.axis[0].split(".")[0] === j.sourceB.split(".")[1] &&
                  getSourceByDataMode(j.sourceA.split(".")[1]) === from)
            )
            .map((dropdown) => {
              let source = getSourceByDataMode(dropdown.metadata.axis[0].split(".")[0]);
              let field = dropdown.metadata.axis[0].split(".")[1];
              return {
                source: source,
                field: field,
                operator: "eq",
                value: dropdown.metadata.values.map((v) => v.value),
                logicOperation: "and",
              };
            }),
        };
      });
    };

    const getMeasuresFromWheres = (wheres, measures) => {
      let extraMeasures = [];

      wheres.forEach((w) => {
        if (w.field && w.field.indexOf("##") === 0) {
          const foundMeasure = getColumnsFromDatasource(datasource).filter((c) => c.value.name === w.field);
          if (foundMeasure.length === 1) {
            if (measures.filter((m) => m.field.name === foundMeasure[0].value?.name).length === 0) {
              extraMeasures.push({
                aggregator: "",
                dataType: "",
                decimalPlaces: "",
                seriesName: "",
                field: foundMeasure[0].value,
              });

              if (typeof foundMeasure[0].value.columnA === "object") {
                extraMeasures.push({
                  aggregator: "",
                  dataType: "",
                  decimalPlaces: "",
                  seriesName: "",
                  field: foundMeasure[0].value.columnA,
                });
              }

              if (typeof foundMeasure[0].value.columnB === "object") {
                extraMeasures.push({
                  aggregator: "",
                  dataType: "",
                  decimalPlaces: "",
                  seriesName: "",
                  field: foundMeasure[0].value.columnB,
                });
              }
            }
          }
        }
      });

      return extraMeasures;
    };

    const getWhereInsFromLayout = (select, layout) => {
      /* if (props.type === "dropdown") {
      return [];
    } */

      //const neededTables = select.map((t) => {
      //  switch (typeof t) {
      //    case "string":
      //      return t.split(".")[0];
      //    case "object":
      //      if (t.field && typeof t.field === "string") {
      //        return t.field.split(".")[0];
      //      }

      //      if (t.field && typeof t.field === "object" && typeof t.field.columnA === "string") {
      //        return t.field.columnA.split(".")[0];
      //      }

      //      return undefined;
      //    default:
      //      return null;
      //  }
      //});
      return (
        layout
          .filter((l) => (l.kpiType === "dropdown" || l.kpiType === "map") && l.i != props.index)
          .filter((l) => l.metadata !== undefined)
          .filter(
            (l) =>
              l.metadata.values &&
              l.metadata.values.length &&
              Object.hasOwn(l.metadata.values[0], "value") &&
              l.metadata.axis &&
              l.metadata.axis.length
          )
          .filter((l) => l.metadata.axis[0].variableName == null)
          //.filter((l) => neededTables.indexOf(getSourceByDataMode(l.metadata.axis[0].split(".")[0])) > -1)
          .filter((dropdown) => {
            if (
              props.metadata?.allExceptFilters != null &&
              props.metadata?.allExceptFilters.field?.length > 0 &&
              props.metadata.allExceptFilters.field.indexOf(dropdown.i) != -1
            ) {
              return false;
            }
            {
              return true;
            }
          })
          .map((dropdown) => {
            let source = getSourceByDataMode(dropdown.metadata.axis[0].split(".")[0]);
            let field = dropdown.metadata.axis[0].split(".")[1];
            return {
              source: source,
              field: field,
              operator: "eq",
              value: dropdown.metadata.values.map((v) => v.value),
            };
          })
      );
    };

    const getWheresFromFilters = (filters) => {
      return filters && filters.length
        ? parseValidWhereFromFilters(
          filters.map((f) => {
            return {
              source: typeof f.column === "string" ? f.column.split(".")[0] : "",
              field:
                typeof f.column === "string" ? f.column.split(".")[1] : f.column?.name?.length ? f.column.name : "",
              operator: f.operation,
              logicOperation: f.logicOperation,
              value: f.value,
            };
          })
        )
        : [];
    };

    const getVariableFilters = (layout) => {
      if (props.metadata.variableFilters != null && props.metadata.variableFilters.length) {
        const validFilters = parseValidVariableFilters(props.metadata.variableFilters);
        let variableFilters = [];
        validFilters.forEach((filter) => {
          const meaningFullVariableSelectors = layout
            .filter((v) => v.metadata?.axis?.length)
            .filter(
              (v) =>
                v.metadata.axis[0].variableName != null &&
                v.metadata.axis[0].variableName === filter.variable.variableName
            )
            .filter((v) => v.metadata?.values?.length);

          meaningFullVariableSelectors.forEach((vs) => {
            const value = vs.metadata.values[0].value;
            if (value && value.length && value[0] != null) {
              variableFilters.push({
                field:
                  typeof filter.column === "string"
                    ? filter.column.split(".")[1]
                    : filter.column?.name?.length
                      ? filter.column.name
                      : "",
                source: typeof filter.column === "string" ? filter.column.split(".")[0] : "",
                operator: filter.operation,
                logicOperation: filter.logicOperation,
                value: value,
              });
            }
          });
        });
        return variableFilters;
      } else {
        return [];
      }
    };

    const getUserIdFilter = () => {
      if (props.filter == "USER") {
        let userIdFilters = [];
        if (datasource.sourceType == "mongo") {
          userIdFilters.push({ source: "table", field: "createdBy", operator: "=", value: selectedSource.id });
        }
        let envolvedTables = getEnvolvedTables();
        let tablesWithUserIdColumn = [];

        envolvedTables.forEach((tableName) => {
          let tableColumns = datasource.resources.find((res) => res.name === tableName).columns;
          let columnsWithUserId = tableColumns.filter((tC) => tC.name == "user_id");
          if (columnsWithUserId.length > 0) {
            tablesWithUserIdColumn = tablesWithUserIdColumn.concat(
              columnsWithUserId.map((c) => {
                c.table = tableName;
                return c;
              })
            );
          }
        });
        if (tablesWithUserIdColumn.length > 0) {
          tablesWithUserIdColumn.forEach((column) => {
            userIdFilters.push({
              source: getSourceByDataMode(column.table),
              field: column.name,
              operator: "=",
              value: selectedSource.id,
            });
          });
          return userIdFilters;
        } else {
          return userIdFilters;
        }
      } else {
        return [];
      }
    };

    const getEnvolvedTables = (indexFlag) => {
      let envolvedTables = [];
      if (props.metadata.axis && props.metadata.axis.length) {
        envolvedTables.push(getSourceByDataMode(props.metadata.axis[0].split(".")[0]));
      }
      if (props.metadata.values) {
        props.metadata.values.map((val, valueIndex) => {
          if (
            val.field != null &&
            val.field != "" &&
            envolvedTables.indexOf(getSourceByDataMode(val.field.split(".")[0])) == -1
          ) {
            envolvedTables.push(getSourceByDataMode(val.field.split(".")[0]));
          }
        });
      }
      if (
        indexFlag &&
        envolvedTables.length > 0 &&
        datasource.resources.find((res) => res.name === envolvedTables[0]) != null
      ) {
        return datasource.resources.find((res) => res.name === envolvedTables[0]).tabIndex;
      } else if (indexFlag == null) {
        return envolvedTables;
      }
    };

    const getSourceByDataMode = useCallback(
      (source) => {
        if (datasource.sourceType == "mongo") {
          return source;
        } else {
          if (typeof source == "object") {
            return source;
          }
          if (props.dataMode == "dev" && source?.indexOf("qa_") != 0) {
            return "qa_" + source;
          }
          return source;
        }
      },
      [props.dataMode]
    );

    const transformMeasureSourceByDataMode = (measure) => {
      if (props.dataMode == "dev") {
        return {
          ...measure,
          columnA:
            measure.columnA && measure.columnA.length && measure.columnA.indexOf("qa_") != 0
              ? `qa_${measure.columnA?.split(".")[0]}.${measure.columnA?.split(".")[1]}`
              : measure.columnA && typeof measure.columnA == "object"
                ? transformMeasureSourceByDataMode(measure.columnA)
                : "",
          columnB:
            measure.columnB && measure.columnB.length && measure.columnB.indexOf("qa_") != 0
              ? `qa_${measure.columnB?.split(".")[0]}.${measure.columnB?.split(".")[1]}`
              : measure.columnB && typeof measure.columnB == "object"
                ? transformMeasureSourceByDataMode(measure.columnB)
                : "",
          filters:
            measure.filters && measure.filters.length
              ? transformMeasureFilterSourceByDataMode(JSON.parse(JSON.stringify(measure)).filters)
              : "",
          allExcept:
            measure.allExcept && measure.allExcept.length
              ? measure.allExcept.map((ae) => {
                return {
                  ...ae,
                  value: `qa_${ae.value}`,
                };
              })
              : null,
        };
      }
      return measure;
    };

    const transformMeasureFilterSourceByDataMode = (filters) => {
      if (props.dataMode == "dev") {
        let newFilters = [...filters];
        return newFilters.map((el) => {
          return {
            ...el,
            column:
              el.column && el.column.length && el.column.indexOf("qa_") != 0
                ? `qa_${el.column?.split(".")[0]}.${el.column?.split(".")[1]}`
                : el.column,
          };
        });
      }
      return filters;
    };

    const getBackgroundColors = (dataLength, index) => {
      if (
        props.metadata.chartAlternativePalette != null &&
        props.metadata.chartAlternativePalette != "" &&
        (props.metadata.labels == null || props.metadata.labels.length == 0)
      ) {
        let colors = [];

        for (let j = 0, i = 0; j < props.metadata.chartAlternativePalette.length - 1 && i < dataLength; j++, i++) {
          if (props.metadata.chartAlternativePalette[7] == null) {
            colors.push(hexToRGBA(props.metadata.chartAlternativePalette[j], 1));
          } else {
            colors.push(
              hexToRGBA(props.metadata.chartAlternativePalette[j], props.metadata.chartAlternativePalette[7])
            );
          }
          if (j === props.metadata.chartAlternativePalette.length - 1) {
            j = 0;
          }
        }

        return colors;
      } else if (
        props.metadata.chartPrimaryColor != null &&
        props.metadata.chartPrimaryColor != "" &&
        !Array.isArray(props.metadata.chartPrimaryColor) &&
        index == 0 &&
        (props.metadata.labels == null || props.metadata.labels.length == 0)
      ) {
        return props.metadata.chartPrimaryColor;
      } else if (
        props.metadata.chartSeriesColorMode == null ||
        props.metadata.chartSeriesColorMode == "single" ||
        props.type == "line" ||
        (props.metadata.labels != null && props.metadata.labels.length > 0)
      ) {
        let calculatedIndex = index;
        if (index > 6) {
          calculatedIndex = index % 7;
        }

        if (props.metadata.chartAlternativePalette != null && props.metadata.chartAlternativePalette != "") {
          if (colorPalette[7] == null) {
            return hexToRGBA(props.metadata.chartAlternativePalette[calculatedIndex], 1);
          } else {
            return hexToRGBA(
              props.metadata.chartAlternativePalette[calculatedIndex],
              props.metadata.chartAlternativePalette[7]
            );
          }
        } else {
          // Does not have alpha channel
          if (colorPalette[7] == null) {
            return hexToRGBA(colorPalette[calculatedIndex], 1);
          } else {
            return hexToRGBA(colorPalette[calculatedIndex], colorPalette[7]);
          }
        }

        /* if (index > 0) {
          let colorPaletteIndex = Math.floor(index / 6);
          if (colorPaletteIndex % 2 == 0) {
            return adjustColor(colorPalette[colorPaletteIndex], index * 20);
          } else {
            return adjustColor(colorPalette[colorPaletteIndex], (index - 5) * -20);
          }
        } else {
          return colorPalette[index];
        } */
      } else if (props.metadata.chartSeriesColorMode == "multiple") {
        let colors = [];

        for (let j = 0, i = 0; j < colorPalette.length - 1 && i < dataLength; j++, i++) {
          if (colorPalette[7] == null) {
            colors.push(hexToRGBA(colorPalette[j], 1));
          } else {
            colors.push(hexToRGBA(colorPalette[j], colorPalette[7]));
          }
          if (j === colorPalette.length - 1) {
            j = 0;
          }
        }

        return colors;
      }
    };

    const hexToRGBA = (hex, alpha) => {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + alpha + ")";
      } else {
        return hex;
      }
    };

    const rgbaToHex = (orig) => {
      var a,
        isPercent,
        rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = ((rgb && rgb[4]) || "").trim(),
        hex = rgb
          ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
          : orig;

      if (alpha !== "") {
        a = alpha;
      } else {
        a = 1;
      }
      // multiply before convert to HEX
      a = ((a * 255) | (1 << 8)).toString(16).slice(1);
      hex = hex + a;

      return hex;
    };

    const adjustColor = (color, percentage) => {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + percentage)).toString(16)).substr(-2)
          )
      );
    };

    const getExportData = () => {
      return filteredData.datasets && filteredData.datasets.length ? filteredData.datasets[0].data : [];
    };

    const duplicateChart = (event) => {
      duplicateKPI(event, props.index);
    };

    const removeChart = (event) => {
      removeKPI(event, props.index);
    };

    const exportExcel = (event) => {
      //<ExcelSVheet data={exportData || []} name="1">
      //  {Object.keys(exportData.length ? exportData[0] : []).map((col) => (
      //    <ExcelColumn label={col} value={col} key={col} />
      //  ))}
      //</ExcelSheet>
      if (exportData.length) {
        // create workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        XLSX.utils.book_append_sheet(workbook, worksheet, "kstk");

        // customize header names
        //XLSX.utils.sheet_add_aoa(worksheet, [["Product ID", "Product Name", "Product Category"]]);

        XLSX.writeFile(
          workbook,
          props.metadata.title != null && props.metadata.title != ""
            ? props.metadata.title + ".xlsx"
            : "kstk-export.xlsx",
          { compression: true }
        );
      }
    };

    const hasExport = () => {
      if (props.clientReportPermissions?.download == false) {
        return false;
      }
      const nonExportable = ["card", "dropdown", "label", "image", "map"];
      if (nonExportable.indexOf(props.type) > -1 || exportData == null) {
        return false;
      }

      return true;
    };

    const getTextAlignment = (alignment) => {
      switch (alignment) {
        case "start":
          return "left";
        case "center":
          return "center";
        case "end":
          return "right";
        default:
          break;
      }
    };

    const getGraphScrollableWrapperStyle = () => {
      // exportData?.length < 6 && props.type != "table"
      if (["label"].indexOf(props.type) != -1) {
        return { overflow: "auto" };
      }
      if (
        (props.metadata &&
          (props.metadata.chartOverflow == null || props.metadata.chartOverflow == "") &&
          (props.metadata.forceChartOverflow == null || props.metadata.forceChartOverflow == false)) ||
        dataFilteredBySelection
      ) {
        return { overflow: "hidden" };
      }
      if (["bar", "stackedBar", "waterfall", "line", "table"].indexOf(props.type) != -1) {
        return { overflowX: "scroll", overflowY: "hidden" };
      } else if (["horizontalBar", "horizontalStackedBar"].indexOf(props.type) != -1) {
        return { overflowX: "hidden", overflowY: "scroll" };
      }
    };

    const getGraphWrapperStyle = () => {
      if (props.type == "card") {
        return { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" };
      }
      if (
        props.metadata == null ||
        (props.metadata &&
          (props.metadata.chartOverflow == null || props.metadata.chartOverflow == "") &&
          (props.metadata.forceChartOverflow == null || props.metadata.forceChartOverflow == false)) ||
        dataFilteredBySelection
      ) {
        return { height: "100%", width: "100%" };
      }
      if (props.metadata && props.metadata.chartOverflow != null && props.metadata.chartOverflow != "") {
        if (["bar", "stackedBar", "waterfall", "line"].indexOf(props.type) != -1) {
          return { height: "100%", width: props.metadata.chartOverflow + "px" };
        } else if (["horizontalBar", "horizontalStackedBar"].indexOf(props.type) != -1) {
          return { width: "100%", height: props.metadata.chartOverflow + "px" };
        } else {
          return { height: "100%", width: props.metadata.chartOverflow + "px" };
        }
      }
    };

    return (
      <Wrapper
        {...props}
        className={
          (props.index === selectedChart && props.mode == "edit" ? "selected" : "") +
          " " +
          (components[props.type].overflowUnset ? "unset-overflow" : "") +
          " " +
          props.type +
          "ChartType" +
          " " +
          props.mode +
          "Mode" +
          " " +
          (props.metadata && props.metadata.title && props.metadata.title != "" ? "true" : "false") +
          "HasTitle" +
          " " +
          (props.type != "label" && props.type != "image" && props.type != "dropdown" ? "true" : "false") +
          "HasShadow" +
          " " +
          props.index
        }
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={() => {
          kpiClick(props.index);
        }}
      >
        <KPIOptions>
          <KPIOptionsToggle size="sm" color="light" className={props.mode + "Mode" + " " + hasExport() + "HasExport"}>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </KPIOptionsToggle>
          <DropdownMenu
            end
            className={props.mode + "Mode" + " " + hasExport() + "HasExport" + " kstk-dropdown-menu"}
            container={"body"}
            size={"20px"}
          >
            <KPIOptionsItem onClick={(e) => removeChart(e)} className={props.mode + "Mode"}>
              <i className="fa-solid fa-trash" />
            </KPIOptionsItem>
            <KPIOptionsItem onClick={(e) => duplicateChart(e)} className={props.mode + "Mode"}>
              <i className="fas fa-clone" />
            </KPIOptionsItem>
            <KPIOptionsItem onClick={clearCache} className={props.mode + "Mode"}>
              <i className="fa-solid fa-arrows-rotate" />
            </KPIOptionsItem>

            {props.type !== "map" ? (
              (hasExport() && props.type !== "table") || datasource.sourceType == "mongo" ? (
                <KPIOptionsItem onClick={(e) => exportExcel(e)}>
                  <i className="fa-solid fa-file-excel" />
                </KPIOptionsItem>
              ) : (
                <React.Fragment>
                  <KPIOptionsItem onClick={startExportTableData}>
                    <i className="fa-solid fa-file-excel" />
                  </KPIOptionsItem>
                </React.Fragment>
              )
            ) : null}
            {hasExport() ? (
              <React.Fragment>
                <KPIOptionsItem className={hasExport() + "HasExport"} onClick={createPagePDF}>
                  <i className="fa-solid fa-file-pdf" />
                </KPIOptionsItem>
                <KPIOptionsItem className={hasExport() + "HasExport"} onClick={createImage}>
                  <i className="fa-solid fa-file-image" />
                </KPIOptionsItem>
              </React.Fragment>
            ) : null}
          </DropdownMenu>
        </KPIOptions>
        {props.metadata && props.type !== "label" && props.metadata.title && props.metadata.title != "" ? (
          <KPITopArea>
            <Title
              style={{
                fontSize: props.metadata.titleFontSize ? props.metadata.titleFontSize + "px" : "1.25rem",
                fontFamily: props.metadata.titleFontFamily
                  ? props.metadata.titleFontFamily
                  : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                color: props.metadata.titleTextColor ? props.metadata.titleTextColor : null,
                backgroundColor: props.metadata.titleBackgroundColor ? props.metadata.titleBackgroundColor : null,
                fontWeight: props.metadata.titleFontBold ? 700 : 500,
                fontStyle: props.metadata.titleFontItalic ? "italic" : "initial",
                textDecoration: props.metadata.titleFontUnderline ? "underline" : "none",
                textAlign: props.metadata.titleTextAlign
                  ? getTextAlignment(props.metadata.titleTextAlign)
                  : props.type == "card"
                    ? "center"
                    : "left",
              }}
            >
              {props.metadata.title}
            </Title>
          </KPITopArea>
        ) : null}
        <GraphScrollableWrapper style={getGraphScrollableWrapperStyle()} id={"graphScrollableWrapper" + props.index}>
          <GraphWrapper
            onClick={chartCanvasClicked}
            style={getGraphWrapperStyle()}
            ref={printRef}
            id={"graphWrapper" + props.index}
          >
            {props.type === "table" ||
              props.type === "dropdown" ||
              props.type === "map" ||
              props.type === "image" ||
              props.type === "label" ||
              props.type === "card" ? (
              <Graph
                type={props.type}
                filteredData={filteredData}
                options={options}
                mode={props.mode}
                metadata={memoizedMetadata}
                dataMode={props.dataMode}
                onDemandDataUpdate={props.onDemandDataUpdate}
                getTableData={higherOrderGetTableData}
                exportTableData={higherOrderExportTableData}
                convertDataTypes={convertDataTypes}
                checkPivotTableWrappers={checkPivotTableWrappers}
                index={props.index}
                startExportTableData={exportingTableData}
                onExportTableDataEnd={onExportTableDataEnd}
                getSourceByDataMode={getSourceByDataMode}
                getAbbreviatedNumber={getAbbreviatedNumber}
                getCardParsedValue={getCardParsedValue}
              />
            ) : (
              <Graph
                type={props.type}
                filteredData={filteredData}
                options={options}
                mode={props.mode}
                index={props.index}
              />
            )}
            {isLoading ? (
              <LoadingVisual>
                <ProgressLoader animated value="100" />
              </LoadingVisual>
            ) : null}
          </GraphWrapper>
        </GraphScrollableWrapper>
      </Wrapper>
    );
  },
  (prevProps, nextProps) => {
    const diff = JSON.stringify(prevProps) == JSON.stringify(nextProps);
    return diff;
  }
);
