// @flow

import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import classnames from "classnames";
import {
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Button,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";
import { ReportContext } from "./reporting/ReportContext";
import { KSTKSidePanel } from "./KSTKSidePanel";
import KSTKEmptyContent from "./KSTKEmptyContent";
import { KSTKValueFieldSidePanel } from "./KSTKValueFieldSidePanel";
import { KSTKMultipleColumnFunctionFieldSidePanel } from "./KSTKMultipleColumnFunctionFieldSidePanel";
import { KSTKColumnAggregatorFieldSidePanel } from "./KSTKColumnAggregatorFieldSidePanel";
import { KSTKSelect } from "./KSTKSelect";
import { KSTKFontEditor } from "./KSTKFontEditor";
import KSTKFilterField from "./KSTKFilterField";
import { getColumnsFromDatasource, ColumnTypes } from "../util/report.util";
import { createFiles, deleteFiles, getFilesFromContainer } from "../services/file.service";

import type { ChartMetadataValue } from "./KSTKComponentTypes";
import type { ChartMetadataRowColumnAggregator } from "./KSTKComponentTypes";

const VisualsListWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & > * {
    padding: 5px;
  }
`;

const VisualsChartListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SettingsWrapper = styled.div`
  height: 100%;
  padding: 5px;
`;

const SettingsTabContent = styled(TabContent)`
  height: 500px;
  width: 100%;
  overflow: auto;
`;

const VisualsSettingsWrapper = styled.div`
  padding: 10px;
`;

const KSTKNav = styled(Nav)`
  padding-bottom: 5px;
`;

const KSTKDarkNavLink = styled(NavLink)`
  color: var(--gray-dark);
  :not(active) {
    color: var(--white);
  }
`;

const KSTKChartIcon = styled.i`
  &.selected {
    color: var(--yellow);
  }
`;

const KSTKSeparator = styled.div`
  border-top: 1px solid var(--red);
`;

const ValuesWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 30px) 30px;
  padding: 5px 0 5px 0;
  align-items: center;
`;

const ValuesOperations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const FieldAreaWrapper = styled.div`
  margin: 2px;
  padding: 5px;
  background-color: #ebeff7;
  border-radius: 0.25rem;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  padding: 5px 0 5px 0;
`;

// doughnut: Charts.Doughnut,
//     pie: Charts.Pie,
//     line: Charts.Line,
//     label: KSTKReporting.KSTKLabel,
//     dropdown: KSTKReporting.KSTKSelectionDropdown

function VisualsList(props) {
  const { selectedChart, layout, overflowVisuals, removeOverflowKPIs } = useContext(ReportContext);

  const [chartsList] = useState([
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "waterfall",
    "pie",
    "doughnut",
    "line",
    "label",
    "image",
    "dropdown",
    "card",
    "table",
    "map",
  ]);

  const [chartLogo] = useState({
    bar: "fa-chart-column",
    horizontalBar: "fa-chart-bar",
    stackedBar: "fa-chart-gantt",
    horizontalStackedBar: "fa-chart-gantt",
    waterfall: "fa-water",
    pie: "fa-chart-pie",
    doughnut: "fa-circle-notch",
    line: "fa-chart-line",
    label: "fa-font",
    image: "fa-image",
    dropdown: "fa-filter",
    card: "fa-square",
    table: "fa-table",
    map: "fa-map",
  });

  const [chartName] = useState({
    bar: "Vertical Bar",
    horizontalBar: "Horizontal Bar",
    stackedBar: "Vertical Stacked Bar",
    horizontalStackedBar: "Horizontal Stacked Bar",
    waterfall: "Waterfall",
    pie: "Pie",
    doughnut: "Doughnut",
    line: "Line",
    label: "Text",
    image: "Image",
    dropdown: "Filter",
    card: "Card",
    table: "Table",
    map: "Map",
  });

  useEffect(() => { }, [selectedChart, layout]);

  const { visualPanelIconClick } = useContext(ReportContext);

  function onVisualClick(type) {
    visualPanelIconClick(type);
  }

  function getChartLogo(chart) {
    return chartLogo[chart] || "";
  }

  function getChartName(chart) {
    return chartName[chart] || "";
  }

  function getSelectedState(chart) {
    const chartInLayout = layout.find((el) => el.i === selectedChart);
    if (!chartInLayout) {
      return "";
    } else {
      return chartInLayout.kpiType === chart ? "selected" : "";
    }
  }

  return (
    <VisualsListWrapper>
      <label htmlFor="visualsList">Visuals list</label>
      <VisualsChartListWrapper>
        {chartsList.map((c, index) => (
          <div id={c + index} key={c + index} value={c} onClick={() => onVisualClick(c)}>
            <KSTKChartIcon className={`fas ${getChartLogo(c)} ${getSelectedState(c)}`} />
            <UncontrolledTooltip target={c + index}>{getChartName(c)}</UncontrolledTooltip>
          </div>
        ))}
      </VisualsChartListWrapper>
      {overflowVisuals != null && overflowVisuals.length > 0 ? (
        <Alert color="danger" style={{ textAlign: "center" }}>
          {overflowVisuals.length + " visuals out of bounds "}
          <i className="far fa-times-circle" onClick={(e) => removeOverflowKPIs(e)} />
        </Alert>
      ) : null}
    </VisualsListWrapper>
  );
}

const VisualSettingsTabContent = styled(TabContent)`
  overflow: auto;
  height: calc(100% - 25px);
`;

function VisualSettings(props) {
  const { datasource, selectedChartMetadata, setSelectedChartMetadata, selectedChart, layout, report } =
    useContext(ReportContext);

  const [fieldValues, setFieldValues] = useState([{}]);
  const [fieldTooltips, setFieldTooltips] = useState([{}]);
  const [rowAggregators, setFieldRowAggregators] = useState({});
  const [columnGrouping, setColumnGrouping] = useState({});
  const [columnPivotColumns, setPivotColumns] = useState({});
  const [columnPivotsAggregators, setColumnPivotsAggregators] = useState([]);
  const [columnPivotsAggregatorsList, setColumnPivotsAggregatorsList] = useState([]);
  const [columnAggregators, setFieldColumnAggregators] = useState([{}]);
  const [columnAggregatorsList, setColumnAggregatorsList] = useState([]);
  const [columnAggregatorLabel, setFieldColumnAggregatorLabel] = useState("Total");
  const [imageUrl, setFieldImageUrl] = useState(null);
  const [imageFile, setFieldImageFile] = useState(null);

  const [rowHeight, setFieldRowHeight] = useState("");
  const [dataLabelFontSize, setFieldDataLabelFontSize] = useState("");
  const [chartOverflow, setFieldChartOverflow] = useState("");
  const [chartOverflowWithFilters, setFieldChartOverflowWithFilters] = useState("");
  const [friendlyNumberDecimalPlaces, setFieldFriendlyNumberDecimalPlaces] = useState("");
  const [map, setFieldMap] = useState("");
  const [mapZoom, setFieldMapZoom] = useState("");
  const [allExceptFilters, setAllExceptFilters] = useState({});
  const [headerGroupingWidth, setHeaderGroupingWidth] = useState("");
  const [lineTotalsWidth, setLineTotalsWidth] = useState("");
  const [headerGroupingTitle, setHeaderGroupingTitle] = useState("");
  const [pivotAggregatorColumnWidth, setPivotAggregatorColumnWidth] = useState("");

  const [columnSettings, setFieldColumnSettings] = useState([]);

  const [typeDoesntHaveValueFields] = useState(["label", "image", "dropdown", "map"]);
  const [typeDoesntHaveLabelFields] = useState(["label", "image", "dropdown", "card", "table", "map"]);
  const [typeDoesntHaveTooltipFields] = useState(["label", "image", "dropdown", "card", "table"]);
  const [typeDoesntHaveAxisFields] = useState(["label", "image", "table"]);
  const [typeDoesntHaveTitleFields] = useState(["image"]);
  const [typeHasCardField] = useState(["card"]);
  const [typeDoesntHaveImagesFields] = useState([
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "waterfall",
    "pie",
    "doughnut",
    "line",
    "label",
    "dropdown",
    "card",
    "table",
    "map",
  ]);
  const [typeDoesntHaveFiltersFields] = useState(["label", "image"]);
  const [typeDoesntHaveChartOverflow] = useState(["label", "image", "dropdown", "card", "pie", "doughnut", "map"]);
  const [typeHasShowLegend] = useState([
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "waterfall",
    "pie",
    "doughnut",
    "line",
  ]);
  const [typeHasFriendlyNumber] = useState([
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "waterfall",
    "pie",
    "doughnut",
    "line",
    "card",
  ]);
  const [typeHasDatalabelPositioning] = useState([
    "dropdown",
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "line",
  ]);
  const [typeHaveChartDataOrder] = useState([
    "dropdown",
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "line",
    "pie",
    "doughnut",
  ]);
  const [typeIsDropdown] = useState(["dropdown"]);
  const [typeIsMap] = useState(["map"]);
  const [typeHasAllExceptFilters] = useState([
    "dropdown",
    "bar",
    "horizontalBar",
    "stackedBar",
    "horizontalStackedBar",
    "waterfall",
    "pie",
    "doughnut",
    "line",
    "card",
    "table",
  ]);
  const [typeDoesntHaveOptimizationFields] = useState(["label", "image", "map"]);

  const [activeTab, setActiveTab] = useState("1");
  const [tableMode, setTableMode] = useState("table");

  useEffect(() => {
    setFieldValues(
      selectedChartMetadata &&
        selectedChartMetadata.values &&
        selectedChartMetadata.values.length &&
        typeof selectedChartMetadata.values !== "string"
        ? selectedChartMetadata.values.map((v: ChartMetadataValue) => {
          return {
            value: v.field,
            operationValue: v.aggregator,
            seriesName: v.seriesName,
            dataTypeValue: v.dataType,
            decimalPlaces: v.decimalPlaces,
          };
        })
        : [{}]
    );
    setFieldTooltips(
      selectedChartMetadata &&
        selectedChartMetadata.tooltips &&
        selectedChartMetadata.tooltips.length &&
        typeof selectedChartMetadata.tooltips !== "string"
        ? selectedChartMetadata.tooltips.map((v: ChartMetadataValue) => {
          return {
            value: v.field,
            operationValue: v.aggregator,
            seriesName: v.seriesName,
            dataTypeValue: v.dataType,
            decimalPlaces: v.decimalPlaces,
          };
        })
        : [{}]
    );
    setFieldRowAggregators(
      selectedChartMetadata && selectedChartMetadata.rowAggregators
        ? {
          value: selectedChartMetadata.rowAggregators.field,
          operationValue: selectedChartMetadata.rowAggregators.aggregator,
        }
        : {}
    );
    setColumnGrouping(
      selectedChartMetadata && selectedChartMetadata.columnGrouping
        ? {
          value: selectedChartMetadata.columnGrouping.field,
          operationValue: selectedChartMetadata.columnGrouping.aggregator,
        }
        : {}
    );
    setPivotColumns(
      selectedChartMetadata && selectedChartMetadata.columnPivotColumns
        ? {
          value: selectedChartMetadata.columnPivotColumns.field,
          operationValue: selectedChartMetadata.columnPivotColumns.aggregator,
        }
        : {}
    );
    setFieldColumnAggregators(
      selectedChartMetadata &&
        selectedChartMetadata.columnAggregators &&
        selectedChartMetadata.columnAggregators.length &&
        typeof selectedChartMetadata.columnAggregators !== "string"
        ? selectedChartMetadata.columnAggregators.map((v: ChartMetadataRowColumnAggregator) => {
          return {
            value: v.field,
            operationValue: v.aggregator,
          };
        })
        : [{}]
    );
    setColumnAggregatorsList(
      selectedChartMetadata &&
        selectedChartMetadata.columnAggregatorsList &&
        selectedChartMetadata.columnAggregatorsList.length
        ? selectedChartMetadata.columnAggregatorsList
        : []
    );
    setColumnPivotsAggregators(
      selectedChartMetadata &&
        selectedChartMetadata.columnPivotsAggregators &&
        selectedChartMetadata.columnPivotsAggregators.length &&
        typeof selectedChartMetadata.columnPivotsAggregators !== "string"
        ? selectedChartMetadata.columnPivotsAggregators.map((v: ChartMetadataRowColumnAggregator) => {
          return {
            value: v.field,
            operationValue: v.aggregator,
          };
        })
        : [{}]
    );
    setColumnPivotsAggregatorsList(
      selectedChartMetadata &&
        selectedChartMetadata.columnPivotsAggregatorsList &&
        selectedChartMetadata.columnPivotsAggregatorsList.length
        ? selectedChartMetadata.columnPivotsAggregatorsList
        : []
    );
    setFieldColumnAggregatorLabel(
      selectedChartMetadata && selectedChartMetadata.columnAggregatorLabel
        ? selectedChartMetadata.columnAggregatorLabel
        : "Total"
    );
    if (selectedChartMetadata && selectedChartMetadata.imageFile) {
      getFilesFromContainer(selectedChart + "image", selectedChartMetadata.imageFile).then((response) => {
        if (response != null) {
          setFieldImageFile(selectedChartMetadata.imageFile);
        } else {
          setFieldImageFile(null);
          let newSelectedChartMetadata = {
            ...selectedChartMetadata,
          };
          newSelectedChartMetadata.imageFile = null;
          setSelectedChartMetadata(newSelectedChartMetadata);
        }
      });
    } else {
      setFieldImageFile(null);
    }
    setFieldImageUrl(selectedChartMetadata && selectedChartMetadata.imageUrl ? selectedChartMetadata.imageUrl : null);
    setFieldRowHeight(
      selectedChartMetadata && selectedChartMetadata.rowHeight ? selectedChartMetadata.rowHeight : null
    );
    setFieldDataLabelFontSize(
      selectedChartMetadata && selectedChartMetadata.dataLabelFontSize ? selectedChartMetadata.dataLabelFontSize : null
    );
    setFieldChartOverflow(
      selectedChartMetadata && selectedChartMetadata.chartOverflow ? selectedChartMetadata.chartOverflow : null
    );
    setFieldChartOverflowWithFilters(
      selectedChartMetadata && selectedChartMetadata.chartOverflowWithFilters
        ? selectedChartMetadata.chartOverflowWithFilters
        : null
    );
    setFieldFriendlyNumberDecimalPlaces(
      selectedChartMetadata && selectedChartMetadata.friendlyNumberDecimalPlaces
        ? selectedChartMetadata.friendlyNumberDecimalPlaces
        : null
    );
    setFieldMap(selectedChartMetadata && selectedChartMetadata.map ? selectedChartMetadata.map : null);
    setFieldMapZoom(selectedChartMetadata && selectedChartMetadata.mapZoom ? selectedChartMetadata.mapZoom : null);
    setAllExceptFilters(
      selectedChartMetadata && selectedChartMetadata.allExceptFilters
        ? {
          value: selectedChartMetadata.allExceptFilters.field,
          operationValue: selectedChartMetadata.allExceptFilters.aggregator,
        }
        : {}
    );
    setHeaderGroupingTitle(
      selectedChartMetadata && selectedChartMetadata.headerGroupingTitle
        ? selectedChartMetadata.headerGroupingTitle
        : null
    );
    setHeaderGroupingWidth(
      selectedChartMetadata && selectedChartMetadata.headerGroupingWidth
        ? selectedChartMetadata.headerGroupingWidth
        : null
    );
    setLineTotalsWidth(
      selectedChartMetadata && selectedChartMetadata.lineTotalsWidth ? selectedChartMetadata.lineTotalsWidth : null
    );
    setPivotAggregatorColumnWidth(
      selectedChartMetadata && selectedChartMetadata.pivotAggregatorColumnWidth
        ? selectedChartMetadata.pivotAggregatorColumnWidth
        : null
    );
    setFieldColumnSettings(
      selectedChartMetadata && selectedChartMetadata.columnSettings && selectedChartMetadata.columnSettings.length
        ? selectedChartMetadata.columnSettings
        : []
    );
    setTableMode(selectedChartMetadata && selectedChartMetadata.tableMode ? selectedChartMetadata.tableMode : "table");
  }, [selectedChartMetadata]);

  const columnsList = getColumnsFromDatasource(datasource).filter((r) => {
    if (r.type == "COLUMN") {
      return r.value.indexOf("qa_") != 0;
    } else if (r.type == "MEASURE" || r.type == "VARIABLE") {
      return true;
    }
  });

  const orderValueField = (index, direction) => {
    if ((direction == "up" && index > 0) || (direction == "down" && index + 1 < fieldValues.length)) {
      let copyOfValueField = Object.assign({}, fieldValues[index]);
      fieldValues.splice(index, 1);
      let nextFieldValueIndex = direction == "up" ? index - 1 : index + 1;
      fieldValues.splice(nextFieldValueIndex, 0, copyOfValueField);
      setSelectedChartMetadata({
        ...selectedChartMetadata,
        values: fieldValues.map((fv) => {
          return {
            field: fv.value,
            aggregator: fv.operationValue,
            seriesName: fv.seriesName,
          };
        }),
      });
    }
  };

  const removeValueField = (index) => {
    fieldValues.splice(index, 1);

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      values: fieldValues.map((fv) => {
        return {
          field: fv.value,
          aggregator: fv.operationValue,
          seriesName: fv.seriesName,
        };
      }),
    });
  };

  const removeTooltipField = (index) => {
    fieldTooltips.splice(index, 1);

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      tooltips: fieldTooltips.map((fv) => {
        return {
          field: fv.value,
          aggregator: fv.operationValue,
          seriesName: fv.seriesName,
        };
      }),
    });
  };

  const removeColumnAggregatorField = (index) => {
    columnAggregators.splice(index, 1);

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnAggregators: columnAggregators.map((fv) => {
        return {
          field: fv.value,
          aggregator: fv.operationValue,
        };
      }),
      columnAggregatorsList: columnAggregators.filter((el) => el.field != "" && el.field != null).map((el) => el.field),
    });
  };

  const removeColumnPivotField = (index) => {
    columnPivotsAggregators.splice(index, 1);

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnPivotsAggregators: columnPivotsAggregators.map((fv) => {
        return {
          field: fv.value,
          aggregator: fv.operationValue,
        };
      }),
      columnPivotsAggregatorsList: columnPivotsAggregators
        .filter((el) => el.field != "" && el.field != null)
        .map((el) => el.field),
    });
  };

  const handleAxisChange = (value) => {
    console.log(value);
    setSelectedChartMetadata({
      ...selectedChartMetadata,
      axis: value.map((v) => v.value),
    });
  };

  const getAxisValue = () => {
    if (selectedChartMetadata && selectedChartMetadata.axis) {
      return columnsList.filter((c) => selectedChartMetadata.axis.indexOf(c.value) > -1);
    } else {
      return [];
    }
  };

  const handleChange = (value, field, type) => {
    if (value != null) {
      let newSelectedChartMetadata = {
        ...selectedChartMetadata,
      };
      if (type == "file") {
        if (newSelectedChartMetadata[field] == null) {
          createFiles(selectedChart + "image", value.target.files).then((response) => {
            let newSelectedChartMetadata = {
              ...selectedChartMetadata,
            };
            newSelectedChartMetadata[field] = value.target.files[0].name;
            setSelectedChartMetadata(newSelectedChartMetadata);
          });
        }
      } else {
        if (typeof value === "string") {
          newSelectedChartMetadata[field] = value;
        } else if (Array.isArray(value)) {
          newSelectedChartMetadata[field] = value.map((v) => (v.value != null ? v.value : v));
        } else if (typeof value === "object" && Object.keys(value).length > 0) {
          newSelectedChartMetadata[field] = value;
        } else if (typeof value == "boolean") {
          if (newSelectedChartMetadata[field] != null) {
            newSelectedChartMetadata[field] = !newSelectedChartMetadata[field];
          } else {
            newSelectedChartMetadata[field] = value;
          }
        }
        setSelectedChartMetadata(newSelectedChartMetadata);
      }
    }
  };

  const changeTableMode = (mode) => {
    let newSelectedChartMetadata = {
      ...selectedChartMetadata,
    };
    if (mode == "table") {
      setTableMode("table");
      newSelectedChartMetadata.tableMode = "table";
      newSelectedChartMetadata.unlimited = false;
    } else {
      setTableMode("pivot");
      newSelectedChartMetadata.tableMode = "pivot";
      newSelectedChartMetadata.unlimited = true;
    }
    setSelectedChartMetadata(newSelectedChartMetadata);
  };

  const getValue = (type) => {
    if (selectedChartMetadata && selectedChartMetadata[type]) {
      if (type === "title") {
        return selectedChartMetadata.title;
      } else {
        return columnsList.filter((c) => {
          if (c.type === "COLUMN") {
            return selectedChartMetadata[type].indexOf(c.value) > -1;
          } else if (c.type === "VARIABLE") {
            return selectedChartMetadata[type].filter((scm) => scm.variableName === c.value?.variableName).length > 0;
          }
        });
      }
    } else {
      return [];
    }
  };

  const getChartMetadataValue = (type) => {
    if (selectedChartMetadata && selectedChartMetadata[type] != null) {
      return selectedChartMetadata[type];
    } else {
      return null;
    }
  };

  const handleColumnSettingsFieldChange = (value, field, column) => {
    if (typeof value == "object" && value != null) {
      value = value.value;
    }
    let newColumnSettings = selectedChartMetadata?.columnSettings
      ? JSON.parse(JSON.stringify(selectedChartMetadata.columnSettings))
      : [];

    let filteredColumnSettings;
    filteredColumnSettings = newColumnSettings.filter((el) => el.column == column);
    filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;

    if (newColumnSettings.length > 0 && filteredColumnSettings) {
      if (typeof value == "boolean") {
        if (filteredColumnSettings[field] != null) {
          filteredColumnSettings[field] = !filteredColumnSettings[field];
        } else {
          filteredColumnSettings[field] = true;
        }
      } else {
        filteredColumnSettings[field] = value ? value : null;
      }
      let otherColumnSettings = newColumnSettings.filter((el) => el.column != column);
      newColumnSettings = otherColumnSettings.concat([filteredColumnSettings]);
    } else {
      let columnSettingsObj = {};
      columnSettingsObj.column = column;
      if (typeof value == "boolean") {
        columnSettingsObj[field] = true;
      } else {
        columnSettingsObj[field] = value ? value : null;
      }
      newColumnSettings.push(columnSettingsObj);
    }
    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnSettings: newColumnSettings,
    });
  };

  const handleColumnAggregatorFunctionChange = (value) => {
    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnAggregatorFunction: value,
    });
  };

  const handleColumnPivotAggregatorField = (value) => {
    setSelectedChartMetadata({
      ...selectedChartMetadata,
      pivotAggregatorField: value,
    });
  };

  const getColumnSettingsValue = (field, column) => {
    if (
      selectedChartMetadata &&
      selectedChartMetadata.columnSettings &&
      selectedChartMetadata.columnSettings.length > 0
    ) {
      let filteredColumnSettings = selectedChartMetadata.columnSettings.filter((el) => el.column == column);
      filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
      if (filteredColumnSettings) {
        if (field == "sort" && filteredColumnSettings.sort) {
          return sortOperations.filter((f) => f.value === filteredColumnSettings.sort);
        } else if (field == "suffix" && filteredColumnSettings.suffix) {
          return suffixes.filter((f) => f.value === filteredColumnSettings.suffix);
        } else if (field == "cellFontSize" && filteredColumnSettings.cellFontSize) {
          return filteredColumnSettings[field].split("px")[0];
        } else if (filteredColumnSettings[field] != null) {
          return filteredColumnSettings[field];
        }
        return "";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const isNewValueMeasure = (value) => {
    return (
      value &&
      typeof value === "object" &&
      typeof value.field === "object" &&
      value.field.name.indexOf("##") === 0 &&
      typeof value.field.columnA === "object" &&
      typeof value.field.columnB === "object"
    );
  };

  const handleValueFieldChange = (i, value) => {
    let newValues = selectedChartMetadata?.values?.length ? [...selectedChartMetadata.values] : [];

    // Value validation
    //if (newValues.length === 0) {
    //  //First value being added
    //  if (isNewValueMeasure(value)) {
    //    console.log("Measures cannot be the used in cards without the axis being selected");
    //    return;
    //  }
    //}

    if (i === null) {
      newValues.push(value);
    } else {
      if (value.aggregator == "" && value.field == "") {
        newValues.splice(i, 1);
      } else {
        newValues.splice(i, 1, value);
      }
    }

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      values: newValues,
      axis: selectedChartMetadata && selectedChartMetadata.axis ? selectedChartMetadata.axis : [],
    });
  };

  const handleTooltipFieldChange = (i, value) => {
    let newTooltips = selectedChartMetadata?.tooltips?.length ? [...selectedChartMetadata.tooltips] : [];
    if (i === null) {
      newTooltips.push(value);
    } else {
      newTooltips.splice(i, 1, value);
    }

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      tooltips: newTooltips,
    });
  };

  const handleColumnAggregatorFieldChange = (i, value) => {
    let newColumnAggregators = selectedChartMetadata?.columnAggregators?.length
      ? [...selectedChartMetadata.columnAggregators]
      : [];
    if (i === null) {
      newColumnAggregators.push(value);
    } else {
      newColumnAggregators.splice(i, 1, value);
    }

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnAggregators: newColumnAggregators,
      columnAggregatorsList: newColumnAggregators
        .filter((el) => el.field != "" && el.field != null)
        .map((el) => el.field),
    });
  };

  const handleColumnPivotFieldChange = (i, value) => {
    let newColumnPivotsAggregators = selectedChartMetadata?.columnPivotsAggregators?.length
      ? [...selectedChartMetadata.columnPivotsAggregators]
      : [];
    if (i === null) {
      newColumnPivotsAggregators.push(value);
    } else {
      newColumnPivotsAggregators.splice(i, 1, value);
    }

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnPivotsAggregators: newColumnPivotsAggregators,
      columnPivotsAggregatorsList: newColumnPivotsAggregators
        .filter((el) => el.field != "" && el.field != null)
        .map((el) => el.field),
    });
  };

  const getAggregatorValue = () => {
    if (selectedChartMetadata?.columnAggregatorFunction) {
      return fieldOperations.filter((f) => f.value === selectedChartMetadata.columnAggregatorFunction);
    } else {
      return [];
    }
  };

  const getPivotAggregatorField = () => {
    if (selectedChartMetadata?.pivotAggregatorField) {
      return columnsList.filter(
        (o) =>
          o.value === selectedChartMetadata.pivotAggregatorField ||
          o.label === selectedChartMetadata.pivotAggregatorField
      );
    } else {
      return [];
    }
  };

  const getDisabledState = (type) => {
    if (selectedChartMetadata === null) {
      return true;
    }

    if (selectedChartMetadata && selectedChartMetadata[type] === null) {
      return true;
    }

    return !dealWithIt(type);
  };

  const dealWithIt = (type) => {
    let dealWithIt = true;

    switch (type) {
      case "title":
        dealWithIt = dealWithTitle();
        break;
      case "card":
        dealWithIt = dealWithCard();
        break;
      case "axis":
        dealWithIt = dealWithAxis();
        break;
      case "labels":
        dealWithIt = dealWithLabels();
        break;
      case "image":
        dealWithIt = dealWithImages();
        break;
      case "values":
        dealWithIt = dealWithValues();
        break;
      case "tooltips":
        dealWithIt = dealWithTooltips();
        break;
      case "filters":
        dealWithIt = dealWithFilters();
        break;
      case "rowColumnAggregators":
        dealWithIt = dealWithRowColumnAggregators();
        break;
      case "lineChartFill":
        dealWithIt = dealWithLineChartFill();
        break;
      case "showLegend":
        dealWithIt = dealWithShowLegend();
        break;
      case "friendlyNumber":
        dealWithIt = dealWithFriendlyNumber();
        break;
      case "chartOverflow":
        dealWithIt = dealWithChartOverflow();
        break;
      case "allExceptFilters":
        dealWithIt = dealWithAllExceptFilters();
        break;
      case "datalabelPositioning":
        dealWithIt = dealWithChartDatalabelPositioning();
        break;
      case "chartDataOrder":
        dealWithIt = dealWithChartDataOrder();
        break;
      case "dropdownExclusive":
        dealWithIt = dealWithDropdownExclusive();
        break;
      case "map":
        dealWithIt = dealWithMapExclusive();
        break;
      case "optimization":
        dealWithIt = dealWithOptimization();
        break;
    }

    return dealWithIt;
  };

  const dealWithLabels = () => {
    /*  return false; */

    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveLabelFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithImages = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveImagesFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithAxis = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveAxisFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithValues = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveValueFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithTooltips = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveTooltipFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithTitle = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveTitleFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithCard = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeHasCardField.indexOf(chart.kpiType) !== -1;
  };

  const dealWithFilters = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveFiltersFields.indexOf(chart.kpiType) === -1;
  };

  const dealWithRowColumnAggregators = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && chart.kpiType == "table";
  };

  const dealWithChartOverflow = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveChartOverflow.indexOf(chart.kpiType) === -1;
  };

  const dealWithAllExceptFilters = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeHasAllExceptFilters.indexOf(chart.kpiType) !== -1;
  };

  const dealWithLineChartFill = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && chart.kpiType == "line";
  };

  const dealWithShowLegend = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeHasShowLegend.indexOf(chart.kpiType) !== -1;
  };

  const dealWithFriendlyNumber = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeHasFriendlyNumber.indexOf(chart.kpiType) !== -1;
  };

  const dealWithChartDatalabelPositioning = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeHasDatalabelPositioning.indexOf(chart.kpiType) !== -1;
  };

  const dealWithChartDataOrder = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeHaveChartDataOrder.indexOf(chart.kpiType) !== -1;
  };

  const dealWithDropdownExclusive = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeIsDropdown.indexOf(chart.kpiType) !== -1;
  };

  const dealWithMapExclusive = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeIsMap.indexOf(chart.kpiType) !== -1;
  };

  const dealWithOptimization = () => {
    let chart = layout.filter((l) => l.i === selectedChart)[0];

    return chart && typeDoesntHaveOptimizationFields.indexOf(chart.kpiType) === -1;
  };

  const handleFiltersChange = (filters) => {
    setSelectedChartMetadata({
      ...selectedChartMetadata,
      filters: filters,
    });
  };

  const handleVariableFiltersChange = (filters) => {
    setSelectedChartMetadata({
      ...selectedChartMetadata,
      variableFilters: filters,
    });
  };

  const clearColumnSettings = (column) => {
    const filteredColumnSettings = selectedChartMetadata.columnSettings.filter((el) => el.column != column);

    setSelectedChartMetadata({
      ...selectedChartMetadata,
      columnSettings: filteredColumnSettings,
    });
  };

  const fieldOperations = [
    { value: "sum", label: "Sum" },
    { value: "avg", label: "Average" },
    { value: "count", label: "Count" },
    { value: "count_dist", label: "Count (Distinct)" },
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
  ];

  const sortOperations = [
    { value: "asc", label: "ASC" },
    { value: "desc", label: "DESC" },
  ];

  const dataTypes = [
    { value: "text", label: "Text" },
    { value: "number", label: "Number" },
    { value: "percentage", label: "Percentage" },
    /* { value: "currency", label: "Currency" }, */
  ];

  const chartAlternativePalettes = [
    {
      label: "Alternative 1",
      value: ["#FF6384", "#36A2EB", "#4BC0C0", "#FF9F40", "#9966FF", "#FFCD56", "#C9CBCF", "0.6"],
    },
    {
      label: "Alternative 2",
      value: ["#88CCF1", "#FF5A5F", "#9A48D0", "#F9DC5C", "#011936", "#823038", "#177E89", "0.6"],
    },
  ];

  const chartPrimaryColors = [
    { value: "blue", label: "Blue", color: "rgba(54, 162, 235, 0.6)" },
    { value: "red", label: "Red", color: "rgba(255, 99, 132, 0.6)" },
    { value: "green", label: "Green", color: "rgba(75, 192, 192, 0.6)" },
    { value: "orange", label: "Orange", color: "rgba(255, 160, 64, 0.6)" },
    { value: "purple", label: "Purple", color: "rgba(153, 102, 255, 0.6)" },
    { value: "yellow", label: "Yellow", color: "rgba(255, 205, 86, 0.6)" },
    { value: "gray", label: "Gray", color: "rgba(201, 203, 207, 0.6)" },
  ];

  const chartSeriesColorModes = [
    { value: "single", label: "Single" },
    { value: "multiple", label: "Multiple" },
  ];

  const datalabelPositionOptions = [
    { value: "start", label: "Start" },
    { value: "center", label: "Center" },
    { value: "end", label: "End" },
  ];

  const suffixes = [
    { value: "%", label: "%" },
    { value: "€", label: "€" },
    { value: "$", label: "$" },
  ];

  const chartDataOrders = [
    { value: "abc", label: "Alphabetical axis" },
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];

  const dropdownPreselectedModes = [
    { value: "firstAlphabetical", label: "First alphabetical value" },
    { value: "highestValue", label: "Highest numeric value" },
  ];

  const maps = [
    { value: "cimbal", label: "CIMBAL" },
    { value: "vfx", label: "VFX" },
  ];

  const deleteFilesFromField = (field, container, fileName) => {
    deleteFiles(container, fileName).then((response) => {
      let newSelectedChartMetadata = {
        ...selectedChartMetadata,
      };
      newSelectedChartMetadata[field] = null;
      setSelectedChartMetadata(newSelectedChartMetadata);
    });
  };

  return (
    <VisualsSettingsWrapper>
      <KSTKNav pills>
        <NavItem>
          <KSTKDarkNavLink
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              setActiveTab("1");
            }}
          >
            Data
          </KSTKDarkNavLink>
        </NavItem>
        <NavItem>
          <KSTKDarkNavLink
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              setActiveTab("2");
            }}
          >
            Format
          </KSTKDarkNavLink>
        </NavItem>
      </KSTKNav>
      <VisualSettingsTabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {selectedChart == null ? (
            <KSTKEmptyContent message="Create or select a KPI" icon="chart-simple" small={true} />
          ) : null}
          {dealWithIt("title") ? (
            <React.Fragment>
              <label htmlFor="title">Title/Text</label>
              <FieldWrapper>
                <Input
                  placeholder="Title"
                  onChange={(e) => handleChange(e.target.value, "title")}
                  value={getValue("title")}
                  disabled={getDisabledState("title")}
                  type="textarea"
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("axis") ? (
            <React.Fragment>
              <label htmlFor="axis">Axis</label>
              <FieldWrapper>
                <KSTKSelect
                  id="axis"
                  placeholder="Axis Columns"
                  isMulti={true}
                  isSearchable={true}
                  isClearable={true}
                  options={columnsList}
                  onChange={(value) => handleChange(value, "axis")}
                  value={getValue("axis")}
                  isDisabled={getDisabledState("axis")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("values") ? (
            <React.Fragment>
              <label htmlFor="values">Values</label>
              <FieldWrapper>
                <div>
                  {fieldValues.map((fv, i) => (
                    <ValuesWrapper key={"" + i}>
                      <KSTKValueFieldSidePanel
                        options={columnsList}
                        value={fv.value}
                        operationValue={fv.operationValue}
                        seriesName={fv.seriesName}
                        dataTypeValue={fv.dataTypeValue}
                        decimalPlaces={fv.decimalPlaces}
                        onDelete={() => removeValueField(i)}
                        onChange={(value) => handleValueFieldChange(i, value)}
                        isDisabled={getDisabledState("values")}
                      />
                      <ValuesOperations>
                        <i className="fa-solid fa-arrow-up" onClick={() => orderValueField(i, "up")} />
                        <i className="fa-solid fa-arrow-down" onClick={() => orderValueField(i, "down")} />
                        <i className="far fa-times-circle" onClick={() => removeValueField(i)} />
                      </ValuesOperations>
                    </ValuesWrapper>
                  ))}
                  <KSTKValueFieldSidePanel
                    options={columnsList}
                    onChange={(value) => handleValueFieldChange(null, value)}
                    isDisabled={getDisabledState("values")}
                    seriesNameField={false}
                  />
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("labels") ? (
            <React.Fragment>
              <label htmlFor="labels">Labels</label>
              <FieldWrapper>
                <KSTKSelect
                  id="labels"
                  isMulti={true}
                  isSearchable={true}
                  isClearable={true}
                  options={columnsList}
                  onChange={(value) => handleChange(value, "labels")}
                  value={getValue("labels")}
                  isDisabled={getDisabledState("labels")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") ? (
            <React.Fragment>
              <label htmlFor="unlimitedDataRequest">Unlimited data request</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "unlimited")}
                  disabled={getDisabledState("rowColumnAggregators")}
                  checked={getChartMetadataValue("unlimited")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("tooltips") ? (
            <React.Fragment>
              <label htmlFor="tooltips">Tooltips</label>
              <FieldWrapper>
                <div>
                  {fieldTooltips.map((fv, i) => (
                    <ValuesWrapper key={"" + i}>
                      <KSTKValueFieldSidePanel
                        options={columnsList}
                        value={fv.value}
                        operationValue={fv.operationValue}
                        seriesName={fv.seriesName}
                        onDelete={() => removeTooltipField(i)}
                        onChange={(value) => handleTooltipFieldChange(i, value)}
                        isDisabled={getDisabledState("tooltips")}
                      />
                      <i
                        className="far fa-times-circle"
                        style={{ paddingLeft: "50%" }}
                        onClick={() => removeTooltipField(i)}
                      />
                    </ValuesWrapper>
                  ))}
                  <KSTKValueFieldSidePanel
                    options={columnsList}
                    onChange={(value) => handleTooltipFieldChange(null, value)}
                    isDisabled={getDisabledState("tooltips")}
                    seriesNameField={false}
                  />
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("filters") ? (
            <React.Fragment>
              <label htmlFor="filters">Filters</label>
              <FieldWrapper>
                <KSTKFilterField
                  options={columnsList}
                  onChange={handleFiltersChange}
                  values={selectedChartMetadata?.filters}
                  isDisabled={false}
                  mode="visualsSidePanel"
                ></KSTKFilterField>
              </FieldWrapper>
              <label>Variable Filters</label>
              <FieldWrapper>
                <KSTKFilterField
                  options={columnsList}
                  onChange={handleVariableFiltersChange}
                  values={selectedChartMetadata?.variableFilters}
                  isDisabled={false}
                  mode="visualsSidePanel"
                  variableValue={true}
                ></KSTKFilterField>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("optimization") ? (
            <React.Fragment>
              <label htmlFor="optimization">AI Optimization</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "optimization", "boolean")}
                  checked={selectedChartMetadata?.optimization}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
        </TabPane>
        <TabPane tabId="2">
          {selectedChart == null ? (
            <KSTKEmptyContent message="Create or select a KPI" icon="chart-simple" small={true} />
          ) : null}
          {dealWithIt("title") ? (
            <React.Fragment>
              <label htmlFor="titleFormat">Title</label>
              <FieldWrapper>
                <div>
                  <FieldAreaWrapper>
                    <KSTKFontEditor target={"title"} handleChange={handleChange} getValue={getChartMetadataValue} />
                  </FieldAreaWrapper>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("showLegend") ? (
            <React.Fragment>
              <label htmlFor="chartAlternativePalette">Alternative palettes</label>
              <FieldWrapper>
                <div>
                  <FieldAreaWrapper>
                    <KSTKSelect
                      placeholder="Alternative palette"
                      isMulti={false}
                      isSearchable={true}
                      isClearable={true}
                      options={chartAlternativePalettes}
                      onChange={(value) => handleChange(value ? value.value : "", "chartAlternativePalette")}
                      value={
                        chartAlternativePalettes.filter(
                          (el) =>
                            JSON.stringify(el.value) == JSON.stringify(getChartMetadataValue("chartAlternativePalette"))
                        ).length > 0
                          ? chartAlternativePalettes.filter(
                            (el) =>
                              JSON.stringify(el.value) ==
                              JSON.stringify(getChartMetadataValue("chartAlternativePalette"))
                          )[0]
                          : []
                      }
                    />
                  </FieldAreaWrapper>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("showLegend") &&
            (selectedChartMetadata?.labels == null || selectedChartMetadata?.labels.length == 0) ? (
            <React.Fragment>
              <label htmlFor="chartPrimaryColor">Primary color</label>
              <FieldWrapper>
                <div>
                  <FieldAreaWrapper>
                    <KSTKSelect
                      placeholder="Primary color"
                      isMulti={false}
                      isSearchable={true}
                      isClearable={true}
                      options={chartPrimaryColors}
                      onChange={(value) => handleChange(value ? value.color : "", "chartPrimaryColor")}
                      value={
                        chartPrimaryColors.filter((el) => el.color == getChartMetadataValue("chartPrimaryColor"))
                          .length > 0
                          ? chartPrimaryColors.filter((el) => el.color == getChartMetadataValue("chartPrimaryColor"))[0]
                          : []
                      }
                    />
                  </FieldAreaWrapper>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("showLegend") ? (
            <React.Fragment>
              <label htmlFor="chartSeriesColorMode">Series color mode</label>
              <FieldWrapper>
                <div>
                  <FieldAreaWrapper>
                    <KSTKSelect
                      placeholder="Series color mode"
                      isMulti={false}
                      isSearchable={true}
                      isClearable={true}
                      options={chartSeriesColorModes}
                      onChange={(value) => handleChange(value ? value.value : "", "chartSeriesColorMode")}
                      value={
                        chartSeriesColorModes.filter((el) => el.value == getChartMetadataValue("chartSeriesColorMode"))
                          .length > 0
                          ? chartSeriesColorModes.filter(
                            (el) => el.value == getChartMetadataValue("chartSeriesColorMode")
                          )[0]
                          : []
                      }
                    />
                  </FieldAreaWrapper>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("card") ? (
            <React.Fragment>
              <label htmlFor="cardFormat">Card value</label>
              <FieldWrapper>
                <div>
                  <FieldAreaWrapper>
                    <KSTKFontEditor
                      target={"card"}
                      handleChange={handleChange}
                      getValue={getChartMetadataValue}
                      textAlignField={false}
                    />
                    <FieldWrapper>
                      <KSTKSelect
                        placeholder="Suffix"
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        options={suffixes}
                        onChange={(value) => handleChange(value ? value.value : "", "datalabelSuffix")}
                        value={
                          suffixes.filter((el) => el.value == getChartMetadataValue("datalabelSuffix")).length > 0
                            ? suffixes.filter((el) => el.value == getChartMetadataValue("datalabelSuffix"))[0]
                            : []
                        }
                      />
                    </FieldWrapper>
                  </FieldAreaWrapper>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("lineChartFill") ? (
            <React.Fragment>
              <label htmlFor="lineChartFill">Ribbon mode</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "lineChartFill")}
                  disabled={getDisabledState("lineChartFill")}
                  checked={
                    getChartMetadataValue("lineChartFill") == null ? false : getChartMetadataValue("lineChartFill")
                  }
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("showLegend") ? (
            <React.Fragment>
              <label htmlFor="showLegend">Show legend</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "showLegend")}
                  disabled={getDisabledState("showLegend")}
                  checked={getChartMetadataValue("showLegend") == null ? true : getChartMetadataValue("showLegend")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("friendlyNumber") ? (
            <React.Fragment>
              <label htmlFor="friendlyNumber">Abbreviated numbers</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "friendlyNumber")}
                  disabled={getDisabledState("friendlyNumber")}
                  checked={
                    getChartMetadataValue("friendlyNumber") == null ? false : getChartMetadataValue("friendlyNumber")
                  }
                />
              </FieldWrapper>
              <label htmlFor="friendlyNumberDecimalPlaces">Abbreviated numbers decimal places</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={1}
                  min={0}
                  max={5}
                  onChange={(e) => handleChange(e.target.value, "friendlyNumberDecimalPlaces")}
                  value={getChartMetadataValue("friendlyNumberDecimalPlaces")}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("datalabelPositioning") || dealWithIt("showLegend") ? (
            <React.Fragment>
              <label htmlFor="datalabelPositioning">Datalabels</label>
              <FieldAreaWrapper>
                {dealWithIt("datalabelPositioning") ? (
                  <React.Fragment>
                    {" "}
                    <FieldWrapper>
                      <KSTKSelect
                        placeholder="Anchor"
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        options={datalabelPositionOptions}
                        onChange={(value) => handleChange(value ? value.value : "", "datalabelAnchor")}
                        value={
                          datalabelPositionOptions.filter((el) => el.value == getChartMetadataValue("datalabelAnchor"))
                            .length > 0
                            ? datalabelPositionOptions.filter(
                              (el) => el.value == getChartMetadataValue("datalabelAnchor")
                            )[0]
                            : []
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <KSTKSelect
                        placeholder="Align"
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        options={datalabelPositionOptions}
                        onChange={(value) => handleChange(value ? value.value : "", "datalabelAlign")}
                        value={
                          datalabelPositionOptions.filter((el) => el.value == getChartMetadataValue("datalabelAlign"))
                            .length > 0
                            ? datalabelPositionOptions.filter(
                              (el) => el.value == getChartMetadataValue("datalabelAlign")
                            )[0]
                            : []
                        }
                      />
                    </FieldWrapper>{" "}
                  </React.Fragment>
                ) : null}
                {dealWithIt("showLegend") ? (
                  <React.Fragment>
                    <FieldWrapper>
                      <Input
                        placeholder="Font size"
                        type="number"
                        step={1}
                        min={8}
                        max={20}
                        onChange={(e) => handleChange(e.target.value, "dataLabelFontSize")}
                        value={
                          dataLabelFontSize == null || dataLabelFontSize == ""
                            ? getChartMetadataValue("dataLabelFontSize") == null
                              ? ""
                              : getChartMetadataValue("dataLabelFontSize")
                            : dataLabelFontSize
                        }
                      ></Input>
                    </FieldWrapper>
                    <FieldWrapper>
                      <KSTKSelect
                        placeholder="Suffix"
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        options={suffixes}
                        onChange={(value) => handleChange(value ? value.value : "", "datalabelSuffix")}
                        value={
                          suffixes.filter((el) => el.value == getChartMetadataValue("datalabelSuffix")).length > 0
                            ? suffixes.filter((el) => el.value == getChartMetadataValue("datalabelSuffix"))[0]
                            : []
                        }
                      />
                    </FieldWrapper>
                  </React.Fragment>
                ) : null}
              </FieldAreaWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("chartDataOrder") ? (
            <React.Fragment>
              <label htmlFor="chartDataOrder">Order</label>
              <FieldWrapper>
                <FieldAreaWrapper>
                  <KSTKSelect
                    placeholder="Order"
                    isMulti={false}
                    isSearchable={true}
                    isClearable={true}
                    options={chartDataOrders}
                    onChange={(value) => handleChange(value ? value.value : "", "chartDataOrder")}
                    value={
                      chartDataOrders.filter((el) => el.value == getChartMetadataValue("chartDataOrder")).length > 0
                        ? chartDataOrders.filter((el) => el.value == getChartMetadataValue("chartDataOrder"))[0]
                        : []
                    }
                  />
                </FieldAreaWrapper>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("dropdownExclusive") ? (
            <React.Fragment>
              <label htmlFor="dropdownPreselectedMode">Preselected mode</label>
              <FieldWrapper>
                <FieldAreaWrapper>
                  <KSTKSelect
                    placeholder="Mode"
                    isMulti={false}
                    isSearchable={true}
                    isClearable={true}
                    options={dropdownPreselectedModes}
                    onChange={(value) => handleChange(value ? value.value : "", "preselectedMode")}
                    value={
                      dropdownPreselectedModes.filter((el) => el.value == getChartMetadataValue("preselectedMode"))[0]
                    }
                  />
                </FieldAreaWrapper>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("chartOverflow") ? (
            <React.Fragment>
              <label htmlFor="chartOverflow">Overflow</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={150}
                  min={150}
                  max={5550}
                  onChange={(e) => handleChange(e.target.value, "chartOverflow")}
                  value={getChartMetadataValue("chartOverflow")}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("chartOverflow") ? (
            <React.Fragment>
              <label htmlFor="chartOverflowWithFilters">Overflow with filters</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={150}
                  min={150}
                  max={5550}
                  onChange={(e) => handleChange(e.target.value, "chartOverflowWithFilters")}
                  value={getChartMetadataValue("chartOverflowWithFilters")}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("chartOverflow") ? (
            <React.Fragment>
              <label htmlFor="forceChartOverflow">Force overflow</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "forceChartOverflow")}
                  disabled={getDisabledState("chartOverflow")}
                  checked={
                    getChartMetadataValue("forceChartOverflow") == null
                      ? false
                      : getChartMetadataValue("forceChartOverflow")
                  }
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("allExceptFilters") ? (
            <React.Fragment>
              <label htmlFor="allExceptFilters">All except filters</label>
              <FieldWrapper>
                <KSTKMultipleColumnFunctionFieldSidePanel
                  options={layout
                    .filter(
                      (l) =>
                        l.kpiType === "dropdown" &&
                        l.metadata !== undefined &&
                        l.metadata.title !== null &&
                        l.metadata.axis !== null &&
                        l.metadata.axis?.length > 0
                    )
                    .map((dropdown) => {
                      return {
                        value: dropdown.i,
                        label: dropdown.metadata.title + " (" + dropdown.metadata.axis[0] + ")",
                        type: "COLUMN",
                      };
                    })
                    .sort((a, b) => {
                      return a.label > b.label ? 1 : -1;
                    })}
                  onlySelectMode={true}
                  value={allExceptFilters.value}
                  onChange={(value) => handleChange(value, "allExceptFilters")}
                  isDisabled={getDisabledState("allExceptFilters")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("image") ? (
            <React.Fragment>
              <label htmlFor="imageUrl">Image url</label>
              <FieldWrapper>
                <div>
                  <Input
                    type="string"
                    onChange={(e) => handleChange(e.target.value, "imageUrl")}
                    value={imageUrl}
                  ></Input>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("image") ? (
            <React.Fragment>
              <label htmlFor="imageFile">Image file</label>
              <FieldWrapper>
                <div>
                  <Input type="file" onChange={(e) => handleChange(e, "imageFile", "file")}></Input>
                  {imageFile != null ? (
                    <div>
                      <span>{imageFile}</span>
                      <i
                        className="far fa-times-circle"
                        style={{ paddingLeft: "16px" }}
                        onClick={() => deleteFilesFromField("imageFile", selectedChart + "image", imageFile)}
                      />
                    </div>
                  ) : null}
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") ? (
            <React.Fragment>
              <label htmlFor="tableHeaders">Table headers</label>
              <FieldWrapper>
                <FieldAreaWrapper>
                  <KSTKFontEditor
                    target={"header"}
                    handleChange={handleChange}
                    getValue={getChartMetadataValue}
                    textAlignField={false}
                  />
                </FieldAreaWrapper>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") ? (
            <React.Fragment>
              <label htmlFor="tableCells">Table cells</label>
              <FieldWrapper>
                <FieldAreaWrapper>
                  <KSTKFontEditor target={"cell"} handleChange={handleChange} getValue={getChartMetadataValue} />
                </FieldAreaWrapper>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null ? (
            <React.Fragment>
              <label htmlFor="columnSettings">Table column settings</label>
              <FieldWrapper>
                <div>
                  <UncontrolledAccordion defaultOpen="20">
                    {selectedChartMetadata?.values.map((fv, i) => (
                      <AccordionItem key={"" + i}>
                        <AccordionHeader targetId={"" + i}>
                          {typeof fv.field == "string" ? JSON.stringify(fv.field) : fv.field.name}
                        </AccordionHeader>
                        <AccordionBody accordionId={"" + i}>
                          <label htmlFor="sort">Sort</label>
                          <FieldWrapper>
                            <KSTKSelect
                              id="sort"
                              isMulti={false}
                              isSearchable={false}
                              isClearable={true}
                              options={sortOperations}
                              onChange={(value) =>
                                handleColumnSettingsFieldChange(
                                  value,
                                  "sort",
                                  typeof fv.field == "string" ? fv.field : fv.field.name
                                )
                              }
                              value={getColumnSettingsValue(
                                "sort",
                                typeof fv.field == "string" ? fv.field : fv.field.name
                              )}
                            />
                          </FieldWrapper>
                          <label htmlFor="columnWidth">Width</label>
                          <FieldWrapper>
                            <Input
                              type="number"
                              step={30}
                              min={10}
                              max={500}
                              onChange={(value) =>
                                handleColumnSettingsFieldChange(
                                  Number(value.target.value),
                                  "width",
                                  typeof fv.field == "string" ? fv.field : fv.field.name
                                )
                              }
                              value={getColumnSettingsValue(
                                "width",
                                typeof fv.field == "string" ? fv.field : fv.field.name
                              )}
                            ></Input>
                          </FieldWrapper>
                          <label htmlFor="suffix">Suffix</label>
                          <FieldWrapper>
                            <KSTKSelect
                              placeholder="Suffix"
                              isMulti={false}
                              isSearchable={true}
                              isClearable={true}
                              options={suffixes}
                              onChange={(value) =>
                                handleColumnSettingsFieldChange(
                                  value,
                                  "suffix",
                                  typeof fv.field == "string" ? fv.field : fv.field.name
                                )
                              }
                              value={getColumnSettingsValue(
                                "suffix",
                                typeof fv.field == "string" ? fv.field : fv.field.name
                              )}
                            />
                          </FieldWrapper>
                          {/*  <label htmlFor="dataType">Data type</label>
                        <FieldWrapper>
                          <KSTKSelect
                            id="dataType"
                            isMulti={false}
                            isSearchable={false}
                            isClearable={true}
                            options={dataTypes}
                            onChange={(value) => handleColumnSettingsFieldChange(value, "dataType", typeof fv.field == "string" ? fv.field : fv.field.name)}
                            value={getColumnSettingsValue("dataType", typeof fv.field == "string" ? fv.field : fv.field.name)}
                          />
                        </FieldWrapper> */}
                          <label htmlFor="cellText">Cell elements</label>
                          <FieldAreaWrapper>
                            <KSTKFontEditor
                              target={"cell"}
                              field={typeof fv.field == "string" ? fv.field : fv.field.name}
                              handleChange={handleColumnSettingsFieldChange}
                              getValue={getColumnSettingsValue}
                            />
                          </FieldAreaWrapper>
                          <label htmlFor="columnWidth">Minimum selected dropdown options</label>
                          <FieldWrapper>
                            <Input
                              type="number"
                              step={1}
                              min={1}
                              max={5}
                              onChange={(value) =>
                                handleColumnSettingsFieldChange(
                                  Number(value.target.value),
                                  "dropdownMinimumSelectedOptions",
                                  typeof fv.field == "string" ? fv.field : fv.field.name
                                )
                              }
                              value={getColumnSettingsValue(
                                "dropdownMinimumSelectedOptions",
                                typeof fv.field == "string" ? fv.field : fv.field.name
                              )}
                            ></Input>
                          </FieldWrapper>
                          <FieldWrapper>
                            <Button
                              color="secondary"
                              onClick={() =>
                                clearColumnSettings(typeof fv.field == "string" ? fv.field : fv.field.name)
                              }
                            >
                              Clear column settings
                            </Button>
                          </FieldWrapper>
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </UncontrolledAccordion>
                </div>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") ? (
            <FieldWrapper>
              <KSTKNav pills>
                <NavItem>
                  <KSTKDarkNavLink
                    className={classnames({
                      active: tableMode === "table",
                    })}
                    onClick={() => {
                      changeTableMode("table");
                    }}
                  >
                    Table
                  </KSTKDarkNavLink>
                </NavItem>
                <NavItem>
                  <KSTKDarkNavLink
                    className={classnames({
                      active: tableMode === "pivot",
                    })}
                    onClick={() => {
                      changeTableMode("pivot");
                    }}
                  >
                    Pivot
                  </KSTKDarkNavLink>
                </NavItem>
              </KSTKNav>
            </FieldWrapper>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "table" ? (
            <React.Fragment>
              <label htmlFor="tableRowAggregators">Table row aggregators</label>
              <FieldWrapper>
                <KSTKMultipleColumnFunctionFieldSidePanel
                  options={selectedChartMetadata?.values?.map((el) => {
                    return {
                      value: typeof el.field == "string" ? el.field : el.field.name,
                      label: typeof el.field == "string" ? el.field : el.field.name,
                      type: "COLUMN",
                    };
                  })}
                  value={rowAggregators.value}
                  operationValue={rowAggregators.operationValue}
                  onChange={(value) => handleChange(value, "rowAggregators")}
                  isDisabled={getDisabledState("rowColumnAggregators")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            columnAggregatorsList &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "table" ? (
            <React.Fragment>
              <label htmlFor="tableColumnAggregators">Table column aggregators</label>
              <div>
                {columnAggregators.map((fv, i) => (
                  <ValuesWrapper key={"" + i}>
                    <KSTKColumnAggregatorFieldSidePanel
                      options={selectedChartMetadata?.values
                        .filter(
                          (el) =>
                            columnAggregatorsList?.indexOf(typeof el.field == "string" ? el.field : el.field.name) == -1
                        )
                        .map((el) => {
                          return {
                            value: typeof el.field == "string" ? el.field : el.field.name,
                            label: typeof el.field == "string" ? el.field : el.field.name,
                            type: "COLUMN",
                          };
                        })}
                      fields={selectedChartMetadata?.values.map((el) => {
                        return {
                          value: typeof el.field == "string" ? el.field : el.field.name,
                          label: typeof el.field == "string" ? el.field : el.field.name,
                          type: "COLUMN",
                        };
                      })}
                      value={fv.value}
                      operationValue={fv.operationValue}
                      onDelete={() => removeColumnAggregatorField(i)}
                      onChange={(value) => handleColumnAggregatorFieldChange(i, value)}
                      isDisabled={getDisabledState("rowColumnAggregators")}
                    />
                    <i
                      className="far fa-times-circle"
                      style={{ paddingLeft: "50%" }}
                      onClick={() => removeColumnAggregatorField(i)}
                    />
                  </ValuesWrapper>
                ))}
                <FieldWrapper>
                  <KSTKColumnAggregatorFieldSidePanel
                    options={selectedChartMetadata?.values
                      .filter(
                        (el) =>
                          columnAggregatorsList?.indexOf(typeof el.field == "string" ? el.field : el.field.name) == -1
                      )
                      .map((el) => {
                        return {
                          value: typeof el.field == "string" ? el.field : el.field.name,
                          label: typeof el.field == "string" ? el.field : el.field.name,
                          type: "COLUMN",
                        };
                      })}
                    fields={selectedChartMetadata?.values.map((el) => {
                      return {
                        value: typeof el.field == "string" ? el.field : el.field.name,
                        label: typeof el.field == "string" ? el.field : el.field.name,
                        type: "COLUMN",
                      };
                    })}
                    onChange={(value) => handleColumnAggregatorFieldChange(null, value)}
                    isDisabled={getDisabledState("rowColumnAggregators")}
                  />
                </FieldWrapper>
              </div>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") && tableMode == "table" ? (
            <React.Fragment>
              <label htmlFor="tableColumnAggregatorLabel">Table column aggregator label</label>
              <FieldWrapper>
                <Input
                  onChange={(e) => handleChange(e.target.value, "columnAggregatorLabel")}
                  value={columnAggregatorLabel}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") && tableMode == "table" ? (
            <React.Fragment>
              <label htmlFor="tableClumnAggregatorFunction">Table column aggregator function</label>
              <FieldWrapper>
                <KSTKSelect
                  id="columnAggregatorFunction"
                  placeholder="Column Aggregator Function"
                  isMulti={false}
                  isSearchable={false}
                  isClearable={true}
                  options={fieldOperations}
                  onChange={(value) => handleColumnAggregatorFunctionChange(value ? value.value : null)}
                  value={getAggregatorValue()}
                  isDisabled={getDisabledState("rowColumnAggregators")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") && tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="headerGroupingWidth">Grouping column title</label>
              <FieldWrapper>
                <Input
                  onChange={(e) => handleChange(e.target.value, "headerGroupingTitle")}
                  value={headerGroupingTitle}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") && tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="headerGroupingWidth">Grouping column width</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={20}
                  min={20}
                  max={300}
                  onChange={(e) => handleChange(e.target.value, "headerGroupingWidth")}
                  value={getChartMetadataValue("headerGroupingWidth")}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") && tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="lineTotalsWidth">Line totals width</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={20}
                  min={20}
                  max={300}
                  onChange={(e) => handleChange(e.target.value, "lineTotalsWidth")}
                  value={getChartMetadataValue("lineTotalsWidth")}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="tableGroupingColumns">Table grouping columns</label>
              <FieldWrapper>
                <KSTKMultipleColumnFunctionFieldSidePanel
                  options={selectedChartMetadata?.values?.map((el) => {
                    return {
                      value: typeof el.field == "string" ? el.field : el.field.name,
                      label: typeof el.field == "string" ? el.field : el.field.name,
                      type: "COLUMN",
                    };
                  })}
                  onlySelectMode={true}
                  value={columnGrouping.value}
                  operationValue={columnGrouping.operationValue}
                  onChange={(value) => handleChange(value, "columnGrouping")}
                  isDisabled={getDisabledState("rowColumnAggregators")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="tablePivotColumns">Table pivot columns</label>
              <FieldWrapper>
                <KSTKMultipleColumnFunctionFieldSidePanel
                  options={selectedChartMetadata?.values?.map((el) => {
                    return {
                      value: typeof el.field == "string" ? el.field : el.field.name,
                      label: typeof el.field == "string" ? el.field : el.field.name,
                      type: "COLUMN",
                    };
                  })}
                  onlySelectMode={true}
                  value={columnPivotColumns.value}
                  operationValue={columnPivotColumns.operationValue}
                  onChange={(value) => handleChange(value, "columnPivotColumns")}
                  isDisabled={getDisabledState("rowColumnAggregators")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            columnPivotsAggregatorsList &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="tablePivotColumnsAggregators">Table pivot columns aggregators</label>

              <div>
                {columnPivotsAggregators.map((fv, i) => (
                  <ValuesWrapper key={"" + i}>
                    <KSTKColumnAggregatorFieldSidePanel
                      options={selectedChartMetadata?.values
                        .filter(
                          (el) =>
                            columnPivotsAggregatorsList?.indexOf(
                              typeof el.field == "string" ? el.field : el.field.name
                            ) == -1
                        )
                        .map((el) => {
                          return {
                            value: typeof el.field == "string" ? el.field : el.field.name,
                            label: typeof el.field == "string" ? el.field : el.field.name,
                            type: "COLUMN",
                          };
                        })}
                      fields={selectedChartMetadata?.values.map((el) => {
                        return {
                          value: typeof el.field == "string" ? el.field : el.field.name,
                          label: typeof el.field == "string" ? el.field : el.field.name,
                          type: "COLUMN",
                        };
                      })}
                      value={fv.value}
                      operationValue={fv.operationValue}
                      onDelete={() => removeColumnPivotField(i)}
                      onChange={(value) => handleColumnPivotFieldChange(i, value)}
                      isDisabled={getDisabledState("rowColumnAggregators")}
                    />
                    <i
                      className="far fa-times-circle"
                      style={{ paddingLeft: "50%" }}
                      onClick={() => removeColumnPivotField(i)}
                    />
                  </ValuesWrapper>
                ))}
                <FieldWrapper>
                  <KSTKColumnAggregatorFieldSidePanel
                    options={selectedChartMetadata?.values
                      .filter(
                        (el) =>
                          columnPivotsAggregatorsList?.indexOf(
                            typeof el.field == "string" ? el.field : el.field.name
                          ) == -1
                      )
                      .map((el) => {
                        return {
                          value: typeof el.field == "string" ? el.field : el.field.name,
                          label: typeof el.field == "string" ? el.field : el.field.name,
                          type: "COLUMN",
                        };
                      })}
                    fields={selectedChartMetadata?.values.map((el) => {
                      return {
                        value: typeof el.field == "string" ? el.field : el.field.name,
                        label: typeof el.field == "string" ? el.field : el.field.name,
                        type: "COLUMN",
                      };
                    })}
                    onChange={(value) => handleColumnPivotFieldChange(null, value)}
                    isDisabled={getDisabledState("rowColumnAggregators")}
                  />
                </FieldWrapper>
              </div>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            columnPivotsAggregatorsList &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="hideGroupingColumnsAggregators">Hide grouping columns aggregators</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "hideGroupingColumnsAggregators")}
                  disabled={getDisabledState("chartOverflow")}
                  checked={
                    getChartMetadataValue("hideGroupingColumnsAggregators") == null
                      ? false
                      : getChartMetadataValue("hideGroupingColumnsAggregators")
                  }
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") &&
            columnPivotsAggregatorsList &&
            selectedChartMetadata != null &&
            selectedChartMetadata.values != null &&
            tableMode == "pivot" ? (
            <React.Fragment>
              <label htmlFor="showPivotCellsHeader">Show pivot cells header</label>
              <FieldWrapper>
                <Input
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.checked, "showPivotCellsHeader")}
                  disabled={getDisabledState("chartOverflow")}
                  checked={
                    getChartMetadataValue("showPivotCellsHeader") == null
                      ? false
                      : getChartMetadataValue("showPivotCellsHeader")
                  }
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("map") ? (
            <React.Fragment>
              <label htmlFor="map">Map</label>
              <FieldWrapper>
                <FieldAreaWrapper>
                  <KSTKSelect
                    placeholder="Map"
                    isMulti={false}
                    isSearchable={true}
                    isClearable={true}
                    options={maps}
                    onChange={(value) => handleChange(value ? value.value : "", "map")}
                    value={maps.filter((el) => el.value == getChartMetadataValue("map"))[0]}
                  />
                </FieldAreaWrapper>
              </FieldWrapper>
              <label htmlFor="mapZoom">Zoom</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={0.2}
                  min={7}
                  max={12}
                  onChange={(e) => handleChange(e.target.value, "mapZoom")}
                  value={getChartMetadataValue("mapZoom")}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {dealWithIt("rowColumnAggregators") ? (
            <React.Fragment>
              <label htmlFor="tablePivotAggregatorField">Table pivot aggregator field</label>
              <FieldWrapper>
                <KSTKSelect
                  id="tablePivotAggregatorField"
                  placeholder="Table pivot aggregator field"
                  isMulti={false}
                  isSearchable={true}
                  isClearable={true}
                  options={selectedChartMetadata?.values?.map((el) => {
                    return {
                      value: typeof el.field == "string" ? el.field : el.field.name,
                      label: typeof el.field == "string" ? el.field : el.field.name,
                      type: "COLUMN",
                    };
                  })}
                  onChange={(value) => handleColumnPivotAggregatorField(value ? value.value : null)}
                  value={getPivotAggregatorField()}
                  isDisabled={getDisabledState("rowColumnAggregators")}
                />
              </FieldWrapper>
            </React.Fragment>
          ) : null}
          {/*           {dealWithIt("rowColumnAggregators") ? (
            <React.Fragment>
              <label htmlFor="pivotAggregatorColumnWidth">Pivot aggregator column width</label>
              <FieldWrapper>
                <Input
                  type="number"
                  step={20}
                  min={20}
                  max={300}
                  onChange={(e) => handleChange(e.target.value, "pivotAggregatorColumnWidth")}
                  value={pivotAggregatorColumnWidth}
                ></Input>
              </FieldWrapper>
            </React.Fragment>
          ) : null} */}
        </TabPane>
      </VisualSettingsTabContent>
    </VisualsSettingsWrapper>
  );
}

const VisualsWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1px auto;
  height: 100%;
`;

function PageSettings(props) {
  const { datasource, report, selectedPage, pages, setPages, setSelectedPageMetadata } = useContext(ReportContext);
  const [name, setName] = useState(pages.find((p) => p.id === selectedPage).name);
  const [hiddenPage, setHiddenPage] = useState(pages.find((p) => p.id === selectedPage).hidden);
  const [filters, setFilters] = useState(
    pages.find((p) => p.id === selectedPage).filters.length == 0
      ? null
      : pages.find((p) => p.id === selectedPage).filters
  );

  const PagerOrderButton = styled.div`
    display: flex;
    gap: 8px;
    padding: 5px 0 5px 0;
  `;

  useEffect(() => {
    let newPage = {
      ...pages.find((p) => p.id === selectedPage),
    };

    newPage.name = name;

    setSelectedPageMetadata(selectedPage, newPage);
  }, [name]);

  useEffect(() => {
    setName(pages.find((p) => p.id === selectedPage).name);
    setFilters(
      pages.find((p) => p.id === selectedPage).filters.length == 0
        ? null
        : pages.find((p) => p.id === selectedPage).filters
    );
  }, [selectedPage]);

  const handleFiltersChange = (filters) => {
    let newPage = {
      ...pages.find((p) => p.id === selectedPage),
    };

    newPage.filters = filters;
    setFilters(filters);
    setSelectedPageMetadata(selectedPage, newPage);
  };

  const changePageOrder = (direction) => {
    let selectedPageIndex = pages.findIndex((el) => el.id == selectedPage);
    if (
      (direction == "left" && selectedPageIndex > 0) ||
      (direction == "right" && selectedPageIndex + 1 < pages.length)
    ) {
      let copyOfSelectedPage = Object.assign(
        {},
        pages.find((el) => el.id == selectedPage)
      );
      pages.splice(selectedPageIndex, 1);
      let nextSelectedPageIndex = direction == "left" ? selectedPageIndex - 1 : selectedPageIndex + 1;
      pages.splice(nextSelectedPageIndex, 0, copyOfSelectedPage);
      setPages(pages);
    }
  };

  const changeHiddenPage = (hiddenFlag) => {
    let parsedHiddenFlag = hiddenFlag == true ? hiddenFlag : false;
    let selectedPageIndex = pages.findIndex((el) => el.id == selectedPage);
    let copyOfSelectedPage = Object.assign(
      {},
      pages.find((el) => el.id == selectedPage)
    );
    copyOfSelectedPage.hidden = hiddenFlag == true ? hiddenFlag : false;
    pages.splice(selectedPageIndex, 1, copyOfSelectedPage);
    if (parsedHiddenFlag == true) {
      setHiddenPage(true);
    } else {
      setHiddenPage(false);
    }
    setPages(pages);
  };

  const getHiddenPage = () => {
    let selectedPageIndex = pages.findIndex((el) => el.id == selectedPage);
    let copyOfSelectedPage = Object.assign(
      {},
      pages.find((el) => el.id == selectedPage)
    );
    return copyOfSelectedPage.hidden == true ? copyOfSelectedPage.hidden : false;
  };

  const columnsList = getColumnsFromDatasource(datasource).filter((r) => {
    if (r.type == "COLUMN") {
      return r.value.indexOf("qa_") != 0;
    } else if (r.type == "MEASURE") {
      return true;
    }
  });

  return (
    <SettingsWrapper>
      <label htmlFor="pageName">Name</label>

      <FieldWrapper>
        <Input onChange={(e) => setName(e.target.value)} value={name}></Input>
      </FieldWrapper>
      <label htmlFor="pageOrder">Order</label>
      <PagerOrderButton>
        <Button color="secondary" onClick={() => changePageOrder("left")}>
          {"<"}
        </Button>
        <Button color="secondary" onClick={() => changePageOrder("right")}>
          {">"}
        </Button>
      </PagerOrderButton>
      <label htmlFor="hiddenPage">Hide page</label>
      <FieldWrapper>
        <Input type="checkbox" onChange={(e) => changeHiddenPage(e.target.checked)} checked={getHiddenPage()} />
      </FieldWrapper>
      <label htmlFor="pageFilters">Filters</label>
      <FieldWrapper>
        <KSTKFilterField
          options={columnsList}
          onChange={handleFiltersChange}
          values={filters}
          isDisabled={false}
          mode="visualsSidePanel"
        ></KSTKFilterField>
      </FieldWrapper>
    </SettingsWrapper>
  );
}

function Visuals(props) {
  const [selectedTab, setSelectedTab] = useState("1");
  return (
    <SettingsWrapper>
      <Nav pills>
        <NavItem>
          <KSTKDarkNavLink
            className={classnames({ active: selectedTab === "1" })}
            onClick={() => {
              setSelectedTab("1");
            }}
          >
            Visual
          </KSTKDarkNavLink>
        </NavItem>
        <NavItem>
          <KSTKDarkNavLink
            className={classnames({ active: selectedTab === "2" })}
            onClick={() => {
              setSelectedTab("2");
            }}
          >
            Page
          </KSTKDarkNavLink>
        </NavItem>
      </Nav>
      <SettingsTabContent activeTab={selectedTab}>
        <TabPane tabId="1">
          <VisualsWrapper>
            <VisualsList />
            <KSTKSeparator />
            <VisualSettings />
          </VisualsWrapper>
        </TabPane>
        <TabPane tabId="2">{<PageSettings></PageSettings>}</TabPane>
      </SettingsTabContent>
    </SettingsWrapper>
  );
}

export function KSTKVisualsSidePanel() {
  return <KSTKSidePanel content={<Visuals />} />;
}
