import { BASE_URL, handleErrors } from "./Util";
import { fetchAuthentication } from "./Client";
import { utilFunctions } from "./module.utils.service";

export const getModulesMetadata = () => {
  return fetch(`${BASE_URL}/Modules/?filter[where][reportBuilderReady]=true`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
  }).then(handleErrors);
};

function filterData(data, filters) {
  return data.filter((el) => {
    let validEl = true;

    for (let f = 0; f < filters.length; f++) {
      let filter = filters[f];
      let parsedEl =
        el[filter.field] == null ||
        el[filter.field] == undefined ||
        (el[filter.field] == "" && isNaN(parseInt(el[filter.field])))
          ? "(vazio)"
          : el[filter.field];
      if (filter.field == "createdBy") {
        if (el.createdBy?.id != filter.value) {
          validEl = false;
          break;
        }
      } else if (filter.operator == "=" || filter.operator == "like" || filter.operator == "eq") {
        if (Array.isArray(filter.value) && filter.value.indexOf(parsedEl) == -1) {
          validEl = false;
          break;
        } else if (!Array.isArray(filter.value) && parsedEl != filter.value) {
          validEl = false;
          break;
        }
      } else if (filter.operator == "<>" || filter.operator == "not like") {
        if (parsedEl == filter.value) {
          validEl = false;
          break;
        }
      } else if (filter.operator == "notBlank") {
        if (parsedEl == null || parsedEl == "" || parsedEl == "(vazio)") {
          validEl = false;
          break;
        }
      } else if (filter.operator == ">") {
        if (!isNaN(Number(parsedEl)) && !isNaN(Number(filter.value))) {
          if (Number(parsedEl) <= Number(filter.value)) {
            validEl = false;
            break;
          }
        }
      } else if (filter.operator == "<") {
        if (!isNaN(Number(parsedEl)) && !isNaN(Number(filter.value))) {
          if (Number(parsedEl) >= Number(filter.value)) {
            validEl = false;
            break;
          }
        }
      } else if (filter.operator == ">=") {
        if (!isNaN(Number(parsedEl)) && !isNaN(Number(filter.value))) {
          if (Number(parsedEl) < Number(filter.value)) {
            validEl = false;
            break;
          }
        }
      } else if (filter.operator == "<=") {
        if (!isNaN(Number(parsedEl)) && !isNaN(Number(filter.value))) {
          if (Number(parsedEl) > Number(filter.value)) {
            validEl = false;
            break;
          }
        }
      }
    }
    return validEl;
  });
}

export const processModuleData = (data, queryParams) => {
  let newData = {};
  let axisUniqueValues = [];

  if (queryParams.select != null) {
    if (queryParams.wheres.length > 0 || queryParams.whereIns.length > 0) {
      data = filterData(data, queryParams.wheres.concat(queryParams.whereIns));
    }
    if (data.length > 0) {
      let axisColumn = typeof queryParams.select[0] == "string" ? queryParams.select[0] : null;
      let labelColumn = typeof queryParams.select[1] == "string" ? queryParams.select[1] : null;
      if (labelColumn != null) {
        labelColumn = labelColumn.split(".")[1];
      }
      let valuesColumns = queryParams.select.filter((el) => typeof el == "object" && el.aggregator != null);

      // Process data if KPI has an axis value
      if (axisColumn != null && axisColumn != "") {
        axisColumn = axisColumn.split(".")[1];
        data.forEach((el) => {
          let axisObj = {};
          let axisKey = "";
          if (el[axisColumn] != "" && el[axisColumn] != null) {
            axisKey = el[axisColumn];
          }
          if (labelColumn != null) {
            if (el[labelColumn] != "" && el[labelColumn] != null) {
              axisObj[labelColumn] = el[labelColumn];
              axisKey = axisKey + "" + el[labelColumn];
            }
          }
          if (axisKey == "") {
            axisKey = "(vazio)";
          }
          if (axisUniqueValues.indexOf(axisKey) == -1) {
            axisUniqueValues.push(axisKey);
            let axisValue = el[axisColumn] == null || el[axisColumn] == "" ? "(vazio)" : el[axisColumn];
            axisObj[axisColumn] = axisValue;
            newData[axisKey] = axisObj;
          }

          // Create an array for each value field for all the collected values
          valuesColumns.forEach((value) => {
            let valueField = value.field.split(".")[1];
            if (newData[axisKey][valueField + "values"] == null) {
              newData[axisKey][valueField + "values"] = [];
            }
            newData[axisKey][valueField + "values"].push(el[valueField]);
            /* if (el[valueField] != "" && el[valueField] != null) {
              newData[axisKey][valueField + "values"].push(el[valueField]);
            } */
          });
        });

        axisUniqueValues.forEach((axisUniqueValue) => {
          valuesColumns.forEach((value) => {
            let valueField = value.field.split(".")[1];
            if (value.aggregator == "count") {
              newData[axisUniqueValue][valueField] = newData[axisUniqueValue][valueField + "values"].length;
            }
            if (value.aggregator == "count_dist") {
              newData[axisUniqueValue][valueField] = [
                ...new Set(newData[axisUniqueValue][valueField + "values"]),
              ].length;
            }
            if (value.aggregator == "sum") {
              if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                let sum = 0;
                newData[axisUniqueValue][valueField + "values"].map((el) => (sum += el));
                newData[axisUniqueValue][valueField] = sum;
              }
            }
            if (value.aggregator == "avg") {
              if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                let sum = 0;
                newData[axisUniqueValue][valueField + "values"].map((el) => (sum += el));
                newData[axisUniqueValue][valueField] =
                  Math.round((sum / newData[axisUniqueValue][valueField + "values"].length) * 100) / 100;
              }
            }
            if (value.aggregator == "max") {
              if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                let max = newData[axisUniqueValue][valueField + "values"][0];
                newData[axisUniqueValue][valueField + "values"].map((el) => (el > max ? (max = el) : null));
                newData[axisUniqueValue][valueField] = max;
              }
            }
            if (value.aggregator == "min") {
              if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                let min = newData[axisUniqueValue][valueField + "values"][0];
                newData[axisUniqueValue][valueField + "values"].map((el) => (el < min ? (min = el) : null));
                newData[axisUniqueValue][valueField] = min;
              }
            }
          });
        });
      }
      // Process data if KPI doesnt have an axis field. Tables and cards for example
      if (axisColumn == null && valuesColumns.length > 0) {
        let axisColumnsFromValues = valuesColumns.filter((el) => el.aggregator == "distinct");
        let axisFieldsFromValues = axisColumnsFromValues.map((el) => el.field.split(".")[1]);
        if (axisColumnsFromValues.length > 0) {
          data.forEach((el) => {
            let axisObj = {};
            let axisFieldsKey = "";
            let validAxisFieldsFlag = true;
            /* axisFieldsFromValues.forEach((field) => {
              if (el[field] != "" && el[field] != null) {
                axisFieldsKey += el[field];
                axisObj[field] = el[field];
              } else if (validAxisFieldsFlag) {
                validAxisFieldsFlag = false;
              }
            }); */
            axisFieldsFromValues.forEach((field) => {
              axisFieldsKey += el[field];
              axisObj[field] = el[field];
            });
            if (validAxisFieldsFlag) {
              if (axisUniqueValues.indexOf(axisFieldsKey) == -1) {
                axisUniqueValues.push(axisFieldsKey);
                newData[axisFieldsKey] = axisObj;
              }

              // Create an array for each value field for all the collected values
              valuesColumns
                .filter((el) => el.aggregator != "distinct")
                .forEach((value) => {
                  let valueField = value.field.split(".")[1];
                  if (newData[axisFieldsKey][valueField + "values"] == null) {
                    newData[axisFieldsKey][valueField + "values"] = [];
                  }
                  /* if (el[valueField] != "" && el[valueField] != null) {
                    newData[axisFieldsKey][valueField + "values"].push(el[valueField]);
                  } */
                  newData[axisFieldsKey][valueField + "values"].push(el[valueField]);
                });

              axisUniqueValues.forEach((axisUniqueValue) => {
                valuesColumns
                  .filter((el) => el.aggregator != "distinct")
                  .forEach((value) => {
                    let valueField = value.field.split(".")[1];
                    if (value.aggregator == "count") {
                      newData[axisUniqueValue][valueField] = newData[axisUniqueValue][valueField + "values"].length;
                    }
                    if (value.aggregator == "count_dist") {
                      newData[axisUniqueValue][valueField] = [
                        ...new Set(newData[axisUniqueValue][valueField + "values"]),
                      ].length;
                    }
                    if (value.aggregator == "sum") {
                      if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                        let sum = 0;
                        newData[axisUniqueValue][valueField + "values"].map((el) => (sum += el));
                        newData[axisUniqueValue][valueField] = sum;
                      }
                    }
                    if (value.aggregator == "avg") {
                      if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                        let sum = 0;
                        newData[axisUniqueValue][valueField + "values"].map((el) => (sum += el));
                        newData[axisUniqueValue][valueField] =
                          Math.round((sum / newData[axisUniqueValue][valueField + "values"].length) * 100) / 100;
                      }
                    }
                    if (value.aggregator == "max") {
                      if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                        let max = newData[axisUniqueValue][valueField + "values"][0];
                        newData[axisUniqueValue][valueField + "values"].map((el) => (el > max ? (max = el) : null));
                        newData[axisUniqueValue][valueField] = max;
                      }
                    }
                    if (value.aggregator == "min") {
                      if (typeof newData[axisUniqueValue][valueField + "values"][0] == "number") {
                        let min = newData[axisUniqueValue][valueField + "values"][0];
                        newData[axisUniqueValue][valueField + "values"].map((el) => (el < min ? (min = el) : null));
                        newData[axisUniqueValue][valueField] = min;
                      }
                    }
                  });
              });
            }
          });
        }
        // Card
        else {
          newData["card"] = {};
          data.forEach((el) => {
            // Create an array for each value field for all the collected values
            valuesColumns.forEach((value) => {
              let valueField = value.field.split(".")[1];
              if (newData["card"][valueField + "values"] == null) {
                newData["card"][valueField + "values"] = [];
              }
              /* if (el[valueField] != "" && el[valueField] != null) {
                newData["card"][valueField + "values"].push(el[valueField]);
              } */
              newData["card"][valueField + "values"].push(el[valueField]);
            });
          });
          valuesColumns.forEach((value) => {
            let valueField = value.field.split(".")[1];
            if (value.aggregator == "count") {
              newData["card"][valueField] = newData["card"][valueField + "values"].length;
            }
            if (value.aggregator == "count_dist") {
              newData["card"][valueField] = [...new Set(newData["card"][valueField + "values"])].length;
            }
            if (value.aggregator == "sum") {
              if (typeof newData["card"][valueField + "values"][0] == "number") {
                let sum = 0;
                newData["card"][valueField + "values"].map((el) => (sum += el));
                newData["card"][valueField] = sum;
              }
            }
            if (value.aggregator == "avg") {
              if (typeof newData["card"][valueField + "values"][0] == "number") {
                let sum = 0;
                newData["card"][valueField + "values"].map((el) => (sum += el));
                newData["card"][valueField] =
                  Math.round((sum / newData["card"][valueField + "values"].length) * 100) / 100;
              }
            }
            if (value.aggregator == "max") {
              if (typeof newData["card"][valueField + "values"][0] == "number") {
                let max = newData["card"][valueField + "values"][0];
                newData["card"][valueField + "values"].map((el) => (el > max ? (max = el) : null));
                newData["card"][valueField] = max;
              }
            }
            if (value.aggregator == "min") {
              if (typeof newData["card"][valueField + "values"][0] == "number") {
                let min = newData["card"][valueField + "values"][0];
                newData["card"][valueField + "values"].map((el) => (el < min ? (min = el) : null));
                newData["card"][valueField] = min;
              }
            }
          });
        }
      }
    }
  }

  return Object.values(newData);
};

let depsDataCache = {};
let modulesDataCache = {};
let autoUpdateTimerFlagCache = true;

export const getModulesData = (moduleId, org, top, tabIndex, user, filterMode, autoUpdateTimer) => {
  if (autoUpdateTimerFlagCache != autoUpdateTimer) {
    modulesDataCache = {};
    autoUpdateTimerFlagCache = autoUpdateTimer;
  }
  if (tabIndex == null) {
    return new Promise((resolve, reject) => {
      resolve([]);
      return false;
    });
  } else if (modulesDataCache[moduleId + org + tabIndex] != null) {
    return new Promise((resolve, reject) => {
      resolve(modulesDataCache[moduleId + org + tabIndex]);
      return true;
    });
  } else {
    modulesDataCache[moduleId + org + tabIndex] = new Promise((resolve, reject) => {
      fetch(`${BASE_URL}/Modules/${moduleId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: fetchAuthentication().value.id,
        },
      }).then((responseModule) => {
        if (responseModule.ok) {
          responseModule.json().then((module) => {
            function getModuleData() {
              // KSTK
              if (org == "5a146532e511ce4db73a09cd") {
                return new Promise((resolve, reject) => {
                  fetch(`${BASE_URL}/${module.collection}`, {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: fetchAuthentication().value.id,
                    },
                  })
                    .then(handleErrors)
                    .then((data) => {
                      if (data == null || data.length == 0) {
                        resolve([]);
                        return false;
                      } else {
                        resolve(data);
                        return true;
                      }
                    });
                });
              } else if (module.customRowFilter && filterMode == "USER") {
                if (
                  (module.value == "class_plan_school_record" || module.value == "class_plan_prof_school_record") &&
                  (user.profile == "admin" || user.profile == "cpAdmin" || user.profile == "cpSupervisor")
                ) {
                  return getDefaultModuleData();
                }
                let primaryMapCustomRowFilters = {};
                let secondaryMapCustomRowFilters = {};
                for (const rowFilter in module.customRowFilter) {
                  if (module.customRowFilter.hasOwnProperty(rowFilter)) {
                    const rowFilterProp = module.customRowFilter[rowFilter];
                    if (
                      rowFilterProp.source == "currentUser" &&
                      user[rowFilter] != null &&
                      rowFilterProp.excludePrimaryRowFilterMap == null
                    ) {
                      if (rowFilterProp.targetCurrentUserField != null) {
                        primaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = {};
                        primaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = user[rowFilter];
                      } else {
                        primaryMapCustomRowFilters[rowFilter] = {};
                        primaryMapCustomRowFilters[rowFilter] = user[rowFilter];
                      }
                    } else if (rowFilterProp.source == "custom" && rowFilterProp.value != null) {
                      primaryMapCustomRowFilters[rowFilter] = {};
                      primaryMapCustomRowFilters[rowFilter] = rowFilterProp.value;
                    }
                  }
                }

                if (
                  module.customRowFilterSecondaryProfile != null &&
                  user.profile == module.customRowFilterSecondaryProfile
                ) {
                  for (const rowFilter in module.customRowFilter) {
                    if (module.customRowFilter.hasOwnProperty(rowFilter)) {
                      const rowFilterProp = module.customRowFilter[rowFilter];
                      if (
                        rowFilterProp.source == "currentUser" &&
                        user[rowFilter] != null &&
                        rowFilterProp.excludeSecondaryRowFilterMap == null
                      ) {
                        if (rowFilterProp.targetCurrentUserField != null) {
                          secondaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = {};
                          secondaryMapCustomRowFilters[rowFilterProp.targetCurrentUserField] = user[rowFilter];
                        } else {
                          secondaryMapCustomRowFilters[rowFilter] = {};
                          secondaryMapCustomRowFilters[rowFilter] = user[rowFilter];
                        }
                      }
                    }
                  }
                }

                let query = parsePropNameValueMap(primaryMapCustomRowFilters, org, null, null, null, null);
                if (query != "") {
                  query = "filter=" + query;
                  return fetch(`${BASE_URL}/${module.collection}?${query}`, {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: fetchAuthentication().value.id,
                    },
                  })
                    .then(handleErrors)
                    .then(function (primaryData) {
                      if (Object.keys(secondaryMapCustomRowFilters).length > 0) {
                        let query2 = parsePropNameValueMap(secondaryMapCustomRowFilters, org, null, null, null, null);
                        if (query2 != "") {
                          query2 = "filter=" + query2;
                          return fetch(`${BASE_URL}/${module.collection}?${query2}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: fetchAuthentication().value.id,
                            },
                          })
                            .then(handleErrors)
                            .then(function (secondaryData) {
                              let primarySecondaryData = primaryData.concat(secondaryData);
                              primarySecondaryData = getUniqueArrObjects(primarySecondaryData, "id");
                              return primarySecondaryData;
                            });
                        } else {
                          return primaryData;
                        }
                      } else {
                        return primaryData;
                      }
                    });
                } else {
                  return getDefaultModuleData();
                }
              } else {
                return getDefaultModuleData();
              }
            }

            function getDefaultModuleData() {
              if (filterMode == "ORG") {
                return fetch(
                  `${BASE_URL}/${module.collection}?filter[where][organization][like]=${org}` +
                    (top != null ? `&filter[limit]=${top}` : ""),
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: fetchAuthentication().value.id,
                    },
                  }
                )
                  .then(handleErrors)
                  .then((data) => {
                    if (data == null || data.length == 0) {
                      return [];
                    } else {
                      return data;
                    }
                  });
              }
              if (filterMode == "USER") {
                return fetch(
                  `${BASE_URL}/${module.collection}?filter[where][createdBy.id][like]=${user.id}` +
                    (top != null ? `&filter[limit]=${top}` : ""),
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: fetchAuthentication().value.id,
                    },
                  }
                )
                  .then(handleErrors)
                  .then((data) => {
                    if (data == null || data.length == 0) {
                      return [];
                    } else {
                      return data;
                    }
                  });
              }
            }

            function getUniqueArrObjects(arr, comp) {
              const unique = arr
                .map((e) => e[comp])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter((e) => arr[e])
                .map((e) => arr[e]);

              return unique;
            }

            function parsePropNameValueMap(
              propNameValueMap,
              org,
              noOrgRequestFlag,
              paginationSize,
              paginationStartIndex,
              remoteMethodFlag
            ) {
              let query = "";
              let lastPos = 0;
              let totalOrConditions = "";
              /* paginationSize = 20;
              paginationStartIndex = 0; */
              for (const key in propNameValueMap) {
                if (propNameValueMap.hasOwnProperty(key)) {
                  let element = propNameValueMap[key];
                  if (Array.isArray(element) && element.length == 0) {
                    continue;
                  }
                  if (Array.isArray(element) && element.length > 1) {
                    let orCondition = "";
                    if (element.length > 0) {
                      if (totalOrConditions == "") {
                        orCondition = "{'or': [";
                      } else {
                        orCondition = ",{'or': [";
                      }

                      for (let i = 0; i < element.length; i++) {
                        const arrEl = element[i];
                        if (typeof arrEl == "string" && remoteMethodFlag == null) {
                          orCondition += "{ '" + key + "': {'like':'" + arrEl + "','options':'i'}}";
                        } else {
                          orCondition += "{ '" + key + "': '" + arrEl + "' }";
                        }
                        if (i < element.length - 1) {
                          orCondition += ",";
                        }
                      }
                      orCondition += "]}";
                      totalOrConditions += orCondition;
                    }
                  } else {
                    if (Array.isArray(element) && element.length == 1) {
                      element = element[0];
                    }
                    if (query == "") {
                      if (typeof element == "string" && remoteMethodFlag == null) {
                        query = "{'where':{ 'and': [{ '" + key + "':{'like':'" + element + "','options':'i'}}]}}";
                      } else {
                        query = "{'where':{ 'and': [{ '" + key + "':" + element + "}]}}";
                      }
                    } else {
                      lastPos = query.length;
                      if (remoteMethodFlag == null) {
                        query = [
                          query.slice(0, lastPos - 3),
                          ",{'" + key + "':{'like':'" + element + "','options':'i'}}",
                          query.slice(lastPos - 3),
                        ].join("");
                      } else {
                        query = [
                          query.slice(0, lastPos - 3),
                          ",{'" + key + "':'" + element + "'",
                          query.slice(lastPos - 3),
                        ].join("");
                      }
                    }
                  }
                }
              }
              if (query == "" && totalOrConditions != "") {
                query = "{'where':{'and': [" + totalOrConditions + "]}}";
              } else if (totalOrConditions != "") {
                lastPos = query.length;
                query = [query.slice(0, lastPos - 3), "," + totalOrConditions + "", query.slice(lastPos - 3)].join("");
              }
              if (query != "") {
                if (noOrgRequestFlag == null) {
                  if (remoteMethodFlag == null) {
                    query = [
                      query.slice(0, query.length - 3),
                      "," + "{'organization':{'like':'" + org + "','options':'i'}}",
                      query.slice(query.length - 3),
                    ].join("");
                  } else {
                    query = [
                      query.slice(0, query.length - 3),
                      "," + "{'organization':'" + org + "'}",
                      query.slice(query.length - 3),
                    ].join("");
                  }
                }
                if (
                  paginationSize != null &&
                  !isNaN(Number(paginationSize)) &&
                  paginationStartIndex != null &&
                  !isNaN(Number(paginationStartIndex))
                ) {
                  query = [
                    query.slice(0, query.length - 1),
                    "," + "'limit':" + paginationSize + "," + "'skip':" + paginationStartIndex,
                    query.slice(query.length - 1),
                  ].join("");
                }
                query = query.replace(/\'/g, '"');
              }

              return query;
            }

            getModuleData().then((response) => {
              let data = response;
              if (data == null || data.length == 0) {
                resolve([]);
                return true;
              }
              let deps = [];
              let depsData = [];
              let activeTab = module.tabs[Object.keys(module.tabs)[tabIndex]];
              let activeTabName = Object.keys(module.tabs)[tabIndex];

              if (depsDataCache[org + JSON.stringify(activeTab)] != null) {
                deps = depsDataCache[org + JSON.stringify(activeTab)].deps;
                depsData = depsDataCache[org + JSON.stringify(activeTab)].depsData;
                runCustomFunctions();
              } else {
                for (var tab in module.tabs) {
                  for (var field in module.tabs[tab].fields) {
                    if (module.tabs[tab].fields[field]["fromTable"] != null) {
                      if (deps.indexOf(module.tabs[tab].fields[field]["fromTable"]) == -1) {
                        deps.push(module.tabs[tab].fields[field]["fromTable"]);
                      }
                    }
                    if (module.tabs[tab].fields[field]["fromTableAux"] != null) {
                      for (let j = 0; j < module.tabs[tab].fields[field]["fromTableAux"].length; j++) {
                        let fromTableCollection = module.tabs[tab].fields[field]["fromTableAux"][j];
                        if (deps.indexOf(fromTableCollection) == -1) {
                          deps.push(fromTableCollection);
                        }
                      }
                    }
                    if (module.tabs[tab].fields[field]["auxFromTable"] != null) {
                      if (deps.indexOf(module.tabs[tab].fields[field]["auxFromTable"]) == -1) {
                        deps.push(module.tabs[tab].fields[field]["auxFromTable"]);
                      }
                    }
                    if (module.tabs[tab].fields[field]["viewType"] == "dynamicField") {
                      for (const key in module.tabs[tab].fields[field]["dynamicFields"]) {
                        if (module.tabs[tab].fields[field]["dynamicFields"].hasOwnProperty(key)) {
                          const element = module.tabs[tab].fields[field]["dynamicFields"][key];
                          if (element.fromTable != null) {
                            if (deps.indexOf(element["fromTable"]) == -1) {
                              deps.push(element["fromTable"]);
                            }
                          }
                          if (element.auxFromTable != null) {
                            if (deps.indexOf(element["auxFromTable"]) == -1) {
                              deps.push(element["auxFromTable"]);
                            }
                          }
                        }
                      }
                    }
                  }
                }

                if (deps.length > 0) {
                  getDependencyData(deps);
                } else {
                  resolve(data);
                  return true;
                }
              }

              function getDependencyData(deps) {
                let dependencyDataPromiseArr = getDependencyDataPromiseArr(deps);
                Promise.all(dependencyDataPromiseArr).then(function (success) {
                  for (let i = 0; i < success.length; i++) {
                    depsData[i] = success[i];
                  }
                  depsDataCache[org + JSON.stringify(activeTab)] = {};
                  depsDataCache[org + JSON.stringify(activeTab)].deps = deps;
                  depsDataCache[org + JSON.stringify(activeTab)].depsData = depsData;
                  runCustomFunctions();
                });
              }

              function runCustomFunctions() {
                let objFunctions = {};
                objFunctions.activeTab = activeTab;
                objFunctions.activeTabName = activeTabName;
                objFunctions.parseValue = parseValue;
                objFunctions.getFromTableData = getFromTableData;
                if (activeTab.customFunctions) {
                  var customFunctions = activeTab.customFunctions;
                  if (customFunctions != null) {
                    for (let i = 0; i < customFunctions.length; i++) {
                      const element = customFunctions[i];
                      if (utilFunctions[element] !== null && utilFunctions[element] !== undefined) {
                        utilFunctions[element](objFunctions, data);
                      }
                    }
                  }
                }
                parseData(activeTab, data);
              }

              function getDependencyDataPromiseArr(deps) {
                let promiseArr = [];

                // KSTK
                if (org == "5a146532e511ce4db73a09cd") {
                  deps.forEach((dep) => {
                    promiseArr.push(
                      fetch(`${BASE_URL}/${dep}`, {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: fetchAuthentication().value.id,
                        },
                      }).then(handleErrors)
                    );
                  });
                } else if (filterMode == "ORG") {
                  deps.forEach((dep) => {
                    promiseArr.push(
                      fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: fetchAuthentication().value.id,
                        },
                      }).then(handleErrors)
                    );
                  });
                } else if (filterMode == "USER") {
                  deps.forEach((dep) => {
                    /* genericFactory.setRouteName(dep); */
                    let depDataWasRequestedFlag = false;
                    // Get the dep active tab field desc
                    for (let field in activeTab.fields) {
                      if (Object.hasOwnProperty.call(activeTab.fields, field)) {
                        let fieldDesc = activeTab.fields[field];
                        if (fieldDesc.fromTable == dep) {
                          if (fieldDesc.getAllFromTable) {
                            /* promiseArr.push(genericFactory.getAll()); */
                            promiseArr.push(
                              fetch(`${BASE_URL}/${dep}`, {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: fetchAuthentication().value.id,
                                },
                              }).then(handleErrors)
                            );
                            depDataWasRequestedFlag = true;
                            break;
                          }
                          if (fieldDesc.unfilteredFromTable) {
                            promiseArr.push(
                              fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: fetchAuthentication().value.id,
                                },
                              }).then(handleErrors)
                            );
                            depDataWasRequestedFlag = true;
                            break;
                          }
                          // Create database fetch based on the matching field desc filtered by fields
                          // Only applies if currentUser has the filtered by fields in its information
                          let depCollectionFilter = {};
                          if (
                            fieldDesc.filteredBy &&
                            Array.isArray(fieldDesc.filteredBy) &&
                            fieldDesc.filteredBy.length > 0
                          ) {
                            fieldDesc.filteredBy.forEach((filteredByField) => {
                              if (
                                (!Array.isArray(user[filteredByField]) &&
                                  user[filteredByField] != null &&
                                  user[filteredByField] != "") ||
                                (user[filteredByField] != null &&
                                  Array.isArray(user[filteredByField]) &&
                                  user[filteredByField].length > 0)
                              ) {
                                depCollectionFilter[filteredByField] = user[filteredByField];
                              }
                            });
                            if (Object.keys(depCollectionFilter).length > 0) {
                              /* promiseArr.push(
                                    genericFactory.getByProperties(depCollectionFilter, user.organization)
                                  ); */
                              let query = parsePropNameValueMap(depCollectionFilter, org, null, null, null, null);
                              if (query != "") {
                                query = "filter=" + query;
                                promiseArr.push(
                                  fetch(`${BASE_URL}/${dep}?${query}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: fetchAuthentication().value.id,
                                    },
                                  }).then(handleErrors)
                                );
                              } else {
                                promiseArr.push(
                                  fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: fetchAuthentication().value.id,
                                    },
                                  }).then(handleErrors)
                                );
                              }
                              depDataWasRequestedFlag = true;
                              break;
                            } else {
                              promiseArr.push(
                                fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                                  method: "GET",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: fetchAuthentication().value.id,
                                  },
                                }).then(handleErrors)
                              );
                              depDataWasRequestedFlag = true;
                              break;
                            }
                          } else {
                            if (dep == "Class_Plan_Students" || dep == "KSTK_Students") {
                              let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields([
                                "year",
                                "class",
                              ]);
                              /* promiseArr.push(
                                    genericFactory.getByProperties(depCollectionFilter, user.organization)
                                  ); */
                              let query = parsePropNameValueMap(depCollectionFilter, org, null, null, null, null);
                              if (query != "") {
                                query = "filter=" + query;
                                promiseArr.push(
                                  fetch(`${BASE_URL}/${dep}?${query}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: fetchAuthentication().value.id,
                                    },
                                  }).then(handleErrors)
                                );
                              } else {
                                promiseArr.push(
                                  fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: fetchAuthentication().value.id,
                                    },
                                  }).then(handleErrors)
                                );
                              }
                              depDataWasRequestedFlag = true;
                              break;
                            } else if (dep == "Class_Plan_Essential_Learnings") {
                              let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields([
                                "schoolYear",
                                "subject",
                              ]);
                              /* promiseArr.push(
                                    genericFactory.getByProperties(depCollectionFilter, user.organization)
                                  ); */
                              let query = parsePropNameValueMap(depCollectionFilter, org, null, null, null, null);
                              if (query != "") {
                                query = "filter=" + query;
                                promiseArr.push(
                                  fetch(`${BASE_URL}/${dep}?${query}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: fetchAuthentication().value.id,
                                    },
                                  }).then(handleErrors)
                                );
                              } else {
                                promiseArr.push(
                                  fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: fetchAuthentication().value.id,
                                    },
                                  }).then(handleErrors)
                                );
                              }
                              depDataWasRequestedFlag = true;
                              break;
                            } else {
                              /* promiseArr.push(
                                    genericFactory.getByProperty("organization", user.organization)
                                  ); */
                              promiseArr.push(
                                fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                                  method: "GET",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: fetchAuthentication().value.id,
                                  },
                                }).then(handleErrors)
                              );
                              depDataWasRequestedFlag = true;
                              break;
                            }
                          }
                        }
                      }
                    }
                    if (!depDataWasRequestedFlag) {
                      if (dep == "Class_Plan_Students" || dep == "KSTK_Students") {
                        let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields([
                          "year",
                          "class",
                        ]);
                        /* promiseArr.push(
                              genericFactory.getByProperties(depCollectionFilter, user.organization)
                            ); */
                        let query = parsePropNameValueMap(depCollectionFilter, org, null, null, null, null);
                        if (query != "") {
                          query = "filter=" + query;
                          promiseArr.push(
                            fetch(`${BASE_URL}/${dep}?${query}`, {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: fetchAuthentication().value.id,
                              },
                            }).then(handleErrors)
                          );
                        } else {
                          promiseArr.push(
                            fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: fetchAuthentication().value.id,
                              },
                            }).then(handleErrors)
                          );
                        }
                      } else if (dep == "Class_Plan_Essential_Learnings") {
                        let depCollectionFilter = getSpecificCollectionFilterBasedOnCurrentUserFields([
                          "schoolYear",
                          "subject",
                        ]);
                        /* promiseArr.push(
                              genericFactory.getByProperties(depCollectionFilter, user.organization)
                            ); */
                        let query = parsePropNameValueMap(depCollectionFilter, org, null, null, null, null);
                        if (query != "") {
                          query = "filter=" + query;
                          promiseArr.push(
                            fetch(`${BASE_URL}/${dep}?${query}`, {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: fetchAuthentication().value.id,
                              },
                            }).then(handleErrors)
                          );
                        } else {
                          promiseArr.push(
                            fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: fetchAuthentication().value.id,
                              },
                            }).then(handleErrors)
                          );
                        }
                      } else {
                        /* promiseArr.push(
                              genericFactory.getByProperty("organization", user.organization)
                            ); */
                        promiseArr.push(
                          fetch(`${BASE_URL}/${dep}?filter[where][organization][like]=${org}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: fetchAuthentication().value.id,
                            },
                          }).then(handleErrors)
                        );
                      }
                    }
                  });
                }

                return promiseArr;
              }

              function getFromTableData(fromTable) {
                var i = deps.indexOf(fromTable);
                if (i != -1) {
                  // KSTK
                  if (org == "5a146532e511ce4db73a09cd") {
                    return depsData[i];
                  } else {
                    return depsData[i].filter((o) => o.organization == org);
                  }
                }
              }

              function parseValue(id, field, auxFromTable, auxFieldName) {
                if (id != "null" && id != "undefined" && id != null) {
                  var total = "";
                  var idToStrings = [];
                  var depData = {};

                  let originalID = JSON.parse(JSON.stringify(id));

                  if (!Array.isArray(id) && id.length > 24) {
                    total = id.substring(24);
                    id = id.substring(0, 24);
                  }

                  if (
                    !Array.isArray(id) &&
                    (typeof id == "number" ||
                      id.length < 24 ||
                      (typeof id == "string" && (id.split(" ").length - 1 > 0 || id.split("/").length - 1 > 0)) ||
                      (typeof originalID == "string" && originalID.split(",").length - 1 > 0))
                  ) {
                    return originalID;
                  }

                  if (auxFromTable == null) {
                    var i = deps.indexOf(activeTab.fields[field].fromTable);
                  } else {
                    var i = deps.indexOf(auxFromTable);
                  }

                  if (i != -1) {
                    if (typeof depsData !== "undefined" && depsData.length > 0) {
                      depData = depsData[i];
                      //var name = depData.filter(el => el.id === id);
                      for (let k = 0; k < depData.length; k++) {
                        let el = depData[k];
                        if (el.id === id) {
                          var name = new Array(el);
                          break;
                        }
                      }
                    }
                  }

                  if (Array.isArray(id)) {
                    let numIDsParsed = 0;
                    for (var j = 0; j < depData.length; j++) {
                      for (var i = 0; i < id.length; i++) {
                        if (depData[j].id === id[i]) {
                          numIDsParsed += 1;
                          if (auxFieldName != null) {
                            idToStrings.push(depData[j][auxFieldName]);
                          } else {
                            idToStrings.push(depData[j][field]);
                          }
                        }
                      }
                      if (numIDsParsed == id.length) {
                        break;
                      }
                    }
                    return idToStrings.toString() + total;
                  }

                  if (typeof name !== "undefined" && name.length > 0) {
                    if (auxFieldName != null) {
                      return name[0][auxFieldName] + total;
                    } else {
                      return name[0][field] + total;
                    }
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              }

              function parseData(activeTab, data) {
                var parsedData = [];
                for (let i = 0; i < data.length; i++) {
                  let row = data[i];
                  for (const key in row) {
                    if (row.hasOwnProperty(key)) {
                      let rowElement = row[key];
                      if (activeTab.fields[key]) {
                        if (
                          activeTab.fields[key].fromTable != null &&
                          activeTab.fields[key].viewType != "calculatedList"
                        ) {
                          row[key] = parseValue(rowElement, key);
                        }
                        if (activeTab.fields[key].viewType == "date" && rowElement) {
                          if (rowElement != null && (typeof rowElement != "string" || rowElement.indexOf("/") == -1)) {
                            row[key] = new Date(rowElement).toLocaleDateString();
                          }
                        }
                        if (
                          activeTab.fields[key].viewType == "dropdown" &&
                          activeTab.fields[key].list != null &&
                          rowElement
                        ) {
                          row[key] = rowElement.toString();
                        }
                      }
                    }
                  }
                  parsedData.push(row);
                }
                resolve(parsedData);
                return true;
              }

              function getSpecificCollectionFilterBasedOnCurrentUserFields(fields) {
                let depCollectionFilter = {};
                fields.forEach((field) => {
                  if (
                    (!Array.isArray(user[field]) && user[field] != null && user[field] != "") ||
                    (user[field] != null && Array.isArray(user[field]) && user[field].length > 0)
                  ) {
                    depCollectionFilter[field] = user[field];
                  }
                });
                return depCollectionFilter;
              }
            });
          });
        }
      });
    });
    return new Promise((resolve, reject) => {
      resolve(modulesDataCache[moduleId + org + tabIndex]);
      return true;
    });
  }
};
